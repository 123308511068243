<template>
  <div
    class="relative  current-tool"
    v-show="datalist.length"
  >
    <ul
      v-if="hasPermission(1092101216)"
      class="absolute ul-dom"
      v-show="showTool"
      @mouseenter="showToolHandle"
      @mouseleave="hideToolHandle"
    >
      <li
        v-for="item in datalist"
        :class="
          `text-center pointer ${
            currentResolution == item.id ? 'current' : ''
          }`
        "
        :key="item.id"
        @click="selectItem(item)"
      >
        {{ item.text }}
      </li>
    </ul>
    <span
      :class="
        `text-lightblue pointer resolution-all ${className}`
      "
      @mouseenter="showToolHandle"
      @mouseleave="hideToolHandle"
      title="切换清晰度"
      >{{ getText }}</span
    >
  </div>
</template>

<script>
export default {
  name: 'ToggleResolution',
  props: {
    resolutionValue: {
      type: [Number, String],
      default: 0
    },
    // 清晰度备选项
    definitions: {
      type: Array,
      default: () => []
    },
    className: {
      type: String,
      default: ''
    },
    noToggleResolution: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentResolution: this.resolutionValue,
      showTool: false
    }
  },
  computed: {
    getText() {
      return (
        this.datalist.find(
          (e) => e.id == this.currentResolution
        )?.text || ''
      )
    },

    datalist() {
      return this.definitions.map((e) => ({
        id: e.templateId,
        text: e.videoName
      }))
    }
  },
  watch: {
    resolutionValue(nv) {
      this.currentResolution = nv
    }
  },

  methods: {
    selectItem(item) {
      this.currentResolution = item.id
      this.showTool = false
      this.$emit('toggleResolutionHandle', item.id)
    },
    showToolHandle() {
      clearTimeout(this.timer)
      if (this.noToggleResolution) {
        return
      }
      this.showTool = true
    },
    hideToolHandle() {
      this.timer = setTimeout(() => {
        this.showTool = false
      }, 200)
    }
  }
}
</script>

<style scoped>
.ul-dom {
  background: rgba(15, 24, 45, 0.8);
  bottom: 100%;
  color: rgba(134, 179, 233, 1);
  left: 50%;
  transform: translate(-50%, -4px);
  width: 38px;
  z-index: 1000;
  padding: 1px;
}
.ul-dom::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top-color: rgba(15, 24, 45, 0.8);
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  z-index: -1;
}
.ul-dom li {
  line-height: 32px;
}
.ul-dom li.hover,
.ul-dom li:hover,
.ul-dom li.current {
  background: rgba(134, 179, 233, 0.3);
}
.resolution-all {
  width: 38px;
  height: 24px;
  border: 1px solid #86b3e9;
  border-radius: 2px;
  display: block;
  text-align: center;
}
.bg-mask {
  background: rgba(0, 0, 0, 0.7);
  border-color: #999;
}
</style>
