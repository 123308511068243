import { setStorage } from '@/tools';
const routes = [
  // 登录login
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: '登录',
      headerHidden: true,
      loginFlag: true
    }
    // meta: { footShow: false },
  },
  {
    path: '/reset-pass', //登录页
    name: 'resetPass',
    meta: {
      title: '充值密码',
      headerHidden: true,
      loginFlag: true
    },
    component: () => import('@/views/resetPass/index.vue')
  },
  {
    path: '/',
    component: () => import('@/views/Home/index.vue'),
    children: [
      {
        path: '',
        redirect: 'home'
      },
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home/components/index.vue'),
        meta: {
          title: '首页',
          keepAlive: true
        }
      },
      {
        path: 'home/outside-province-video',
        name: 'OutsideProvinceVideo',
        component: () => import('@/views/Video/index.vue'),
        meta: {
          title: '省外视频'
        }
      },
      {
        path: 'home/all-message',
        name: 'Message',
        component: () => import('@/views/Message/index.vue'),
        meta: {
          title: '所有消息',
          keepAlive: false,
          // permissionCode: 10921610
        }
      },
      {
        path: 'home/turn-pro',
        name: 'TurnPro',
        component: () => import('@/views/TurnPro/index.vue'),
        meta: {
          title: '巡检预案',
          keepAlive: false,
          permissionCode: 109215
        }
      },
      {
        path: 'home/project-screen',
        name: 'ProjectScreen',
        component: () => import('@/views/ProjectScreen/index.vue'),
        meta: {
          title: '投屏'
        }
      }
    ],
    beforeEnter: (to, from, next) => {
      setStorage('historyURL', '/home');
      next();
    }
    // meta: { footShow: true },
  },
  {
    path: '/resource',
    component: () => import('@/views/Resource/index.vue'),
    meta: {
      title: '资源中心',
      headerHidden: false
    },
    children: [
      {
        path: '',
        redirect: 'index',
      },
      {
        path: 'index',
        name: 'Resource',
        component: () => import('@/views/Resource/components/index.vue'),
        meta: {
          title: '资源中心首页',
          keepAlive:true
        }
      },
      {
        path: 'net-condition',
        name: 'NetCondition',
        component: () => import('@/views/NetCondition/index.vue'),
        meta: {
          title: '联网状况',
          permissionCode: 109211101010,
          keepAlive: true
        }
      },
      {
        path: 'departnet-condition',
        name: 'DepartNetCondition',
        component: () => import('@/views/NetCondition/departIndex.vue'),
        meta: {
          title: '部级联网状况',
          permissionCode: 109211101010
        }
      },
      {
        path: 'yn-condition',
        name: 'YnCondition',
        component: () => import('@/views/NetCondition/indexYunNan.vue'),
        meta: {
          title: '云南联网状况',
          permissionCode: 109211101010
        }
      },
      {
        path: 'screen-and-video',
        name: 'ScreenAndVideo',
        component: () => import('@/views/ScreenAndVideo/index.vue'),
        meta: {
          title: '截图和视频',
          // permissionCode: 10921111
        }
      },

      {
        path: 'apply',
        name: 'Apply',
        component: () => import('@/views/Apply/index.vue'),
        meta: {
          title: '申请'
        }
      },
      {
        path: 'cameral-join',
        name: 'CameralJoin',
        component: () => import('@/views/CameralJoin/index.vue'),
        meta: {
          title: '摄像机接入',
          permissionCode: 1092111410
        }
      },
      {
        path: 'cameral-statistic',
        name: 'CameralStatistic',
        component: () => import('@/views/CameralStatistic/index.vue'),
        meta: {
          title: '摄像机统计'
        }
      },
      {
        path: 'cameral-group',
        name: 'CameralGroup',
        component: () => import('@/views/CameralGroup/index.vue'),
        meta: {
          title: '摄像机组'
        }
      },
      {
        path: 'cameral-exception',
        name: 'CameralException',
        component: () => import('@/views/CameralException/index.vue'),
        meta: {
          title: '摄像机异常统计'
        }
      },
      {
        path: 'exception-manage',
        name: 'ExceptionManage',
        component: () => import('@/views/ExceptionManage/index.vue'),
        meta: {
          title: '故障列表',
          permissionCode: 109211121010
        }
      }
    ],
    beforeEnter: (to, from, next) => {
      setStorage('historyURL', '/resource');
      next();
    }
  },
  {
    path: '/ai-center',
    component: () => import('@/views/AiCenter/index.vue'),
    meta: {
      title: 'Ai中心',
      headerHidden: false
    },
    children: [
      {
        path: '',
        redirect: 'index'
      },
      {
        path: 'index',
        name: 'AiCenter',
        component: () => import('@/views/AiCenter/components/index.vue'),
        meta: {
          title: '首页'
        }
      },
      {
        path: 'event-all',
        name: 'EventAll',
        component: () => import('@/views/EventAll/index.vue'),
        meta: {
          title: '事件全部'
        }
      },
      {
        path: 'analyzed-schema',
        name: 'EventAlarm',
        component: () => import('@/views/AiAnalyzed/index.vue'),
        meta: {
          title: '事件预案列表'
        }
      }
    ],
    beforeEnter: (to, from, next) => {
      setStorage('historyURL', '/ai-center');
      next();
    }
  },
  /* {
    path: '/user-manage',
    component: () => import('@/views/UserManage/index.vue'),
    meta: {
      title: '用户管理',
      headerHidden: false,
      dialog: true
    }
  },
  {
    path: '/act-action',
    component: () => import('@/views/ActAction/index.vue'),
    meta: {
      title: '行为审计',
      headerHidden: false,
      dialog: true
    }
  },
  {
    path: '/camera-dict',
    component: () => import('@/views/CameraDict/index.vue'),
    meta: {
      title: '数据字典',
      headerHidden: false,
      dialog: true
    }
  },
  {
    path: '/store-manage',
    component: () => import('@/views/StoreManage/index.vue'),
    meta: {
      title: '存储管理',
      headerHidden: false,
      dialog: true
    }
  },
  {
    path: '/system-setting',
    component: () => import('@/views/SystemSetting/index.vue'),
    meta: {
      title: '系统设置',
      headerHidden: false,
      dialog: true
    }
  },
  {
    path: '/log-action',
    component: () => import('@/views/LogAction/index.vue'),
    meta: {
      title: '操作日志',
      headerHidden: false,
      dialog: true
    }
  },
  {
    path: '/system-setting',
    component: () => import('@/views/SystemSetting/index.vue'),
    meta: {
      title: '系统设置',
      headerHidden: false
    }
  },
  {
    path: '/role-manage',
    component: () => import('@/views/RoleManage/index.vue'),
    meta: {
      title: '角色管理',
      headerHidden: false,
      dialog: true
    }
  },
  {
    path: '/authority-manage',
    component: () => import('@/views/AuthorityManage/index.vue'),
    meta: {
      title: '权限管理',
      headerHidden: false,
      dialog: true
    }
  }, */
  {
    path: '/system',
    component: () => import('@/views/System/index.vue'),
    children: [
      {
        path: '',
        redirect: 'user-manage'
      },
      {
        path: 'user-manage',
        name: 'userManage',
        component: () => import('@/views/UserManage/index.vue'),
        meta: {
          title: '用户管理',
          keepAlive: true,
          permissionCode: 10921710
        }
      },
      {
        path: 'role-manage',
        name: 'roleManage',
        component: () => import('@/views/RoleManage/index.vue'),
        meta: {
          title: '角色管理',
          keepAlive: true,
          permissionCode: 10921711
        }
      },
      {
        path: 'log-action',
        name: 'logAction',
        component: () => import('@/views/LogAction/index.vue'),
        meta: {
          title: '操作日志',
          keepAlive: true,
          permissionCode: 10921712
        }
      },
      {
        path: 'act-action',
        name: 'actAction',
        component: () => import('@/views/ActAction/index.vue'),
        meta: {
          title: '行为审计',
          keepAlive: true,
          isSuperAdmin: true
        }
      },
      {
        path: 'camera-dict',
        name: 'cameraDict',
        component: () => import('@/views/CameraDict/index.vue'),
        meta: {
          title: '数据字典',
          keepAlive: true,
          isSuperAdmin: true
        }
      },
      {
        path: 'store-manage',
        name: 'storeManage',
        component: () => import('@/views/StoreManage/index.vue'),
        meta: {
          title: '存储管理',
          keepAlive: true,
          isSuperAdmin: true
        }
      },
      {
        path: 'system-setting',
        component: () => import('@/views/SystemSetting/index.vue'),
        meta: {
          title: '系统设置',
          headerHidden: false,
          dialog: true,
          isSuperAdmin: true
        }
      },
      {
        path: 'authority-manage',
        component: () => import('@/views/AuthorityManage/index.vue'),
        meta: {
          title: '权限管理',
          headerHidden: false,
          dialog: true,
          isSuperAdmin: true
        }
      }
    ]
  },
  {
    path: '/spot-check',
    component: () => import('@/views/SpotCheck/index.vue'),
    meta: {
      title: '抽检',
      headerHidden: false,
      dialog: true,
      permissionCode: 109214
    }
  },
  {
    path: '/map-com',
    component: () => import('@/views/MapCom/index.vue'),
    meta: {
      title: '地图',
      headerHidden: true,
      loginFlag: true,
      // permissionCode: 109213
    }
  },
  {
    path: '/timing-recording',
    component: () => import('@components/TimingRecording/index.vue'),
    meta: {
      title: '定时录制',
      headerHidden: false,
      permissionCode: 1092101112
    }
  },
  {
    path: '/single-window',
    name: 'SingleWindow',
    component: () => import('@/views/window/SingleWindow.vue'),
    meta: {
      title: '单窗口',
      headerHidden: true,
      loginFlag: true,
      isScreen:true
    }
  },
  {
    path: '/single-window1*1',
    name: 'SingleWindow2',
    component: () => import('@/views/window/SingleWindow.vue'),
    meta: {
      title: '单窗口',
      headerHidden: true,
      loginFlag: true,
      isScreen:true
    }
  },
  {
    path: '/four-window',
    name: 'FourWindow',
    component: () => import('@/views/window/FourWindow.vue'),
    meta: {
      title: '四分屏',
      headerHidden: true,
      loginFlag: true,
      isScreen:true
    }
  },
  {
    path: '/four-window2*2',
    name: 'FourWindow2',
    component: () => import('@/views/window/FourWindow.vue'),
    meta: {
      title: '四分屏',
      headerHidden: true,
      loginFlag: true,
      isScreen:true
    }
  },
  {
    path: '/six-window',
    name: 'SixWindow',
    component: () => import('@/views/window/SixWindow.vue'),
    meta: {
      title: '六分屏',
      headerHidden: true,
      loginFlag: true,
      isScreen:true
    }
  },
  {
    path: '/six-window3*2',
    name: 'SixWindow1',
    component: () => import('@/views/window/SixWindow.vue'),
    meta: {
      title: '六分屏',
      headerHidden: true,
      loginFlag: true,
      isScreen:true
    }
  },
  {
    path: '/six-window2*3',
    name: 'SixWindow2',
    component: () => import('@/views/window/SixWindow23.vue'),
    meta: {
      title: '六分屏',
      headerHidden: true,
      loginFlag: true,
      isScreen:true
    }
  },
  {
    path: '/nine-window',
    name: 'NineWindow',
    component: () => import('@/views/window/NineWindow.vue'),
    meta: {
      title: '九分屏',
      headerHidden: true,
      loginFlag: true,
      isScreen:true
    }
  },
  {
    path: '/nine-window3*3',
    name: 'NineWindow1',
    component: () => import('@/views/window/NineWindow.vue'),
    meta: {
      title: '九分屏',
      headerHidden: true,
      loginFlag: true,
      isScreen:true
    }
  },
  {
    path: '/twelve-window',
    name: 'TwelveWindow',
    component: () => import('@/views/window/TwelveWindow.vue'),
    meta: {
      title: '十二分屏',
      headerHidden: true,
      loginFlag: true,
      isScreen:true
    }
  },
  {
    path: '/twelve-window3*4',
    name: 'TwelveWindow1',
    component: () => import('@/views/window/TwelveWindow34.vue'),
    meta: {
      title: '十二分屏',
      headerHidden: true,
      loginFlag: true,
      isScreen:true
    }
  },
  {
    path: '/twelve-window4*3',
    name: 'TwelveWindow2',
    component: () => import('@/views/window/TwelveWindow.vue'),
    meta: {
      title: '十二分屏',
      headerHidden: true,
      loginFlag: true,
      isScreen:true
    }
  },
  {
    path: '/sixteen-window',
    name: 'SixteenWindow',
    component: () => import('@/views/window/SixteenWindow.vue'),
    meta: {
      title: '十六分屏',
      headerHidden: true,
      loginFlag: true,
      isScreen:true
    }
  },
  {
    path: '/sixteen-window4*4',
    name: 'SixteenWindow1',
    component: () => import('@/views/window/SixteenWindow.vue'),
    meta: {
      title: '十六分屏',
      headerHidden: true,
      loginFlag: true,
      isScreen:true
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404/index.vue'),
    meta: {
      title: '找不到'
    }
    // meta: { footShow: true },
  },
  { path: '*', redirect: '/404' }
];

export default routes;
