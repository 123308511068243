import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const sharingFolder = {
    //获取共享收藏夹
    getSharingFolders(params) {
        return axios
          .get(`${base.sq}/monitor/sharing/getSharingFolders?${qs.stringify(params)}`)
          .then(({ data }) => {
            return data;
          });
    },
    //新增共享收藏夹
    addSharingFolder(params) {
        return axios
          .post(`${base.sq}/monitor/sharing/addSharingFolder`, params)
          .then(({ data }) => {
            return data;
          });
    },
    //修改文件夹
    updateSharingFolder(params) {
       return axios
        .post(`${base.sq}/monitor/sharing/updateSharingFolder`, params)
        .then(({ data }) => {
          return data;
        });
    },
    //删除摄像机deleteSharingFolder
    deleteSharingFolder(id) {
        return axios
            .delete(`${base.sq}/monitor/sharing/deleteSharingFolder/${id}`)
            .then(({ data }) => {
            return data;
            });
    },
    //绑定摄像机
    bindingCamera(params) {
        return axios
            .put(`${base.sq}/monitor/sharing/bindingCamera`, params)
            .then(({ data }) => {
            return data;
        });
    },
    //获取文件夹下所有摄像机
    getCamerasByFolderId(folderId) {
        return axios
          .get(`${base.sq}/monitor/sharing/getCamerasByFolderId/${folderId}`)
          .then(({ data }) => {
            return data;
          });
    },
    //解绑摄像机unboundCamera
    unboundCamera(params) {
        return axios
            .put(`${base.sq}/monitor/sharing/unboundCamera`, params)
            .then(({ data }) => {
                return data;
            });
    },
    //共享
    start(folderId) {
        return axios
            .put(`${base.sq}/monitor/sharing/start/${folderId}`)
            .then(({ data }) => {
                return data;
            });
    },
    ////////////////////////////////取消共享
    stop(folderId) {
        return axios
            .put(`${base.sq}/monitor/sharing/stop/${folderId}`)
            .then(({ data }) => {
                return data;
            });
    },
    getSharingCameras() {
        return axios.get(`${base.sq}/monitor/sharing/getSharingCameras`).then(({data}) => {
            return data;
        });
    },
    // POST /monitor/sharing/updateRankByFolderId
    updateRankByFolderId(id,data) {
        return axios.post(`${base.sq}/monitor/sharing/updateRankByFolderId?folderId=${id}`,data).then(({data}) => {
            return data;
        });
    }
};
export default sharingFolder;