import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router/router';
import api from './request/api/index'; // 导入api接口
import Antd from 'ant-design-vue';
//滚动条插件
import vuescroll from 'vuescroll/dist/vuescroll';
import VueDraggableResizable from 'vue-draggable-resizable'
import * as filters from './filters/filters.js';
import directives from '@/directives';
// import Socket from '@/components/js/socket';
import gcoord from 'gcoord';
import axios from 'axios';
import '@/utils/rem'; //fix20210701
//import '@/utils/flexible.js';
// import { setStorage /* , clearStorage  */ } from '@/tools';
// import { Button } from 'ant-design-vue';
import { createSocket } from '@/tools/websocket';
import '@/style/override.css';
import '@assets/styles/index.css';

let userId = store.getters.getUserInfo && store.getters.getUserInfo.userId;
// 连接websocket
if (userId) {
  console.log(`${window.g.webSocketUrl}${userId}`);
  createSocket(`${window.g.webSocketUrl}${userId}`);
}
/* let socket = new Socket();
socket.openSocket();
Vue.prototype.socket = socket;
setStorage('socketId', socket); */

Vue.use(Antd);
Vue.use(vuescroll);
// Vue.component(Button.name, Button);

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]); //插入过滤器名和对应方法
});

Vue.prototype.$api = api; // 将api挂载到vue的原型上

Vue.prototype.$g = window.g;
Vue.prototype.$gcoord = gcoord;
Vue.prototype.$axios = axios;
Vue.prototype.$qiFang = window.g.qfMap;
Vue.prototype.hasPermission = permissionCode => {
  const userPermissions = store?.state?.login?.userPermissions || {};
  return userPermissions[permissionCode] || false;
};
Vue.prototype.permissionWarning = () =>
  Vue.prototype.$message.warning(
    '对不起，您还没有当前操作权限，请联系管理员申请！'
  );
Vue.config.productionTip = false;
window.addEventListener('beforeunload', () => {
  /* clearStorage('cameraList');
  clearStorage('patrolCameral'); */
  /* Vue.prototype.socket.socket.onclose();*/
});

Vue.component('vue-draggable-resizable', VueDraggableResizable)
new Vue({
  router,
  store,
  directives,
  render: h => h(App),
  data() {
    return {
      playerType: 'flv',
      mediatype: 'flvUrl',
      scrollOps: {
        vuescroll: {
          mode: 'native' //Utils.browser.version.mobile ? "slide" : "native"
        },
        rail: {},
        scrollPanel: {
          //scrollingX:false,
          easing: 'easeInQuad'
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: false,
          keepShow: false,
          background: 'rgba(127, 158, 198, 0.8)',
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: false,
          size: '6px',
          disable: false
        }
      }
    };
  },
  computed: {
    scrollOpsX() {
      return Object.assign({}, this.scrollOps, {
        scrollPanel: {
          scrollingY: false
        }
      });
    },
    scrollOpsY() {
      return Object.assign({}, this.scrollOps, {
        scrollPanel: {
          scrollingX: false
        }
      });
    },
    scrollOpsXY() {
      return Object.assign({}, this.scrollOps, {
        scrollPanel: {
          locking: true,
          scrollingY: true,
          scrollingX: true
        }
      });
    },
    scrollOpsSlider() {
      return Object.assign({}, this.scrollOps, {
        vuescroll: {
          mode: 'slide'
        }
      });
    }
  }
}).$mount('#app');
