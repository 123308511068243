import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const User = {
  //获取用户管理列表
  getUserList(data) {
    return axios
      .get(`${base.sq}/user/list?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  //数据导出
  downloadUser(data) {
    return axios
      .get(`${base.sq}/user/download?${qs.stringify(data)}`, {
        responseType: 'arraybuffer',
      })
      .then(({ data }) => {
        return data;
      });
  },
  //批量更新用户状态
  batchUserStatus(data) {
    return axios.put(`${base.sq}/user/batch`, data).then(({ data }) => {
      return data;
    });
  },
  //删除用户
  deleteUser(data) {
    return axios.delete(`${base.sq}/user/batch`, {data:data}).then(({ data }) => {
      return data;
    });
  },
  //编辑用户
  updateUser(data) {
    return axios.put(`${base.sq}/user/user`, data).then(({ data }) => {
      return data;
    });
  },
  //新增用户
  addUser(data) {
    return axios.post(`${base.sq}/user/users`, data).then(({ data }) => {
      return data;
    });
  },
  //获取详情
  getDetail(data) {
    return axios
      .get(`${base.sq}/user/details?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
};

export default User;
