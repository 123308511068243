import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const imageAn = {
  //查询图像分析列表
  getImageList(data) {
    return axios
      .get(`${base.sq}/monitor/image-analysis?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  //查询图像分析详情
  getImageDetail(data) {
    return axios
      .get(`${base.sq}/monitor/image-analysis/detail?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  //新增图像智能分析
  postIntelligent(data) {
    return axios
      .post(`${base.sq}/monitor/image-analysis/intelligent`, data)
      .then(({ data }) => {
        return data;
      });
  },
  postManual(data) {
    return axios
      .post(`${base.sq}/monitor/image-analysis/manual`, data)
      .then(({ data }) => {
        return data;
      });
  },
  deleteImageAn(taskId) {
    return axios
      .delete(`${base.sq}/monitor/image-analysis/${taskId}`)
      .then(({ data }) => {
        return data;
      });
  }
};
export default imageAn;
