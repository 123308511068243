import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './index';
import { getStorage } from '../tools/index';
import store from '@/store'

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
Vue.use(VueRouter);

const router = new VueRouter({
  linkActiveClass: 'active',
  mode: 'hash',
  routes
});
const excludeRouter = [
  'login',
  'resetPass'
  /* 'register',
  'forgetpsd',
  'home',
  'SingleWindow',
  'FourWindow',
  'SixWindow',
  'OutsideProvinceVideo',
  'Message',
  'Resource',
  'NetCondition',
  'ScreenAndVideo',
  'Apply',
  'CameralJoin',
  'CameralStatistic',
  'CameralGroup',
  'CameralException',
  'AiCenter', */
];

const getUserPermission = () => {
  const roleCode = store.state.login.userInfo['role']
  return Vue.prototype.$api.login.getChoseListByAllRole({
    roleCode,
  })
  .then(res => {
    if (res?.code !== 200) {
      Vue.prototype.$message.error({
        content:
          '获取用户权限列表失败, 请刷新重试',
        duration: 3,
      });
      store.state.login.userPermissions = {}
    } else {
      store.state.login.userPermissions = res.data.full.concat(res.data.half).reduce((acc, e) => {
        acc[e] = true;
        return acc;
      }, {});
    }
  }).catch(() => {
    Vue.prototype.$message.error({
      content:
        '获取用户权限列表失败, 请刷新重试',
      duration: 3,
    });
    store.state.login.userPermissions = {}
  });
}
const hasPermission = permissionCode => { // 判断有无权限
  const userPermissions = store?.state?.login?.userPermissions || {}
  return userPermissions[permissionCode] || false
}
const isSuperAdmin = () => { // 判断是否超管
  const roleId = store?.state?.login?.userInfo?.role
  return roleId.includes(100000001)
}
router.beforeEach(async (to, from, next) => {
  //取消请求
  store.commit('clear_cancel');
  const { Authorization } = getStorage('appmonitor', {});
  /* let Authorization = false */
  if (!Authorization && !excludeRouter.includes(to.name)) {
    next({
      name: 'login'
    });
  } else if (excludeRouter.includes(to.name)) {
    next()
  } else {
    if (store?.state?.login?.userPermissions === null) { // 刷新页面重新获取用户权限列表
      to.meta.permissionCode ? await getUserPermission() : getUserPermission()
    }

    if (to.meta.permissionCode) { // 权限管理
      if (hasPermission(to.meta.permissionCode)) {
        next()
      } else {
        Vue.prototype.permissionWarning()
        next(from.fullPath)
      }
    } else if (to.meta.isSuperAdmin) { // 超管独有
      if (isSuperAdmin()) {
        next()
      } else {
        Vue.prototype.$message.warning('您不是超级管理员，无法访问此页面。')
        next('/')
      }
    } else {
      next();
    }
  }
});

/* router.afterEach(to => {
  window.document.title = to.meta.title;
  window.scrollTo(0, 0);
}); */

router.onError(error => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  console.log(
    isChunkLoadFailed,
    '/Loading chunk (d)+ failed/g',
    '路由懒加载找不到对应的moudle'
  );
  if (isChunkLoadFailed) {
    window.location.reload();
  } else {
    console.log(error);
  }
});
export default router;
