<template>
  <div>
    <a-modal
      title="故障修改"
      :visible="visible"
      width="30%"
      :dialogClass="dialogClass"
      :centered="true"
      :mask="false"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
    >
      <template #footer>
        <a-button key="submit" type="primary" @click="handleOk">修改</a-button>
        <a-button key="back" @click="handleCancel">取消</a-button>
      </template>
      <div class="edit-fault-box">
        <a-checkbox v-model="soon"  @change="restore">立即恢复</a-checkbox>
        <div class="clear"></div>
        <div class="fault-bottom" v-if="showTime">
         <span class="title">修改恢复时间：</span>
         <div>
           <a-date-picker
              format="YYYY-MM-DD"
              v-model="timeData"
              :disabled-date="disabledDate"
            />
         </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
// import { mapState, mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: 'fault-dialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    cameraId: {
      type: String,
      default: '',
    },
    isLucency: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialogClass: this.isLucency ? 'fault-modal' : 'fault-modal fault-lucency-modal',
      timeData:'',
      soon:false,
      showTime: true,
      faultDetail:{}
    };
  },
  computed: {
    // ...mapState({
    //   faultDetail: (state) => state.home.faultDetail,
    // }),
  },
  watch: {
    visible(nv) {
      if (nv) {
        this.getFaultDetails()
      }
    },
  },
  methods: {
    moment,
    disabledDate(current) {
      const date = new Date();
      date.setTime(date.getTime() - 3600 * 1000 * 24);
      return current && current < date;
    },
    restore(val){
      console.log('2222', val.target.checked)
      if(val.target.checked == true){
        this.showTime = false
      }else{
        this.showTime = true
      }
    },
    handleCancel() {
      this.timeData='',
      this.soon=false,
      this.showTime= true,
      this.$emit('closeEditFault');
    },
    handleOk() {
      let params = {
          cameraFaultList: [{
            cameraId: this.cameraId,
            createId: this.faultDetail.createId,
            faultId: this.faultDetail.faultId,
            faultName: this.faultDetail.faultName,
            restoreTime: this.soon ? this.moment(new Date()).format('YYYY-MM-DD HH:mm') : this.moment(this.timeData).format('YYYY-MM-DD')
          }]
      }
      this.$api.home
        .faultReported(params)
        .then((data) => {
          if (data.code === 200) {
            this.$message.success('修改成功！')
            this.$nextTick(() => {
              this.$emit('getFaultDetails');
            this.$emit('faultClick');
            })
            this.handleCancel()
          } else {
            this.$message.error(data.message)
            this.handleCancel()
          }
        })
    },
    getFaultDetails() {
      let params = {
        cameraId: this.cameraId,
      }
      console.log('params', params)
      this.$api.home
        .faultDetails(params)
        .then((res) => {
          if (res.code === 200) {
            this.faultDetail = res.data
            this.timeData = this.moment(res.data?.restoreTime).format('YYYY-MM-DD')
            console.log('faultDetail', this.faultDetail)
          } else {
            this.$message.error(res.message)
          }
        })
    },
    // ...mapActions(['getFaultDetail']),
  },
  mounted() {
  },
};
</script>
<style lang="less">
  .fault-lucency-modal {
    &.ant-modal {
      .ant-modal-content {
        // background: pink;
        background: rgba(30, 40, 60, 1);
      }
    }
  }
.fault-modal {
  &.ant-modal {
     .ant-modal-body {
      padding: 0;
    }
    .ant-modal-footer {
      display: flex;
      justify-content: center;
    }
  }
  .edit-fault-box {
    height: 100px;
    padding-left: 20px;
    .clear{
      clear: both;
    }
    .title{
      float: left;
      height: 60px;
      line-height: 40px;
    } 
      .fault-left {
        // display: block;
        .fault-main {
          float: left;
          display: block;
          text-align: center;
          height: 30px;
          line-height: 30px;
          width: 90px;
          border-radius: 40px;
          border: 1px solid #3683D4;
          margin: 5px;
          &.fault-main-opt {
            // border-left: 5px solid #029BE4;
              color: #fff;
              background: #409EFF;
          }
        }
        .fault-but {
          position: relative;
          display: inline-block;
          text-align: center;
          height: 100%;
          cursor: pointer;
          span {
            display: inline-block;
            max-width: 65px;
            color: #fff;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .img-i {
                position: absolute;
                right: -10px;
                top: 50%;
                transform: translateY(-50%);
                width: 10px;
                height: 10px;
                // background: #fff;
              }
        }

    }
    .fault-right {
      padding: 10px 0;
         .right-radio{
          .right-radio-box {
            display: inline-block;
            width: 140px;
            height: 30px;
            line-height: 30px;
            color: #fff;
            font-size: 14px;
            text-align: center;
            padding: 0 10px;
            margin: 0 15px 15px 0; 
            box-sizing: border-box;
            cursor: pointer;
            border: 1.5px solid #3683D4;
            border-radius: 5px;
            &.right-radio-box-opt {
              background: #409EFF;
              border: 1.5px solid #409EFF;
            }
          }
         }
      }
  }
}

</style>
