import {
  CLASSIFY,
  DIRECTOIN,
  CAMERASTATUS,
  DIRECTOINARROW,
  CAMERASTATUSNEW,
  CAMERATYPE
} from '@/tools';

let ANALYSETYPE = {
  0: '立即循环分析',
  1: '按时间段定时巡检',
  2: '每日定时巡检',
  3: '按路数优先分析'
};
export const subString = (val, count = 4) => {
  if (typeof val !== 'string') return String(val);
  return val.length > count ? val.substring(0, count) + '...' : val;
};

export const getClassify = val => {
  return CLASSIFY[String(val)];
};
export const getDirection = val => {
  return DIRECTOIN[String(val)];
};
export const getDirectionArrow = val => {
  return DIRECTOINARROW[String(val)];
};
export const getCameraStatus = val => {
  return CAMERASTATUS[String(val)];
};
export const getCameraStatusNew = val => {
  return CAMERASTATUSNEW[String(val)];
};
export const formatNum = num => {
  return (num + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
};
export const getAnalyseType = val => {
  return ANALYSETYPE[val];
};
export const getCameraType = val => {
  return   CAMERATYPE[String(val)];
};