import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块
// import Base64 from '@/tools/base64';
const snapshot = {
  //拉取列表
  addScreenShout(data) {
    return axios
      .post(
        `${base.sq}/monitor/screenShot/addScreenShout?${qs.stringify(
          data.data.params
        )}`,
        data.data.file
        /* {
          headers: {
            operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
          }
        } */
      )
      .then(({ data }) => {
        return data;
      });
  },
  //获取摄像机详情
  getScreenShoutCamera(data) {
    return axios
      .get(
        `${base.sq}/monitor/screenShot/getScreenShoutCamera?${qs.stringify(
          data
        )}`
      )
      .then(({ data }) => {
        return data;
      });
  },
  //批量下载
  batchDownload(data) {
    return axios
      .post(`${base.sq}/monitor/screenShot/batchDownload`, data.data, {
        responseType: 'arraybuffer'
      })
      .then(({ data }) => {
        return data;
      });
  },
  confirmSaveAutoVideo(id) {
    return axios
      .put(`${base.sq}/monitor/screenShot/confirmSaveAutoVideo/${id}`)
      .then(({ data }) => {
        return data;
      });
  },
  getSnapshotZips(params) {
    return axios
      .get(`${base.sq}/monitor/screenShot/snapshot-zips?${qs.stringify(params)}`)
      .then(({ data }) => {
        return data;
      });
  },
  getAllRoadSecListByOrgId(orgId) {
    return axios
      .get(`${base.sq}/user/organization/getAllRoadSecListByOrgId/${orgId}`)
      .then(({ data }) => {
        return data;
      });
  }
};
export default snapshot;
