<template>
 <transition name="animate">
  <div class="block-road" v-if="visible">
    <audio :src="audioSrc" autoplay>
       您的浏览器不支持 audio 标签。
    </audio>
    <div class="block-road-header flex items-center justify-between">
      <div class="flex items-center">
        <a-icon type="warning" class="icon-w m-r-xs" />
        <span>路段拥堵提示</span>
      </div>
      <a-icon type="close" class="icon-icon-c pointer" @click="closeHandle" />
    </div>
    <div class="block-road-content">
      <div class="title flex items-center">
        <span class="tag m-r-xs">严重拥堵</span>
        <div class="text ellipsis flex-one">G85杭瑞高速</div>
      </div>
      <div class="info">
        昆明东段往盘龙区方向，路况拥堵，拥堵长度13.8千米，平均车辆速度50米/小时，请及时关注！
      </div>
    </div>
    <div class="block-road-footer flex items-center justify-between">
        <span class="time">2021-12-08</span>
        <span class="btn">查看详情</span>
    </div>
  </div>
 </transition>
</template>

<script>
export default {
  name: 'BlockRoadCom',
  props:{
      visible:{
          type:Boolean,
          default:false
      }
  },
  data(){
      return {
          audioSrc: require('@/assets/home/audio.wav'),
      }
  },
  methods:{
      closeHandle(){
          this.$emit('close');
      }
  }
};
</script>

<style lang="less" scoped>
.animate-enter-active,
.animate-leave-active {
  transition: all 0.5s;
}
.animate-enter,
.animate-leave-to {
  transform: translate3d(0, 100%, 0);
}
.block-road {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 340px;
  background: rgb(37, 64, 134);
  border: 1px solid rgba(37, 64, 134, 0.5);
  color: #fff;
  padding: 10px;
  border-radius: 6px;
  z-index: 999;
  .block-road-header {
    .icon-w {
      color: rgb(222, 125, 14);
    }
    .icon-c {
      color: rgb(126, 142, 184);
    }
  }
  .block-road-content {
    padding: 15px 0 10px;
    border-bottom: 1px solid rgba(20,152,240,0.3);
    .title {
      margin-bottom: 5px;
      .tag {
        background: rgb(234, 74, 74);
        border-radius: 3px;
        padding: 1px 4px;
      }
    }
  }
  .block-road-footer {
      padding-top:10px;
      .time{
          color:rgb(74,139,201);
      }
      .btn{
          border-radius: 6px;
          padding:5px 8px;
          background: rgb(20,152,240);
          line-height:1;
      }
  }
}
</style>
