const state = {
  cancelTokenArr:[]
};
const mutations = {
    push_cancel(state,payload) {
        state.cancelTokenArr.push(payload.cancelToken);
    },
    clear_cancel({cancelTokenArr}) {
        cancelTokenArr.forEach(item => {
            item('路由跳转取消请求');
        });
        cancelTokenArr = []
    }
}
export default{
    state,
    mutations,
}