<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <div
        id="container"
        class="full-width wrapper full-height"
        ref="container"
      >
        <div class="full-width full-height pdm" v-if="!loginFlag">
          <Header v-if="!headerHidden"></Header>
          <div class="home-inner">
            <router-view />
            <a-button
              type="primary"
              style="position:absolute;right:10px;top:10px;z-index:999"
              @click="visibleRoad = true"
              v-show="false"
            >
              Open the notification box
            </a-button>
          </div>
        </div>
        <!-- <router-view v-if="loginFlag" /> -->
        <template v-if="loginFlag">
          <router-view></router-view>
          <!-- <app-main v-else-if="!isScreen" :isScreen="isScreen"></app-main> -->
        </template>
      </div>
    </a-config-provider>

    <!-- loading提示遮罩层 -->
    <transition name="full-loading-fade">
      <div class="full-loading-layer flex-center" v-show="fullLoadingShow">
        <a-spin :tip="loadingTip" :delay="loadingDelay" />
      </div>
    </transition>
    <event-alarm-detail
      ref="eventAlarm"
      :visible="eventVisible"
      :cameraDetailId="cameraDetailId"
      sourceType="cameraNum"
      @close="closeDetail"
      @misReportHandle="misReportParentHandle"
    />
    <!-- 拥堵路段 -->
    <block-road-com :visible="visibleRoad" @close="closeBlockHandle"></block-road-com>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import Header from '@/components/Header';
import { Public } from '@/tools/pageSize';
import { mapMutations, mapState } from 'vuex';
import EventAlarmDetail from '@/components/EventAlarmDetail';
import BlockRoadCom from '@/components/BlockRoadCom.vue';
// import AppMain from '@/views/AppMain';
// import bus from '@/components/js/eventBus';
export default {
  name: 'App',
  data() {
    return {
      locale: zhCN,
      headerHidden: false,

      loginFlag: true,
      isScreen: false,
      screenHeight: 0,
      screenWidth: 0,
      screenWidth2: 0,
      visible: true,

      fullLoadingShow: false,
      loadingDelay: 0,
      loadingTip: 'loading',
      eventVisible: false,
      cameraDetailId: '',
      audioSrc: require('@/assets/home/audio.wav'),

      visibleRoad:false
    };
  },

  watch: {
    $route(to, from) {
      if (to !== from) {
        this.headerHidden = to.meta.headerHidden ? to.meta.headerHidden : false;
        this.loginFlag = to.meta.loginFlag ? to.meta.loginFlag : false;
        this.isScreen = to.meta.isScreen ? to.meta.isScreen : false;
      }
    },
    screenHeight(newV, oldV) {
      if (newV !== oldV) {
        this.screenWidth = document.body.clientWidth;
        this.screenHeight = newV;
        this.resizePage();
      }
    },
    screenWidth2(newV) {
      let bi2 = newV / (this.screenHeight * 3.1);
      let appDom = document.getElementById('app');
      appDom.style.width = this.screenHeight * 3.1 + 'px';
      appDom.style.height = this.screenHeight + 'px';
      appDom.style.overflow = 'hidden';
      appDom.style.transform = `scale(${bi2},1)`;
      appDom.style['transform-origin'] = 'left top';
    },
  },
  created() {
    const vp = this.__proto__.__proto__;
    vp.$fullLoading = this.showLoading;
    vp.$closeFullLoading = this.closeLoading;
  },
  mounted() {
    
    // this.openReport();
    //获取videomessage
    // 接收消息
    /*  this.$notification.open({
      message: '系统提示',
      description: 'fsdfaaaaaaaaaaaaa',
      placement: 'bottomLeft',
      duration: null,
      bottom: 0,
      class: 'notify',
    }); */
    // 注册监听事件
    window.addEventListener('onmessageWS', this.getsocketData);
    /*  */
    /* await this.initSize();
    this.updateSize();
    this.updateScale();
    window.addEventListener('resize', () => {
      this.onResize();
    }); */
    /* this.$nextTick(() => {
      this.resizePage();
      window.onresize = () => {
        return (() => {
          window.screenHeight = document.documentElement.clientHeight;
          this.screenHeight = window.screenHeight;
          this.screenWidth2 = document.documentElement.clientHeight;
        })();
      };
    }); */
    // this.setDrawerDom(this.$refs.drawer);
    // Public.pageResize();
    // window.addEventListener('resize', this.resizeWindow.bind(this));
  },
  components: {
    Header,
    EventAlarmDetail,
    BlockRoadCom,
    // AppMain,
  },
  computed: {
    ...mapState({
      currentDrawerCom: (state) => state.home.currentDrawerCom,
    }),
  },
  methods: {
    closeBlockHandle() {
      this.visibleRoad = false;
    },
    openReport() {
      let _this = this;
      const key1 = `open${Date.now()}`;
      this.$notification.open({
        key: key1,
        message: '事件上报',
        description: _this.getDiscription(key1),
        icon: <a-icon type="exclamation-circle" class="text-orange" />,
        duration: null,
        placement: 'bottomLeft',
        class: 'report-class',
        onClick: this.reportClick,
        bottom: 0,
        style: { left: '10px' },
      });
    },
    getDiscription(key) {
      return (
        <div class="report-container">
          <audio src={this.audioSrc} autoplay>
            您的浏览器不支持 audio 标签。
          </audio>
          <div class="report-content flex full-width">
            <div class="img-box full-width">
              <div class="report-tit f14 m-b-sm ellipsis">
                这是标题这是标题这是标题这是标题这是标题这是标题
              </div>
              <div class="flex-one">
                <img src={require('@/assets/logo.png')} />
              </div>
              <div class="report-footer m-t-sm m-b-sm">由智能厂商提供</div>
              <div class="btn text-right">
                <button onClick={(e) => this.reportConfirm(e, key)}>
                  认定
                </button>
                <button type="primary" onClick={(e) => this.reportMis(e, key)}>
                  误报
                </button>
              </div>
            </div>
          </div>

          <div class="progress">
            <span class="progress-inner"></span>
          </div>
        </div>
      );
    },
    reportClick() {
      this.eventVisible = true;
    },
    closeDetail() {
      this.eventVisible = false;
    },
    reportConfirm(e, key) {
      this.$notification.close(key);
      e.stopPropagation();
    },
    reportMis(e, key) {
      this.$notification.close(key);
      e.stopPropagation();
    },
    misReportParentHandle() {},
    getsocketData(e) {
      // 创建接收消息函数
      const data = e && JSON.parse(e.detail.data);
      //'G50沪渝高速渝长下行录制时长已超过30分钟，请及时处理！'
      if (data.notifyInfo) {
        this.$notification.open({
          message: '系统提示',
          description: `${data.notifyInfo}`,
          placement: 'bottomLeft',
          bottom: 0,
          duration: null,
        });
      } else if (data.type == 2) {
        //本地录制
        this.$confirm({
          title: '录制结束提示',
          content:
            '视频录制已完成，是否立即下载？您也可以到【录像管理】中进行查看。',
          okText: '立即下载',
          cancelText: '取消',
          class: 'warning-modal2',
          centered: true,
          onOk() {
            let newData = JSON.parse(data.data);
            window.open(newData.ossPath, '_blank');
          },
          onCancel() {},
        });
      } else if (data.type == 3) {
        // 录制消息通知自动录制
       /*  this.$warning({
          title: '录制提示',
          content:
            '视频录制完成，请在【资源中心】-【录像管理】中进行查看处理。',
          okText: '确定',
          class: 'warning-modal3',
          centered: true,
          onOk() {},
        }); */
        this.$notification.open({
          message: '系统提示',
          description: '视频录制完成，请在【资源中心】-【录像管理】中进行查看处理。',
          placement: 'bottomLeft',
          bottom: 0,
          duration: 3,
        });
      }
      console.log('socket message', e);
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    resizeWindow() {
      Public.pageResize();
    },
    resizePage() {
      let bi = this.screenWidth / (this.screenHeight * 3.1);
      let appDom = document.getElementById('app');
      appDom.style.width = this.screenHeight * 3.1 + 'px';
      appDom.style.height = this.screenHeight + 'px';
      appDom.style.overflow = 'hidden';
      appDom.style.transform = `scale(${bi},1)`;
      appDom.style['transform-origin'] = 'left top';
    },
    async onResize() {
      await this.initSize();
      this.updateScale();
    },
    initSize() {
      return new Promise((resolve) => {
        this.dom = this.$refs.container;
        let domWidth = this.dom.offsetWidth;
        let domHeight = this.dom.offsetHeight;
        this.width = 0;
        this.height = 0;
        this.originalWidth = 0;
        this.originalHeight = 0;
        // 获取大屏的真实尺寸
        if (domWidth && domHeight) {
          this.width = domWidth;
          this.height = domHeight;
        } else {
          this.width = this.dom.clientWidth;
          this.height = this.dom.clientHeight;
        }
        // 获取画布尺寸
        if (!this.originalWidth || !this.originalHeight) {
          this.originalWidth = window.screen.width;
          this.originalHeight = window.screen.height;
        }
        // console.log(width.value, height.value, originalWidth.value, originalHeight.value)
        resolve();
      });
    },
    updateSize() {
      if (this.width && this.height) {
        this.dom.style.width = `${this.width}px`;
        this.dom.style.height = `${this.height}px`;
      } else {
        this.dom.style.width = `${this.originalWidth}px`;
        this.dom.style.height = `${this.originalHeight}px`;
      }
    },
    updateScale() {
      // 获取真实的视口尺寸
      const currentWidth = document.body.clientWidth;
      const currentHeight = document.body.clientHeight;
      // 获取大屏最终的宽高
      const realWidth = this.width || this.originalWidth;
      const realHeight = this.height || this.originalHeight;
      // console.log(currentWidth, currentHeight)
      const widthScale = currentWidth / realWidth;
      const heightScale = currentHeight / realHeight;
      this.dom &&
        (this.dom.style.transform = `scale(${widthScale}, ${heightScale})`);
    },
    ...mapMutations(['setDrawerDom']),

    showLoading(options) {
      this.fullLoadingShow = true;
      this.loadingTip = options?.tip || 'loading';
      this.loadingDelay = options?.delay || 0;
      let duration = options?.duration || 3;
      options?.duration === 0 && (duration = 60);
      setTimeout(() => {
        this.fullLoadingShow = false;
      }, duration * 1000);
    },

    closeLoading() {
      this.fullLoadingShow = false;
    },
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.resizeWindow.bind(this));
    // 在需要的时候卸载监听事件，比如离开页面
    window.removeEventListener('onmessageWS', this.getsocketData);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
  background: #0f182d;
  /* padding: 0 10px 10px; */
  /*  font-size: 92px; */
}
.pdm {
  padding: 0 10px 10px;
}
.home-inner {
  border: 1px solid #4a6080;
  border-top: none;
  height: calc(100% - 67px);
  padding: 0 15px 20px;
  background: #0f182d url('./assets/home/home-bg.jpg') bottom center no-repeat;
}
/* #container {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  transform-origin: left top;
  z-index: 999;
} */

.full-loading-fade-enter-active,
.full-loading-fade-leave-active {
  transition: opacity 0.3s;
}

.full-loading-fade-enter, .full-loading-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.full-loading-layer {
  background-color: #00000085;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;
}
</style>
<style lang="less" scoped>
.report-container {
  padding-top: 20px;
  img {
    display: block;
    width: 100%;
    height: 182px;
    max-width: 100%;
  }
  .report-tit {
    color: #d6d9dd;
  }
  .report-footer {
    position: relative;
    padding-left: 28px;
    color: rgba(123, 129, 139, 1);
    &::before {
      content: '';
      position: absolute;
      background: url('~@/assets/home/report-icon.png') no-repeat;
      background-size: 100% 100%;
      width: 22px;
      height: 18px;
      left: 0;
      top: 2px;
    }
  }
  .btn {
    button {
      width: 62px;
      height: 30px;
      line-height: 30px;
      background: linear-gradient(0deg, #1c5bb2 0%, #0f283d 100%);
      border: 1px solid;
      border-image: linear-gradient(0deg, #4679bf 0%, #143354 100%) 1 1;
      border-radius: 3px;
      color: #fff;
      font-size: 14px;
      &:last-child {
        margin-left: 10px;
        background: linear-gradient(0deg, #b86f19 0%, #3d280f 100%);
        border-image: linear-gradient(0deg, #dd8a28 0%, #513412 100%) 1 1;
      }
    }
  }
  .progress {
    height: 2px;
    width: 100%;
    background: #ccc;
    position: absolute;
    left: 0;
    bottom: 0;
    .progress-inner {
      display: block;
      height: 100%;
      background: green;
      animation: aniamateWd 5s linear;
      @keyframes aniamateWd {
        0% {
          width: 0%;
        }
        100% {
          width: 100%;
        }
      }
    }
  }
}
</style>
