import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const authority = {
  getAuthority(data) {
    return axios
      .get(`${base.sq}/user/function/tree?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  searchRepeatFunction(funcName) {
    return axios
      .get(`${base.sq}/user/function/function/${funcName}`)
      .then(({ data }) => {
        return data;
      });
  },
  addFunction(data) {
    return axios
      .post(`${base.sq}/user/function/functions`, data)
      .then(({ data }) => {
        return data;
      });
  },
  modifyFunction(data) {
    return axios
      .put(`${base.sq}/user/function/function`, data)
      .then(({ data }) => {
        return data;
      });
  }
};
export default authority;
