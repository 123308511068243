<template>
  <a-modal
    title="修改密码"
    width="30%"
    :footer="null"
    :visible="visible"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <!--  <div class="width-70 m-a">
      <div class="flex items-center  m-b">
        <span class="wd100 text-right">旧密码：</span>
        <a-input
          class="flex-one"
          placeholder="请填写"
          v-model="params.oldPass"
        />
      </div>
      <div class="flex items-center m-b">
        <span class="wd100 text-right">新密码：</span>
        <a-input class="flex-one" placeholder="请填写" v-model="params.pass" />
      </div>
      <div class="flex items-center m-b">
        <span class="wd100 text-right">确认新密码：</span>
        <a-input
          class="flex-one"
          placeholder="请填写"
          v-model="params.confPass"
        />
      </div>
    </div> -->
    <div class="mod-container">
      <div class="mod-con-inner">
        <div class="form-container">
          <a-form-model
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
          <a-form-model-item label="旧密码" ref="oldPass" prop="oldPass">
              <div class="form-flex">
                <a-input
                  v-model="ruleForm.oldPass"
                  :type="oldPasswordType"
                  autocomplete="off"
                  class="flex-one"
                  @blur="
                    () => {
                      $refs.oldPass.onFieldBlur();
                    }
                  "
                ></a-input>
                <i
                  :class="[
                    {
                      'iconfont iconxianshi': oldPasswordType === 'text',
                      'iconfont iconyincang': oldPasswordType === 'password',
                    },
                    'pointer',
                  ]"
                  @click="toggleType('oldPasswordType')"
                />
              </div>
            </a-form-model-item>
            <a-form-model-item label="新密码" ref="newPass" prop="pass">
              <div class="form-flex">
                <a-input
                  v-model="ruleForm.pass"
                  :type="passwordType"
                  autocomplete="off"
                  @focus="showTip = true"
                  @input="checkStrong()"
                  class="flex-one"
                   @blur="
                    () => {
                      $refs.newPass.onFieldBlur();
                      showTip = false
                    }
                  "
                ></a-input>
                <i
                  :class="[
                    {
                      'iconfont iconxianshi': passwordType === 'text',
                      'iconfont iconyincang': passwordType === 'password',
                    },
                    'pointer',
                  ]"
                  @click="toggleType('passwordType')"
                /><!--showTip-->
                <div class="tips" v-show="showTip">
                  <p
                    :class="{
                      error: passwordRule['len'] == 0,
                      success: passwordRule['len'] == 1,
                    }"
                  >
                    长度8~20个字符；
                  </p>
                  <p
                    :class="{
                      error: passwordRule['alp'] == 0,
                      success: passwordRule['alp'] == 1,
                    }"
                  >
                    必须包含字母(区分大小写)；
                  </p>
                  <p
                    :class="{
                      error: passwordRule['num'] == 0,
                      success: passwordRule['num'] == 1,
                    }"
                  >
                    至少有一个数字；
                  </p>
                  <p
                    :class="{
                      error: passwordRule['chi'] == 0,
                      success: passwordRule['chi'] == 1,
                    }"
                  >
                    不允许有空格，中文；
                  </p>
                   <p
                    :class="{
                      error: passwordRule['fh'] == 0,
                      success: passwordRule['fh'] == 1,
                    }"
                  >
                    必须包含特殊符号；
                  </p>
                  <p
                    :class="{
                      error: passwordRule['ac'] == 0,
                      success: passwordRule['ac'] == 1,
                    }"
                  >
                    不能与账户名称相同；
                  </p>
                </div>
              </div>
            </a-form-model-item>
            <a-form-model-item label="密码强度">
              <div class="form-flex-container">
                <span
                  class="flex-one sp-item"
                  :class="{
                    'stren-1': currentStrength.includes(0),
                  }"
                  >弱</span
                >
                <span
                  class="flex-one sp-item"
                  :class="{
                    'stren-2': currentStrength.includes(1),
                  }"
                  >中</span
                >
                <span
                  class="flex-one sp-item"
                  :class="{
                    'stren-3': currentStrength.includes(2),
                  }"
                  >强</span
                >
              </div>
              <div class="line-tip text-lightblue">
                为了提升您的密码安全性，建议使用英文字母加数字或字符组合密码
              </div>
            </a-form-model-item>
            <a-form-model-item label="确认密码" ref="checkPass" prop="checkPass">
              <div class="form-flex">
                <a-input
                  v-model="ruleForm.checkPass"
                  :type="conPasswordType"
                  autocomplete="off"
                  @focus="showConfTip = true"
                  @input="checkConfirm"
                  class="flex-one"
                  @blur="
                    () => {
                      $refs.checkPass.onFieldBlur();
                      showConfTip = false
                    }
                  "
                ></a-input>
                <i
                  :class="[
                    {
                      'iconfont iconxianshi': conPasswordType === 'text',
                      'iconfont iconyincang': conPasswordType === 'password',
                    },
                    'pointer',
                  ]"
                  @click="toggleType('conPasswordType')"
                />
                <div class="tips" v-show="showConfTip">
                  <p
                    :class="{
                      error: conPasswordRule['sa'] == 0,
                      success: conPasswordRule['sa'] == 1,
                    }"
                  >
                    务必与新密码一致
                  </p>
                </div>
              </div>
            </a-form-model-item>
            <div class="flex items-center justify-center m-t-md m-b-sm">
              <a-button
                type="primary"
                class="m-r-sm"
                :disabled="permitDisabled"
                @click="submitForm()"
                >提交</a-button
              >
              <a-button type="default" @click="handleCancel()">取消</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'ModifyPass',
  /*  data() {
    return {
      visible: false,
      params: {
        oldPass: '',
        pass: '',
        confPass: '',
      },
    };
  }, */
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value === this.ruleForm.oldPass) {
        callback(new Error('新密码与最近的旧密码相同，请重新设置其他密码'));
      }else {
        /* if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        } */
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    var validatePassOld = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入旧密码'));
      }  else {
        callback();
      }
    };
    return {
      visible: false,
      ruleForm: {
        oldPass: '',
        pass: '',
        checkPass: '',
      },
      rules: {
        oldPass: [
          { required: true, message: '旧密码不能为空', trigger: 'blur' },
          { validator: validatePassOld, trigger: 'blur' },
        ],
        pass: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' },
        ],
        checkPass: [
          { required: true, message: '确认密码不能为空', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' },
        ],
      },
      // strengthArr: ['弱', '中', '强'],
      currentStrength: [],
      showTip: false,
      showConfTip: false,
      oldPasswordType:'password',
      passwordType: 'password',
      conPasswordType: 'password',
      loginName: '',
      oldPass: '',
      passwordRule: {
        len: 2,
        alp: 2,
        num: 2,
        chi: 2,
        fh: 2,
        ac: 2,
      },
      conPasswordRule: {
        sa: 2,
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 15 },
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.login.userInfo,
    }),
    permitDisabled() {
      let passBool = false;
      let confirmBool = false;
      let keys = Object.keys(this.passwordRule);
      if(this.ruleForm.oldPass === this.ruleForm.pass) {
        passBool = true;
      }//fixsxy
      keys.forEach((item) => {
        if (this.passwordRule[item] == 2 || this.passwordRule[item] == 0) {
          passBool = true;
        }
      });
      if (this.conPasswordRule.sa == 2 || this.conPasswordRule.sa == 0) {
        confirmBool = true;
      }
      // console.log(passBool, confirmBool);
      return passBool || confirmBool;
    },
  },
  watch: {},
  methods: {
    ...mapActions(['logout','userModifyEncryptPass']),
    show() {
      this.visible = true;
    },
    hide() {
      this.ruleForm.oldPass = '';
      this.ruleForm.pass = '';
      this.ruleForm.checkPass = '';
      this.passwordRule ={
        len: 2,
        alp: 2,
        num: 2,
        chi: 2,
        fh: 2,
        ac: 2,
      };
      this.conPasswordRule ={
        sa: 2,
      };
      this.passwordType = 'password';
      this.conPasswordType ='password';
      this.visible = false;
    },
    handleCancel() {
      if(this.ruleForm.oldPass&&(this.ruleForm.oldPass.trim() === this.ruleForm.pass.trim())) {
        return;
      }
      this.$refs.ruleForm.resetFields();
      this.hide();
    },
    handleOk() {
      if (!this.params.oldPass) {
        this.$message.error('旧密码不能为空');
        return;
      }
      if (!this.params.pass) {
        this.$message.error('密码不能为空');
        return;
      }
      if (this.params.pass !== this.params.confPass) {
        this.$message.error('两次输入密码不一致');
        return;
      }
      let data = {
        oldPassword: this.params.oldPass,
        password: this.params.confPass,
      };
      this.$api.login.modifyPass(data).then((res) => {
        if (res.code === 200) {
          this.$message.success('修改密码成功');
          this.hide();
          this.logout();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    compareToFirstPassword(rule, value, callback) {
      /* const form = this.form;
      if (value && value !== form.getFieldValue('password')) {
        callback('两次输入密码不一致!');
      } else {
        callback();
      } */
      callback();
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // console.log('papa', this.ruleForm, this.loginName, this.oldPass);
          let obj = {
            userId: this.userInfo.userId,
            oldPassword: this.ruleForm.oldPass,
            password: this.ruleForm.pass,
          };
          this.userModifyEncryptPass(obj).then((res)=>{
            if(res.code === 200) {
              this.$message.success('密码重置成功');
              this.hide();
              this.logout();
            } else {
              this.$message.error(res.message);
            }
          }).catch(e => {
             this.$message.error(e);
          })
          /* this.$api.home.modifyEncryptPass(obj).then((res) => {
            if (res.code == 200) {
              this.$message.success('密码重置成功');
              this.hide();
              this.logout();
              
            } else {
              this.$message.error(res.message);
            }
          }); */
        } else {
          return false;
        }
      });
    },
    checkStrong() {
      let sValue = this.ruleForm.pass;
      let modes = [];
      //正则表达式验证符合要求的
      //提示
      if (/^.{8,20}$/.test(sValue)) {
        this.passwordRule.len = 1;
      } else {
        this.passwordRule.len = 0;
      }
      if (/([A-Za-z]{1,20})([0-9]*)/.test(sValue)) {
        this.passwordRule.alp = 1;
      } else {
        this.passwordRule.alp = 0;
      }
      if (/([0-9]{1,20})([A-Za-z]*)/.test(sValue)) {
        this.passwordRule.num = 1;
      } else {
        this.passwordRule.num = 0;
      }
      if (/(\s+)|([\u4e00-\u9fa5]+)/.test(sValue)) {
        this.passwordRule.chi = 0;
      } else {
        this.passwordRule.chi = 1;
      }
      if (
        /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im.test(
          sValue
        )
      ) {
        this.passwordRule.fh = 1;
      } else {
        this.passwordRule.fh = 0;
      }

      if (this.userInfo.loginName && this.userInfo.loginName == sValue) {
        this.passwordRule.ac = 0;
      } else {
        this.passwordRule.ac = 1;
      }

      if (sValue.length < 1) {
        modes = [];
      }
      if (/\d/.test(sValue)) {
        //数字
        modes = [0];
      }
      if (/[a-zA-Z]/.test(sValue)) {
        //大小写
        modes = [0, 1];
      }
      if (/[`~!@#$%^&*()_+<>?:"{},./;'[\]]/.test(sValue)) {
        modes = [0, 1, 2];
      } //特殊字符
      this.currentStrength = modes;
      this.checkConfirm();
    },
    toggleType(type) {
      this[type] = this[type] == 'password' ? 'text' : 'password';
    },
    checkConfirm() {
      let initValue = this.ruleForm.pass;
      let confValue = this.ruleForm.checkPass;
      if (initValue === confValue) {
        this.conPasswordRule.sa = 1;
      } else {
        this.conPasswordRule.sa = 0;
      }
    },
  },
};
</script>

<style scoped>
.wd100 {
  width: 100px;
}
.mod-container >>> .ant-form-item-label > label {
  color: rgba(134, 179, 233, 1);
}
.mod-container >>> .ant-form-item {
  margin-bottom: 5px;
}
/* .mod-container >>> .ant-input {
  color: #666;
} */
.bg-white {
  width: 100%;
  height: 100%;
}
/* .form-container {
 padding: 60px 30% 60px 20%; */
/*   box-shadow: 0px 1px 6px 0px rgba(20, 22, 37, 0.1); */
/*  border-radius: 4px; */
/*  max-height: 420px;
} */
.form-container >>> .el-form .el-form-item .el-form-item__label {
  line-height: 40px;
}
.form-flex {
  position: relative;
}
.form-flex-container {
  display: flex;
}
.form-flex-container .flex-one {
  flex: 1;
}
.form-flex .tips {
  position: absolute;
  width: 210px;
  border: 1px solid rgba(134, 179, 233, 1);
  box-shadow: 0 0 4px 4px #111b31;
  background: #111b31;
  line-height: 1;
  padding: 10px;
  right: -220px;
  top: 0;
  border-radius: 3px;
  color: #fff;
}
.form-flex .tips::before,
.form-flex .tips::after {
  content: '';
  position: absolute;
  left: -17px;
  top: 14px;
  border: 8px solid transparent;
  border-right-color: #6b90be;
  width: 0;
  height: 0;
  z-index: 1;
}
.form-flex .tips::after {
  border: 8px solid transparent;
  border-right-color: #111b31;
  z-index: 2;
  left: -15px;
  top: 14px;
}
.form-flex .tips p {
  margin: 0;
  line-height: 1.2;
  position: relative;
  color: rgba(191, 191, 191, 1);
  padding-left: 10px;
}
.form-flex .tips p::before {
  content: '';
  position: absolute;
  left: 0;
  top: 4px;
  width: 4px;
  height: 4px;
  background: rgba(191, 191, 191, 1);
}
.form-flex .tips p.success {
  color: rgba(78, 176, 59, 1);
}
.form-flex .tips p.success::before {
  content: '√';
  color: rgba(78, 176, 59, 1);
  width: 0;
  height: 0;
  font-weight: bold;
  top: 0;
}
.form-flex .tips p.error {
  color: rgba(252, 67, 67, 1);
}
.form-flex .tips p.error::before {
  content: '×';
  color: rgba(252, 67, 67, 1);
  width: 0;
  height: 0;
  font-weight: bold;
  top: -1px;
}
.form-flex-container .sp-item {
  background: #ececec;
  line-height: 20px;
  text-align: center;
  color: #333;
  margin-top: 10px;
}
.form-flex-container .sp-item:nth-child(2) {
  margin: 10px 10px 0;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.form-flex-container .sp-item.stren-1 {
  background: rgba(252, 67, 67, 1);
  color: #fff;
}
.form-flex-container .sp-item.stren-2 {
  background: rgba(252, 150, 67, 1);
  color: #fff;
}
.form-flex-container .sp-item.stren-3 {
  background: rgba(95, 205, 87, 1);
  color: #fff;
}
.line-tip {
  margin: 5px 0;
  line-height: 1.5;

  font-size: 12px;
}
.modify-pass {
  text-align: center;
  margin-bottom: 40px;
  color: rgb(20, 22, 37);
  margin-left: 15%;
}

.pointer {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 2px;
  color: rgb(164 192 234);
  font-size: 14px;
}
.mod-pass-style {
  text-align: center;
  font-size: 30px;
  margin: 20px 0;
}
.mod-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 86%;
}
.mod-container .mod-con-inner {
  /*   width: 65%;
  max-width: 770px; */
}
/* .p-l-big {
  padding-left: 150px;
} */
/* .icon-eyes {
  background: url('./icon-eyes.png') no-repeat;
  background-size: 100% 100%;
  width: 18px;
  height: 12px;
}
.icon-close {
  background: url('./icon-close.png') no-repeat;
  background-size: 100% 100%;
  width: 18px;
  height: 12px;
} */
</style>
