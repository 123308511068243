/**
 * 获取 Storage 中的数据
 *
 * @param {String} key
 * @param {any} Default  兼容获取空值的情况
 *
 * @return {any}
 *
 * @Author 雨落
 */
// import { isString, isObject } from 'lodash';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import {
  wgs84_to_gcj02 /* , gcj02_to_wgs84 */
} from '@/tools/wgs84_to_gcj02.js';
import store from '@/store/index';
import Vue from 'vue';
import axios from 'axios';
import base from '@/request/api/base';
import qs from 'qs';

export const CLASSIFY = {
  '0': '默认',
  '1': '道路沿线',
  '2': '桥梁',
  '3': '隧道',
  '4': '收费广场',
  '5': '收费站',
  '6': '服务区',
  '7': 'ETC门架',
  '8': '移动视频源'
};

export const DIRECTOIN = {
  '0': '上行',
  '1': '下行',
  '2': '上下行'
};
export const DIRECTOINARROW = {
  '0': '↑',
  '1': '↓',
  '2': '↑↓'
};

export const CAMERATYPE = {
  '1': '监控型枪机',
  '2': '监控型球机',
  '3': '全景型',
  '4': '抓拍型'
};
export const CAMERASTATUS = {
  '0': '离线',
  '1': '在线',
  '2': '异常'
};

export const CAMERASTATUSNEW = {
  '0': '离线',
  '1': '在线',
  '-1': '异常',
  '2': '故障'
};

export const yunNanDefault = {
  northEast: '108.828182,31.037603',
  southWest: '94.897518,21.047298'
};
export const getStorage = (key, Default = '') => {
  let t = window.localStorage.getItem(key);
  t =
    (isString(t) && t.indexOf('{') !== -1) ||
    (isString(t) && t.indexOf('[') !== -1) ?
    JSON.parse(t) :
    t;
  return t || Default;
};
export const getSessionStorage = (key, Default = '') => {
  let t = window.sessionStorage.getItem(key);
  t =
    (isString(t) && t.indexOf('{') !== -1) ||
    (isString(t) && t.indexOf('[') !== -1) ?
    JSON.parse(t) :
    t;
  return t || Default;
};

/**
 * 设置 Storage 中的数据
 *
 * @param {String} key
 * @param {any} data
 *
 * @return {undefined}
 *
 * @Author 雨落
 */
export const setStorage = (key, data) => {
  return window.localStorage.setItem(
    key,
    (isObject(data) && JSON.stringify(data)) || data
  );
};
export const setSessionStorage = (key, data) => {
  return window.sessionStorage.setItem(
    key,
    (isObject(data) && JSON.stringify(data)) || data
  );
};
/**
 * 清除 Storage 中的数据
 *
 * @param {String} key
 * @param {any} data
 *
 * @return {undefined}
 *
 * @Author 雨落
 */
export const clearStorage = key => {
  return window.localStorage.removeItem(key);
};
export const clearSessionStorage = key => {
  return window.sessionStorage.removeItem(key);
};

/**
 * 时间戳转换
 *
 * @property  {String} timestamp
 *
 * @Author 雨落
 */
export function getYMDHMS(timestamp) {
  if (timestamp) {
    let time = new Date(timestamp * 1000);
    let year = time.getFullYear();
    const month = (time.getMonth() + 1).toString().padStart(2, '0');
    const date = time
      .getDate()
      .toString()
      .padStart(2, '0');
    const hours = time
      .getHours()
      .toString()
      .padStart(2, '0');
    const minute = time
      .getMinutes()
      .toString()
      .padStart(2, '0');
    const second = time
      .getSeconds()
      .toString()
      .padStart(2, '0');
    return (
      year +
      '-' +
      month +
      '-' +
      date +
      ' ' +
      hours +
      ':' +
      minute +
      ':' +
      second
    );
  } else {
    return false;
  }
}

export function formatGroupTreeData(dataList) {
  if (Array.isArray(dataList) && !dataList.length) {
    return [];
  }
  let newList = [];
  let keys = Object.keys(dataList);

  keys.forEach((item, index) => {
    let parentLists = parentList(dataList[item]);
    let obj = {
      title: item,
      key: createGuid(),
      baseId: index,
      id: index,
      type: 'ancestor',
      children: parentLists
    };
    newList.push(obj);
  });

  return newList;
}

function parentList(dataList) {
  return dataList.map((item, index) => {
    let obj = {
      title: item.cameraGroupName,
      key: createGuid(),
      type: 'parent',
      index: index,
      scopedSlots: {
        title: 'customtitle'
      },
      // scopedSlots: { customRender: 'tags' },
      children: childList(item.cameraInfoBasePOS)
    };
    return {
      ...item,
      ...obj
    };
  });
}

function childList(dataList) {
  return dataList.map(item => {
    return {
      ...item,
      ...{
        title: item.cameraName,
        key: createGuid(),
        type: 'camera',
        onlineStatus: '1',
        // scopedSlots: { customRender: 'tags' },
        scopedSlots: {
          title: 'customtitle'
        }
      }
    };
  });
}
export function treeDataReCheckNoCamera(dataList, type) {
  //console.info(typeof dataList,Object.keys(dataList));
  if (Array.isArray(dataList) && !dataList.length) {
    return [];
  }
  let newList = [],
    title = {
      province: 'name',
      provinceRoad: 'name',
      provinceRoadNoCamera: 'name',
      organization: 'name',
      yunOrganization: 'name',
      road: 'name',
      city: 'name',
      camera: 'cameraName'
    },
    level = {
      province: 2,
      provinceRoad: 3
    },
    key = {
      province: 'id',
      organization: 'id',
      road: 'id',
      city: 'id',
      camera: 'cameraId',
      provinceRoad: 'id'
    },
    checkTitle = rs => {
      if (type === 'road') {
        return rs[key[type]] + ' ' + rs[title[type]];
      }
      if (type === 'camera') {
        // return "K" + (rs.kmPile || 0) + "+" + (rs.hmPile || 0);修改前
        return rs['khPile']; //修改后
      }

      return rs[title[type]];
    },
    replenishDataFun = data => {
      let nlist = [];
      data.forEach(vo => {
        let oobj = {
          title: checkTitle(vo), //checkTitle(vo),//(type === "road" ? vo[key[type]] + " " : "") + vo[title[type]],
          key: createGuid(),
          //treeTitle: checkTitle(vo) + "<span>"+vo.online + "/" + vo.total+"</span>",
          baseId: vo[key[type]],
          type: type,
          isLeaf: true,
          needType: type === 'province' ?
            'region' :
            type === 'road' ?
            'roadArea' :
            type === 'organization' ?
            'organizationArea' :
            type === 'provinceYun' ?
            'region' :
            type === 'provinceRoad' ?
            'roadArea' :
            '',
          regionCode: vo.regionCode,
          organizationId: vo.organizationId,
          roadId: vo.roadId,
          scopedSlots: {
            title: 'customtitle'
          },
          gradeType: level[type]
        };
        //if(type === "camera") oobj.switcherIcons = { icon: ["offline","online","error"][parseInt(vo.onlineStatus)] };//
        if (type === 'camera')
          oobj.scopedSlots = {
            title: 'customtitle',
            icon: ['offline', 'online', 'error'][parseInt(vo.onlineStatus)]
          };
        //if(type === "camera") oobj.slots = {title:"customtitle", icon: ["offline","online","error"][parseInt(vo.onlineStatus)] };
        //if (type === "camera") oobj.slots.icon = ["offline","online","error"][parseInt(vo.onlineStatus)];
        nlist.push(Object.assign({}, vo, oobj));
      });
      /*  console.info('replenishDataFun  ', nlist); */
      return nlist;
    };
  if (type === 'road' && !Array.isArray(dataList)) {
    let keyAr = Object.keys(dataList);
    keyAr.forEach((vo, idx) => {
      let list = replenishDataFun(dataList[vo]);
      newList.push({
        title: vo,
        key: createGuid(),
        baseId: idx,
        id: idx,
        type: 'roadBefore',
        children: list
      });
    });
  } else if (type === 'yunOrganization') {
    newList = dataList.map((item, idx) => {
      return {
        title: item.organizationName,
        key: createGuid(),
        baseId: idx,
        id: idx,
        type: 'roadBefore',
        children: replenishDataFun(item.childList)
      };
    });
  } else {
    newList = replenishDataFun(dataList);
  }
  return newList;
}

export function treeOrganiztionCheckNoCamera(dataList, type) {
  if (Array.isArray(dataList) && !dataList.length) {
    return [];
  }
  let newList = [],
    checkTitle = rs => {
      return rs['organizationName'];
    },
    treeTrackChild = list => {
      let type = 'roadBefore';
      return list.map((item, idx) => {
        if (item.childList && item.childList.length > 0) {
          item.children = treeTrackChild(item.childList);
        }

        return {
          ...{
            title: checkTitle(item),
            key: createGuid(),
            baseId: item.total === 0 && !item.childList ? item.organizationId : idx,
            id: item.organizationId,
            type,
            needType: 'organizationArea',
            scopedSlots: {
              title: 'customtitle'
            },
            isLeaf: !item.childList || item.childList.length <= 0
          },
          ...item
        };
      });
    };
  if (dataList && dataList.length > 0) {
    /*dataList.forEach(item => {
       
       if (item.childList && item.childList.length) {
        
        newList = treeTrackChild(item.childList);
      } fix以前多层
      
    });*/
    newList = treeTrackChild(dataList);
    //去除第一层scopedSlots
    if (type) {
      console.log('haha', newList);
      // newList = newList[0].children;
    } else {
      newList.forEach(item => {
        delete item.scopedSlots;
      });
    }
  }

  return newList;
}
export function treeOrganiztionCheck(dataList, flag) {
  if (Array.isArray(dataList) && !dataList.length) {
    return [];
  }
  let newList = [],
    checkTitle = rs => {
      return rs['organizationName'];
    },
    treeTrackChild = list => {
      let type = 'roadBefore';
      return list.map((item, idx) => {
        if (item.childList && item.childList.length > 0) {
          item.children = treeTrackChild(item.childList);
        }

        return {
          ...{
            title: checkTitle(item),
            key: createGuid(),
            baseId: item.total === 0 && !item.childList ? item.organizationId : idx,
            id: item.organizationId,
            needType: 'organizationArea',
            type: item.childList && item.childList.length > 0 ? type : 'last',
            scopedSlots: {
              title: 'customtitle'
            },
            isLeaf: item.total === 0 && !item.childList,
            label: checkTitle(item),
            value: item.organizationId,
            check: false
          },
          ...item
        };
      });
    };
  if (dataList && dataList.length > 0) {
    dataList.forEach((item, idx) => {
      let obj = {
        title: checkTitle(item),
        key: createGuid(),
        baseId: idx,
        id: idx,
        type: 'roadBefore',
        label: checkTitle(item),
        value: item.organizationId,
        scopedSlots: {
          title: 'customtitle'
        },
        check: false,
        needType: 'organizationArea'
      };

      if (item.childList && item.childList.length) {
        // obj.children = treeTrackChild(item.childList);

        if (flag) {
          obj.children = treeTrackChild(item.childList);
        } else {
          newList = treeTrackChild(item.childList);
        }
      }
      if (flag) {
        newList.push({
          ...obj,
          ...item
        });
      }
      // newList.push({ ...obj, ...item });
    });
  }
  return newList;
}

export function treeOrganiztionCheckTreeOther(dataList, organizationId, flag) {
  if (Array.isArray(dataList) && !dataList.length) {
    return [];
  }
  let newList = [],
    checkTitle = rs => {
      return rs['organizationName'];
    },
    treeTrackChild = list => {
      let type = 'roadBefore';
      return list.map((item, idx) => {
        if (item.childList && item.childList.length > 0) {
          item.children = treeTrackChild(item.childList);
        }

        return {
          ...{
            title: checkTitle(item),
            key: createGuid(),
            baseId: item.total === 0 && !item.childList ? item.organizationId : idx,
            id: item.organizationId,
            originalOrganizationId: organizationId,
            needType: 'organizationArea',
            type: item.childList && item.childList.length > 0 ? type : 'last',
            scopedSlots: {
              title: 'customtitle'
            },
            isLeaf: item.total === 0 && !item.childList,
            label: checkTitle(item),
            value: item.organizationId,
            check: false
          },
          ...item
        };
      });
    };
  if (dataList && dataList.length > 0) {
    dataList.forEach((item, idx) => {
      if (item.childList && item.childList.length) {
        // obj.children = treeTrackChild(item.childList);
        // let labelContent = `<div class='labelContent'>${item.online}</div>`
        let obj = {
          title: checkTitle(item),
          // title: checkTitle(item) + '(' + item.online + '/' + item.total + ')',
          // title: checkTitle(item) + '(' + `<div style="color:#000">${item.online}</div>`  + '/' + item.total + ')',
          key: createGuid(),
          baseId: idx,
          id: idx,
          type: item.childList && item.childList.length > 0 ? 'roadBefore' : 'last',
          label: checkTitle(item),
          value: item.organizationId,
          scopedSlots: {
            title: 'customtitle'
          },
          check: false,
          needType: 'organizationArea'
        };
        if (flag) {
          // delete obj.scopedSlots;
          obj.children = treeTrackChild(item.childList);
        } else {
          newList = treeTrackChild(item.childList);
        }

        if (flag) {
          newList.push({
            ...obj,
            ...item
          });
        }
      }

      // newList.push({ ...obj, ...item });
    });
  }
  console.log('newList', newList)
  return newList;
}
export function treeOrganiztionCheckTree(dataList, flag) {
  if (Array.isArray(dataList) && !dataList.length) {
    return [];
  }
  let newList = [],
    checkTitle = rs => {
      return rs['organizationName'];
    },
    treeTrackChild = list => {
      let type = 'roadBefore';
      return list.map((item, idx) => {
        if (item.childList && item.childList.length > 0) {
          item.children = treeTrackChild(item.childList);
        }

        return {
          ...{
            title: checkTitle(item),
            key: createGuid(),
            baseId: item.total === 0 && !item.childList ? item.organizationId : idx,
            id: item.organizationId,
            needType: 'organizationArea',
            type: item.childList && item.childList.length > 0 ? type : 'last',
            scopedSlots: {
              title: 'customtitle'
            },
            isLeaf: item.total === 0 && !item.childList,
            label: checkTitle(item),
            value: item.organizationId,
            check: false
          },
          ...item
        };
      });
    };
  if (dataList && dataList.length > 0) {
    dataList.forEach((item, idx) => {
      let obj = {
        title: checkTitle(item),
        key: createGuid(),
        baseId: idx,
        id: idx,
        type: item.childList && item.childList.length > 0 ? 'roadBefore' : 'last',
        label: checkTitle(item),
        value: item.organizationId,
        scopedSlots: {
          title: 'customtitle'
        },
        check: false,
        needType: 'organizationArea'
      };

      if (item.childList && item.childList.length) {
        // obj.children = treeTrackChild(item.childList);

        if (flag) {
          // delete obj.scopedSlots;
          obj.children = treeTrackChild(item.childList);
        } else {
          newList = treeTrackChild(item.childList);
        }
      }
      if (flag) {
        newList.push({
          ...obj,
          ...item
        });
      }
      // newList.push({ ...obj, ...item });
    });
  }
  return newList;
}
export function treeOrganiztionCheckNet(dataList) {
  if (Array.isArray(dataList) && !dataList.length) {
    return [];
  }
  let newList = [],
    checkTitle = rs => {
      return rs['organizationName'];
    },
    treeTrackChild = list => {
      return list.map(item => {
        if (item.childList && item.childList.length > 0) {
          item.children = treeTrackChild(item.childList);
        }
        return {
          ...item,
          ...{
            id: item.organizationId,
            label: checkTitle(item),
            value: item.organizationId
          }
        };
      });
    };
  if (dataList && dataList.length > 0) {
    dataList.forEach((item, id) => {
      let obj = {
        id,
        key: createGuid(),
        label: checkTitle(item),
        value: item.organizationId
      };

      if (item.childList && item.childList.length) {
        // obj.children = treeTrackChild(item.childList);

        obj.children = treeTrackChild(item.childList);
      }

      newList.push({
        ...item,
        ...obj
      });

      // newList.push({ ...obj, ...item });
    });
  }
  return newList;
}
export function treeTrackProvince(dataList, id) {
  let arr = treeTrackCameraCheck(dataList);
  let curIndex = -1;
  arr = arr.map((item, index) => {
    if (item.id === id) {
      item.check = true;
      curIndex = index;
    }
    return item;
  });
  return {
    cameraList: arr,
    curIndex
  };
}
export function treeTrackCameraMulCheck(dataList, dataRef) {
  let arr = [];

  function trackTree(childlist) {
    return childlist.map(child => {
      /*  if (dataRef && dataRef.key === child.key) {
        child.check = dataRef.check;
      } */
      /* else {
             child.check = false;
           } */
      child.check =
        dataRef && dataRef.key === child.key ? dataRef.check : false;
      if (child.children && child.children.length > 0) {
        child.children = trackTree(child.children);
      }
      return child;
    });
  }
  /*  arr = dataList.map(item => {
    if (dataRef && dataRef.key === item.key) {
      item.check = dataRef.check;
    } else {
      item.check = false;
    }

    if (item.children && item.children.length > 0) {
      item.children = trackTree(item.children);
    }
    return item;
  }); */
  arr = trackTree(dataList);
  return arr;
}
export function treeTrackCameraCheck(dataList, dataRef) {
  let arr = [];

  function trackTree(childlist) {
    return childlist.map(child => {
      if (dataRef && dataRef.key === child.key) {
        child.check = dataRef.check;
      } else {
        child.check = false;
      }
      if (child.children && child.children.length > 0) {
        child.children = trackTree(child.children);
      }
      return child;
    });
  }
  arr = dataList.map(item => {
    if (dataRef && dataRef.key === item.key) {
      item.check = dataRef.check;
    } else {
      item.check = false;
    }

    if (item.children && item.children.length > 0) {
      item.children = trackTree(item.children);
    }
    return item;
  });
  return arr;
}
export function treeDataReCheck(dataList, type, typePa) {
  //console.info(typeof dataList,Object.keys(dataList));
  if (Array.isArray(dataList) && !dataList.length) {
    return [];
  }
  let newList = [],
    title = {
      province: 'name',
      provinceRoad: 'name',
      provinceRoadNoCamera: 'name',
      organization: 'name',
      yunOrganization: 'name',
      road: 'name',
      city: 'name',
      camera: 'cameraName',
      provinceYun: 'name'
    },
    level = {
      province: 2,
      provinceYun: 2,
      provinceRoad: 3
    },
    key = {
      province: 'id',
      organization: 'id',
      road: 'id',
      city: 'id',
      camera: 'cameraId',
      provinceRoad: 'id'
    },
    checkTitle = rs => {
      if (type === 'road') {
        return rs[key[type]] + ' ' + rs[title[type]];
      }
      if (type === 'camera') {
        // return "K" + (rs.kmPile || 0) + "+" + (rs.hmPile || 0);修改前
        return rs['khPile']; //修改后
      }

      return rs[title[type]];
    },
    replenishDataFun = data => {
      let nlist = [];
      data.forEach(vo => {
        let oobj = {
          title: checkTitle(vo), //checkTitle(vo),//(type === "road" ? vo[key[type]] + " " : "") + vo[title[type]],
          key: createGuid(),
          //treeTitle: checkTitle(vo) + "<span>"+vo.online + "/" + vo.total+"</span>",
          baseId: vo[key[type]],
          type: type,
          needType: type === 'province' ?
            'region' :
            type === 'road' ?
            'roadArea' :
            type === 'organization' ?
            'organizationArea' :
            type === 'provinceYun' ?
            'region' :
            type === 'provinceRoad' ?
            'roadArea' :
            '',
          regionCode: vo.regionCode,
          organizationId: vo.organizationId,
          roadId: vo.roadId,
          typePa,
          isLeaf: type === 'camera' || type === 'provinceRoadNoCamera',
          scopedSlots: {
            title: 'customtitle'
          },
          gradeType: level[type],
          check: false
        };
        //if(type === "camera") oobj.switcherIcons = { icon: ["offline","online","error"][parseInt(vo.onlineStatus)] };//
        if (type === 'camera')
          oobj.scopedSlots = {
            title: 'customtitle',
            icon: ['offline', 'online', 'error'][parseInt(vo.onlineStatus)]
          };
        //if(type === "camera") oobj.slots = {title:"customtitle", icon: ["offline","online","error"][parseInt(vo.onlineStatus)] };
        //if (type === "camera") oobj.slots.icon = ["offline","online","error"][parseInt(vo.onlineStatus)];
        nlist.push(Object.assign({}, vo, oobj));
      });
      /*  console.info('replenishDataFun  ', nlist); */
      return nlist;
    };
  if (type === 'road' && !Array.isArray(dataList)) {
    let keyAr = Object.keys(dataList);
    keyAr.forEach((vo, idx) => {
      let list = replenishDataFun(dataList[vo]);
      newList.push({
        title: vo,
        key: createGuid(),
        baseId: idx,
        id: idx,
        type: 'roadBefore',
        children: list
      });
    });
  } else if (type === 'yunOrganization') {
    newList = dataList.map((item, idx) => {
      return {
        title: item.organizationName,
        key: createGuid(),
        baseId: idx,
        id: idx,
        type: 'roadBefore',
        children: replenishDataFun(item.childList)
      };
    });
  } else if (type === 'provinceYun') {
    newList = dataList.map((item, idx) => {
      // console.error(item);
      return {
        title: item.name,
        key: createGuid(),
        baseId: idx,
        id: idx,
        type: 'roadBefore',
        online: item.online,
        total: item.total,
        needType: 'region',
        regionCode: item.regionCode,
        scopedSlots: {
          title: 'customtitle'
        },
        children: replenishDataFun(item.netWorkingStatusResp)
      };
    });
  } else {
    newList = replenishDataFun(dataList);
  }
  return newList;
}

/**
 * uuid(v4)生成
 * @returns {string}
 */
export function createGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
/**
 * 查询摄像机统计数据
 * @param data
 * @param callback
 * @returns {boolean}
 */
export function queryStaticDataList(postdata, callback) {
  if (!postdata.dimensionType) {
    return false;
  }

  if (!postdata.data) postdata.data = {};
  //{dimensionType:'province'}

  if (postdata.dimensionType === 'camera') {
    queryCameraStaticDataList(postdata, callback);
    return;
  }

  Vue.prototype.$api.home
    .getCameralList(postdata.dimensionType, postdata.data)
    .then(({
      data
    }) => {
      //console.info('queryStaticDataList', res);
      // console.error(data);
      if (data && data.code === 200) {
        if (data.data) {
          //&& res.data.length > 0
          let dimensionType = postdata.dimensionType;
          data.data = treeDataReCheck(data.data, dimensionType);
        }
        //_this.setCameraStatisticsByRegion(res.data);
        if (typeof callback === 'function') {
          callback(data);
        }
      }
    });
}

/**
 * 查询摄像机详细信息列表
 * @returns {boolean}
 */
export function queryCameraStaticDataList(postData, callback) {
  //getCameraListForStatis
  let args = {
    currPage: 1,
    pageSize: 2000, // 暂未使用分页功能
    ...postData.data
  };
  //获取路径所选的所有参数
  //args.data = _this.getPreParamsAr();

  //查询数据
  Vue.prototype.$api.home.getCameraListForStatis(args).then(({
    data
  }) => {
    // debugger;
    if (data && data.code === 200) {
      if (data.data) {
        //&& res.data.length > 0
        data.data = treeDataReCheck(data.data, postData.dimensionType);
      }
      //_this.setCameraStatisticsByRegion(res.data);
      if (typeof callback === 'function') {
        callback(data);
      }
    }
  });
}

/* export function refreshAmapMarks(
   type,
  dataRef,
  isSearch
) {
  // debugger;
  // this.eventRegionCode = currRegionCode;
  // let vm = new Vue();
  let mapInstance = store.getters.getMapInstance;
  let botClassify = store.getters.getBotClassify;
  let onlineStatus = store.getters.getOnlineStatus;
  let addressValue = store.getters.getAddressValue;
  let latValue = store.getters.getLatValue;
  let searchValue = store.getters.getSearchValue;
  latValue;
  if (dataRef && dataRef.type == 'roadBefore' && type == 'road') {
    return;
  }
  // console.error(mapInstance);
  let  
    mymap = mapInstance.mapInstance,
    bounds = mymap.getBounds(),
    zoom = mymap.getZoom(),
    postBody = {
      cityCode: '',
      classify: '',
      orgnizationId: '',
      provinceCode: '',
      road: ''
    };
   
  if (dataRef) {
    switch (dataRef.type) {
      case 'province':
        postBody.provinceCode = parseInt(dataRef.id);
        break;
      case 'city':
        postBody.cityCode = parseInt(dataRef.id);
        break;
      case 'organization':
        postBody.organizationId = dataRef.id;
        break;
      case 'road':
        postBody.road = dataRef.id;
        break;
      case 'roadBefore':
        postBody.classify = dataRef.title;
        break;
    }
  }
  // console.error('haha--------------', type, dataRef, postBody);
  let data = {};

  data = {
    params: {
      zoomLevel: zoom, //13,
      northEast: bounds.northeast.toString(),
      southWest: bounds.southwest.toString()
    },
    data: postBody
  };
  // console.error('kaka---------', bounds, data);
  clearSessionStorage('currentCamera');
  Vue.prototype.$api.home.getCameraListForPot(data).then(res => {
    //根据选中的状态、类型筛选数据
    getCountStatus(res.data);

    let newArr = res.data.filter(item => {
      // console.log(vo);
      if (
        botClassify.includes(item.classify) &&
        onlineStatus.includes(item.onlineStatus)
      ) {
        return true;
      }
    });
    if (isSearch) {
      newArr = searchData(newArr, searchValue);
    }
    //TODO: 层级变化查询接口，返回摄像头路线字段为 road
    // console.error('haha-------', newArr);
    mapInstance.pointsSetIn(newArr);
    if (isSearch) {
      if (addressValue) {
        if (addressValue.includes('K')) {
          setLocation(res.data, addressValue);
        } else {
          mapInstance.getLatByAddress(addressValue);
        }
      } else if (latValue) {
        // console.error('------', latValue);
        mapInstance.getAddressByLat(latValue.split(','));
      }
    }
  });
} */
//取消点位请求
const CancelToken = axios.CancelToken;
let cancel;
export function refreshAmapMarks(
  /* currRegionCode, */
  type,
  dataRef,
  isSearch
) {
  cancel && cancel('取消点位请求哦');
  // this.eventRegionCode = currRegionCode;
  // let vm = new Vue();
  let mapInstance = store.getters.getMapInstance;
  let botClassify = store.getters.getBotClassify;
  let onlineStatus = store.getters.getOnlineStatus;
  let addressValue = store.getters.getAddressValue;
  let latValue = store.getters.getLatValue;
  let searchValue = store.getters.getSearchValue;
  let defaultZoom = store.getters.getDefaultZoom;
  let searchCameraId = store.getters.getSearchCameraId;
  let isSearchBtn = store.getters.geIsSearchBtn;
  //判断是否是搜索动作
  let getSearchOk = store.getters.getSearchOk;
  // console.error('sxy', botClassify);
  if (
    dataRef &&
    dataRef.type == 'roadBefore' &&
    !dataRef.type2 &&
    type == 'road' &&
    !getSearchOk
  ) {
    return;
  }
  // console.error(mapInstance);
  let qfMap = window.g.qfMap;
  let mymap = mapInstance.mapInstance /* _this = this, */ ,
    bounds = '',
    zoom = 0,
    northEast,
    southWest,
    postBody = {
      cityCode: '',
      classify: '',
      orgnizationId: '',
      provinceCode: '',
      road: '',
      highwayFlag: 1
    };

  if (qfMap) {
    let view = mymap.getView();
    // let center = view.getProjection();
    /*     let ext = center.getExtent(); */
    let mapSize = view.calculateExtent(mymap.getSize());
    let firstNode = mercatorTolonlat({
      x: mapSize[0],
      y: mapSize[1]
    });
    var firstNodeLonLat = wgs84_to_gcj02(firstNode.x, firstNode.y);
    let secondNode = mercatorTolonlat({
      x: mapSize[2],
      y: mapSize[3]
    });
    let secondNodeLonLat = wgs84_to_gcj02(secondNode.x, secondNode.y);
    zoom = view.getZoom();
    northEast = secondNodeLonLat.join(',');
    southWest = firstNodeLonLat.join(',');
  } else {
    bounds = mymap.getBounds();
    zoom = mymap.getZoom();
    console.log('zoom', zoom)
    northEast = bounds.northeast.toString();
    southWest = bounds.southwest.toString();
    /* if (searchValue) {
      northEast = yunNanDefault.northEast;
      southWest = yunNanDefault.southWest;
    } */
  }
  console.log('kaka', northEast, southWest);
  /* let number = 0;
  number++; */

  /*  if (number > 0 && zoom > 5) {
    _this.chinamapVis = false;
  } else {
    _this.chinamapVis = true;
  } */
  if (dataRef) {
    if (Vue.prototype.$g.treeDefinate || Vue.prototype.$g.treeProvince) {
      switch (dataRef.type2) {
        case 'province_region':
          postBody.provinceCode = dataRef.parentId;
          postBody.cityCode = dataRef.cityCode;
          postBody.road = dataRef.roadId2;
          break;
        case 'roadBefore_organ':
          postBody.organizationId = dataRef.id;
          break;
        case 'road_road':
          postBody.road = dataRef.id;
          break;
        case 'organ_other':
          postBody.organizationId = dataRef.id;
          postBody.highwayFlag = '';
          break;
      }
    } else {
      switch (dataRef.type) {
        case 'province':
          postBody.provinceCode = parseInt(dataRef.id);
          break;
        case 'city':
          postBody.cityCode = parseInt(dataRef.id);
          break;
        case 'organization':
          postBody.organizationId = dataRef.id;
          break;
        case 'road':
          postBody.road = dataRef.id;
          break;
        case 'roadBefore':
          postBody.classify = dataRef.title;
          break;
      }
    }
    if (Vue.prototype.$g.treeDefinate) {
      if (dataRef.highwayFlag) {
        postBody.highwayFlag = dataRef.highwayFlag;
      }
      if (dataRef.orgnizationId) {
        postBody.organizationId = dataRef.orgnizationId;
        postBody.highwayFlag = '';
      }
    }
  }
  // console.error('haha--------------', type, dataRef, postBody);
  let data = {};
  data = {
    params: {
      zoomLevel: zoom, //13,
      northEast,
      southWest,
      searchBlock: isSearchBtn ? searchValue : ''
    },
    data: postBody
  };
  //为了让搜索的时候一直定位图层18层
  if (data.params.searchBlock) {
    data.params.zoomLevel = 18;
  }
  if (searchCameraId) {
    // delete data.params.searchBlock;
    if (searchCameraId.searchResultType === 1) {
      /* data.data.cameraId = searchCameraId.cameraId;
      if (searchValue) {
        data.params.zoomLevel = 18;
      } */
    } else if (searchCameraId.searchResultType === 3) {
      data.data.roadId = searchCameraId.roadId;
      if (searchValue) {
        data.params.zoomLevel = mymap.getZoom();
      } //fixsxy重新使用
    } else {
      data.data.organizationId = searchCameraId.organizationId;
      if (searchValue) {
        data.params.zoomLevel = mymap.getZoom();
      } //fixsxy重新使用
    }
  }
  if (isSearch) {
    if (searchValue) {
      data.params.zoomLevel = 18;
    } else {
      data.params.zoomLevel = defaultZoom;
    }
    data.data = {
      cityCode: '',
      classify: '',
      orgnizationId: '',

      road: ''
    };
    if (!Vue.prototype.$g.isDepart) {
      data.data.provinceCode = '';
    } else {
      data.data.provinceCode = dataRef?.id && parseInt(dataRef.id);
    }
    /* if (searchCameraId) {
      data.data.cameraId = searchCameraId;
    } */
  }
  /* else {
     data.params.searchBlock = '';
   } */
  // console.error('kaka---------', bounds, data);

  clearSessionStorage('currentCamera');
  let userInfo = store.getters.getUserInfo;
  const token = userInfo ? userInfo.Authorization : null;
  // Vue.prototype.$api.home.getCameraListForPot(data).then(res => { fix 2022-01-13
  /* Vue.prototype.$api.home.getCameraListForPot(data).then(res => { */
  axios
    .post(
      `${base.sq}/monitor/cam-fav/homepagePot?${qs.stringify(data.params)}`,
      data.data, {
        headers: {
          Authorization: token
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        })
      },

    )
    .then(({
      data
    }) => {
      //根据选中的状态、类型筛选数据
      // console.log('哈哈',res.data);//这个就是搜索的所有摄像机组
      const res = data;
      if (res.code == 200) {
        getCountStatus(res.data);
        let newArr = res.data.filter(item => {
          // console.log(item);
          if (qfMap) {
            // console.log(qfMap);//true
            // let lonlat1 = gcj02_to_wgs84(item.longitude, item.latitude);
            let lonLat = wgs84_to_gcj02(item.longitude, item.latitude);
            /*   item.longitude = lonLat[0];
            item.latitude = lonLat[1]; */
            item.long = lonLat[0];
            item.lat = lonLat[1];
            // console.log([item.longitude, item.latitude], /*  lonlat1, */ lonLat);
            //不出海不出国
            /* if (
              botClassify.includes(item.classify) &&
              onlineStatus.includes(item.onlineStatus) && 
              item.longitude >= MINLONG &&
              item.longitude <= MAXLONG &&
              item.latitude >= MINLAT &&
              item.latitude <= MAXLAT
            ) {
              
              return true;
            } */
          }

          // console.log(vo);
          if (
            botClassify.includes(item.classify) &&
            onlineStatus.includes(item.onlineStatus)
          ) {
            return true;
          }
        });
        /* if (isSearch) {
          newArr = searchData(newArr, searchValue);
        } fixsxy去掉过去界面搜索*/
        //TODO: 层级变化查询接口，返回摄像头路线字段为 road
        // console.error('haha-------', newArr);
        mapInstance.pointsSetIn(newArr);
        if (isSearch) {
          if (addressValue) {
            if (addressValue.includes('K')) {
              setLocation(res.data, addressValue);
            } else {
              mapInstance.getLatByAddress(addressValue);
            }
          } else if (latValue) {
            // console.error('------', latValue);
            mapInstance.getAddressByLat(latValue.split(','));
          }
        }
      }

    });
}
export function refreshOrganizationAmapMarks(newData) {
  let mapInstance = store.getters.getMapInstance;

  let mymap = mapInstance.mapInstance,
    zoom = 18,
    northEast,
    southWest,
    postBody = {
      cityCode: '',
      classify: '',
      currentOrganizationId: newData.type == 'exception' ? newData.organizationId : '',
      provinceCode: '',
      regionCode: newData.type == 'RoadTraffic' ? newData.regionCode : '',
      road: newData.type == 'RoadTraffic' ? newData.roadCode : ''
    };

  let view = mymap.getView();
  // let center = view.getProjection();
  /*     let ext = center.getExtent(); */
  let mapSize = view.calculateExtent(mymap.getSize());
  let firstNode = mercatorTolonlat({
    x: mapSize[0],
    y: mapSize[1]
  });
  var firstNodeLonLat = wgs84_to_gcj02(firstNode.x, firstNode.y);
  let secondNode = mercatorTolonlat({
    x: mapSize[2],
    y: mapSize[3]
  });
  let secondNodeLonLat = wgs84_to_gcj02(secondNode.x, secondNode.y);
  // zoom = view.getZoom();
  northEast = secondNodeLonLat.join(',');
  southWest = firstNodeLonLat.join(',');

  let data = {};
  data = {
    params: {
      zoomLevel: zoom, //13,
      northEast,
      southWest
    },
    data: postBody
  };

  Vue.prototype.$api.home.getCameraListForPot(data).then(res => {
    let newArr = res.data.filter(item => {
      let lonLat = wgs84_to_gcj02(item.longitude, item.latitude);
      // item.longitude = lonLat[0];
      // item.latitude = lonLat[1];
      item.long = lonLat[0];
      item.lat = lonLat[1];

      return true;
    });

    mapInstance.pointsSetIn(newArr);
  });
}
export function searchData(newArr, searchValue) {
  let splitArr = searchValue.split(' ');
  if (splitArr.length === 2) {
    let cameraName = `${splitArr[0]}_${splitArr[1]}`;
    let arr = newArr.filter(item => {
      if (item.cameraName.includes(cameraName)) {
        return true;
      }
    });
    return arr;
  } else if (splitArr.length === 1) {
    let splitArr2 = splitArr[0].split('~');
    if (splitArr2.length === 1) {
      let arr = newArr.filter(item => item.khPile.includes(splitArr2[0]));
      if (arr.length <= 0) {
        let sa = getSubstring(splitArr2[0]);
        let bsa = sa - 10;
        let asa = sa + 10;
        arr = newArr.filter(item => {
          let kp = getSubstring(item.khPile);
          if (kp >= bsa && kp <= asa) {
            return true;
          }
        });
        return arr;
      } else {
        return arr;
      }
    } else if (splitArr2.length === 2) {
      let sa1 = getSubstring(splitArr2[0]);
      let sa2 = getSubstring(splitArr2[1]);
      return newArr.filter(item => {
        let kp = getSubstring(item.khPile);
        // console.error(sa1, sa2, kp);
        if (kp >= sa1 && kp <= sa2) {
          return true;
        }
      });
    }
  }
}

function getSubstring(sa1) {
  if (sa1.includes('K')) {
    sa1 = sa1.substring(sa1.indexOf('K') + 1);
    if (sa1.includes('+')) {
      sa1 = sa1.substring(0, sa1.indexOf('+'));
    }
    return Number(sa1);
  }
}

function getCountStatus(data) {
  let obj = {
    offline: 0,
    online: 0,
    error: 0
  };
  if (data && data.length > 0) {
    obj.online = data.filter(item => item.onlineStatus === '1').length;
    obj.offline = data.filter(item => item.onlineStatus === '2').length;
    obj.error = data.filter(item => item.onlineStatus === '0').length;
  }
  store.commit('setStatusCount', obj);
}

function setLocation(data, addressValue) {
  let mapInstance = store.getters.getMapInstance;
  /* let latValue = store.getters.getLatValue;
  let addressResult = '';
  let latResult = ''; */
  let lat = [];
  data.forEach(item => {
    if (addressValue.includes('K') && addressValue == item.khPile) {
      lat = [item.longitude, item.latitude];
    }
  });
  // return lat;
  mapInstance.getAddressByLat(lat);
  // console.error('----------------', addressValue, lat);
}

export function formatOrganizationTree(data) {
  let list = [];
  if (data[0]) {
    list = data[0].childList.map(item => {
      item.selected = false;
      return item;
    });
  }

  return trackTree(list);
}

export function formatAuthorityTree(data) {
  return trackTreeAuthority(data);
}
/* export function formatProvinceTree(data) {
  let list = data[0].regionRspList;

  return trackProvinceTree(list);
} */
export function formatProvinceTree(dataList) {
  let arr = [];
  dataList.forEach(item => {
    let obj = {
      label: item.regionName,
      value: item.regionCode
    };
    if (item.regionRspList && item.regionRspList.length > 0) {
      obj.children = formatProvinceTree(item.regionRspList);
    }
    arr.push(obj);
  });
  return arr;
}
/* function trackProvinceTree(list) {
  let newList = [];
  newList = list.map(item => {
    let obj = { label: item.regionName, value: item.regionCode };
    if (item.regionRspList && item.regionRspList.length > 0) {
      obj.children = trackProvinceTree(item.childList);
    }
    return obj;
  });
  return newList;
} */
function trackTree(list) {
  let newList = [];
  newList = list.map(item => {
    let obj = {
      label: item.organizationName,
      value: item.organizationId,
      ...item
    };
    if (item.childList && item.childList.length > 0) {
      obj.children = trackTree(item.childList);
    }
    return obj;
  });
  return newList;
}

function trackTreeAuthority(list) {
  let newList = [];
  newList = list.map(item => {
    let obj = {
      label: item.functionDesc,
      value: item.functionCode,
      parentCode: item.parentCode
    };
    if (item.childNode && item.childNode.length > 0) {
      obj.children = trackTreeAuthority(item.childNode);
    }
    return obj;
  });
  return newList;
}

export function getSeconds(date) {
  if (!date) {
    return;
  }
  let dataArr = date.split(':');
  let hour = Number(formatData(dataArr[0]));
  let min = Number(formatData(dataArr[1]));
  let sec = Number(formatData(dataArr[2]));
  let seconds = hour * 60 * 60 + min * 60 + sec;
  return seconds;
}

export function timeDiff(endTime, startTime) {
  return getSeconds(endTime) - getSeconds(startTime);
}

function formatData(date) {
  let a = Number(date[0]);
  let b = date[1];
  return a > 0 ? `${a}${b}` : b;
}

export const handleExportFile = function (data, name) {
  const blob = new Blob([data], {
    type: 'application/vnd.ms-excel;charset=utf-8'
  });
  const downloadElement = document.createElement('a');
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = name;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  window.URL.revokeObjectURL(href);
};
export const handleZipExportFile = function (data, name) {
  const blob = new Blob([data], {
    type: 'application/zip'
  });
  const downloadElement = document.createElement('a');
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = name;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  window.URL.revokeObjectURL(href);
};
export const handleVideo = function (data, name) {
  const blob = new Blob([data], {
    type: 'application/octet-stream'
  });
  const downloadElement = document.createElement('a');
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = name;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  window.URL.revokeObjectURL(href);
};
// 导出操作日志的表格
export const handleExportFiles = function (data, name) {
  const blob = new Blob([data], {
    type: 'application/vnd.ms-excel;charset=utf-8'
  });
  const downloadElement = document.createElement('a');
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = name;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  window.URL.revokeObjectURL(href);
};
// 导出行为审计的表格
export const handleExportActionFiles = function (data, name) {
  const blob = new Blob([data], {
    type: 'application/vnd.ms-excel;charset=utf-8'
  });
  const downloadElement = document.createElement('a');
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = name;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  window.URL.revokeObjectURL(href);
};

export const getParamValue = function (key) {
  let reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i');
  let value = '';
  let r = window.location.href
    .substr(window.location.href.indexOf('?') + 1)
    .match(reg);

  if (r != null) {
    value = decodeURIComponent(r[2]);
    return value;
  }
  return null;
};

export const setFlagArr = function () {
  let index = getParamValue('index');
  let flagArr = getStorage('flagArr');
  flagArr[index] = true;
  setStorage('flagArr', flagArr);
};

export const compileStr = function (code) {
  var c = String.fromCharCode(code.charCodeAt(0) + code.length);
  for (var i = 1; i < code.length; i++) {
    c += String.fromCharCode(code.charCodeAt(i) + code.charCodeAt(i - 1));
  }
  return escape(c);
};
//字符串进行解密
export const uncompileStr = function (code) {
  code = unescape(code);
  var c = String.fromCharCode(code.charCodeAt(0) - code.length);
  for (var i = 1; i < code.length; i++) {
    c += String.fromCharCode(code.charCodeAt(i) - c.charCodeAt(i - 1));
  }
  return c;
};

export function group(array, subGroupLength) {
  let index = 0;
  let newArray = [];
  while (index < array.length) {
    newArray.push(array.slice(index, (index += subGroupLength)));
  }
  return newArray;
}

// 时间戳转多少分钟之前
export function getDateDiff(dateTimeStamp) {
  // 时间字符串转时间戳
  let timestamp = new Date(dateTimeStamp).getTime();
  let minute = 1000 * 60;
  let hour = minute * 60;
  let day = hour * 24;
  // let halfamonth = day * 15;
  let month = day * 30;
  let year = day * 365;
  let now = new Date().getTime();
  let diffValue = now - timestamp;
  let result;
  if (diffValue < 0) {
    return;
  }
  let yearC = diffValue / year;
  let monthC = diffValue / month;
  let weekC = diffValue / (7 * day);
  let dayC = diffValue / day;
  let hourC = diffValue / hour;
  let minC = diffValue / minute;
  if (yearC >= 1) {
    result = '' + parseInt(yearC) + '年前';
  } else if (monthC >= 1) {
    result = '' + parseInt(monthC) + '月前';
  } else if (weekC >= 1) {
    result = '' + parseInt(weekC) + '周前';
  } else if (dayC >= 1) {
    result = '' + parseInt(dayC) + '天前';
  } else if (hourC >= 1) {
    result = '' + parseInt(hourC) + '小时前';
  } else if (minC >= 1) {
    result = '' + parseInt(minC) + '分钟前';
  } else result = '刚刚';
  return result;
}
// console.log(getDateDiff("2020-12-16 12:12:12"));

/**
 * web墨卡托(3857)转经纬度坐标(4326)
 * @param mercator
 * @returns {{x: number, y: number}}
 */
export function mercatorTolonlat(mercator) {
  var lonlat = {
    x: 0,
    y: 0
  };
  var x = (mercator.x / 20037508.34) * 180;
  var y = (mercator.y / 20037508.34) * 180;
  y =
    (180 / Math.PI) *
    (2 * Math.atan(Math.exp((y * Math.PI) / 180)) - Math.PI / 2);
  lonlat.x = x;
  lonlat.y = y;
  return lonlat;
}

export const getBlockList = item => {
  return {
    blockRoadName: item.xname,
    blockRoadLength: (item.jamlen / 1000).toFixed(2),
    pline: item.pline,
    noCamera: 1,
    check: false
  };
};
export const getAbormalList = item => {
  return {
    blockRoadName: item.organizationName,
    percent: item.abnormalPercentage,
    organizationId: item.organizationId
  };
};
export const padLen = (n, len) => {
  return String(n).padStart(len, '0');
};
export const getTimes = len => {
  let arr = [];
  arr = new Array(len).fill(0);
  arr = arr.map((item, index) => {
    return {
      value: padLen(index + 1, 2),
      text: padLen(index + 1, 2)
    };
  });
  return arr;
};
export const getTimesFitler = (len, filterTime) => {
  let arr = [];
  arr = new Array(len).fill(0);
  arr = arr.map((item, index) => {
    return {
      value: padLen(index + 1, 2),
      text: padLen(index + 1, 2),
      disabled: getDisabled(index, filterTime)
    };
  });
  return arr;
};
const getDisabled = (index, filterTime) => {
  let reg = /^(0+)/g;
  let strTime = filterTime.replace(reg, '');
  if (+strTime > index) {
    return true;
  } else {
    return false;
  }
};
export const saveAs = (function (view) {
  'use strict';
  // IE <10 is explicitly unsupported
  if (
    typeof view === 'undefined' ||
    (typeof navigator !== 'undefined' &&
      /MSIE [1-9]\./.test(navigator.userAgent))
  ) {
    return;
  }
  var doc = view.document,
    // only get URL when necessary in case Blob.js hasn't overridden it yet
    get_URL = function () {
      return view.URL || view.webkitURL || view;
    },
    save_link = doc.createElementNS('http://www.w3.org/1999/xhtml', 'a'),
    can_use_save_link = 'download' in save_link,
    click = function (node) {
      var event = new MouseEvent('click');
      node.dispatchEvent(event);
    },
    is_safari = /constructor/i.test(view.HTMLElement) || view.safari,
    is_chrome_ios = /CriOS\/[\d]+/.test(navigator.userAgent),
    throw_outside = function (ex) {
      (view.setImmediate || view.setTimeout)(function () {
        throw ex;
      }, 0);
    },
    force_saveable_type = 'application/octet-stream',
    // the Blob API is fundamentally broken as there is no "downloadfinished" event to subscribe to
    arbitrary_revoke_timeout = 1000 * 40, // in ms
    revoke = function (file) {
      var revoker = function () {
        if (typeof file === 'string') {
          // file is an object URL
          get_URL().revokeObjectURL(file);
          console.log(file);
        } else {
          // file is a File
          file.remove();
        }
      };
      setTimeout(revoker, arbitrary_revoke_timeout);
    },
    dispatch = function (filesaver, event_types, event) {
      event_types = [].concat(event_types);
      var i = event_types.length;
      while (i--) {
        var listener = filesaver['on' + event_types[i]];
        if (typeof listener === 'function') {
          try {
            listener.call(filesaver, event || filesaver);
          } catch (ex) {
            throw_outside(ex);
          }
        }
      }
    },
    auto_bom = function (blob) {
      // prepend BOM for UTF-8 XML and text/* types (including HTML)
      // note: your browser will automatically convert UTF-16 U+FEFF to EF BB BF
      if (
        /^\s*(?:text\/\S*|application\/xml|\S*\/\S*\+xml)\s*;.*charset\s*=\s*utf-8/i.test(
          blob.type
        )
      ) {
        return new Blob([String.fromCharCode(0xfeff), blob], {
          type: blob.type
        });
      }
      return blob;
    },
    FileSaver = function (blob, name, no_auto_bom) {
      if (!no_auto_bom) {
        blob = auto_bom(blob);
      }
      // First try a.download, then web filesystem, then object URLs
      var filesaver = this,
        type = blob.type,
        force = type === force_saveable_type,
        object_url,
        dispatch_all = function () {
          dispatch(filesaver, 'writestart progress write writeend'.split(' '));
        },
        // on any filesys errors revert to saving with object URLs
        fs_error = function () {
          if ((is_chrome_ios || (force && is_safari)) && view.FileReader) {
            // Safari doesn't allow downloading of blob urls
            var reader = new FileReader();
            reader.onloadend = function () {
              var url = is_chrome_ios ?
                reader.result :
                reader.result.replace(
                  /^data:[^;]*;/,
                  'data:attachment/file;'
                );
              var popup = view.open(url, '_blank');
              if (!popup) view.location.href = url;
              url = undefined; // release reference before dispatching
              filesaver.readyState = filesaver.DONE;
              dispatch_all();
            };
            reader.readAsDataURL(blob);
            filesaver.readyState = filesaver.INIT;
            return;
          }
          // don't create more object URLs than needed
          if (!object_url) {
            object_url = get_URL().createObjectURL(blob);
          }
          if (force) {
            view.location.href = object_url;
          } else {
            var opened = view.open(object_url, '_blank');
            if (!opened) {
              // Apple does not allow window.open, see https://developer.apple.com/library/safari/documentation/Tools/Conceptual/SafariExtensionGuide/WorkingwithWindowsandTabs/WorkingwithWindowsandTabs.html
              view.location.href = object_url;
            }
          }
          filesaver.readyState = filesaver.DONE;
          dispatch_all();
          revoke(object_url);
        };
      filesaver.readyState = filesaver.INIT;

      if (can_use_save_link) {
        object_url = get_URL().createObjectURL(blob);
        setTimeout(function () {
          save_link.href = object_url;
          save_link.download = name;
          click(save_link);
          dispatch_all();
          revoke(object_url);
          filesaver.readyState = filesaver.DONE;
        });
        return;
      }

      fs_error();
    },
    FS_proto = FileSaver.prototype,
    saveAs = function (blob, name, no_auto_bom) {
      return new FileSaver(blob, name || blob.name || 'download', no_auto_bom);
    };
  // IE 10+ (native saveAs)
  if (typeof navigator !== 'undefined' && navigator.msSaveOrOpenBlob) {
    return function (blob, name, no_auto_bom) {
      name = name || blob.name || 'download';

      if (!no_auto_bom) {
        blob = auto_bom(blob);
      }
      return navigator.msSaveOrOpenBlob(blob, name);
    };
  }

  FS_proto.abort = function () {};
  FS_proto.readyState = FS_proto.INIT = 0;
  FS_proto.WRITING = 1;
  FS_proto.DONE = 2;

  FS_proto.error = FS_proto.onwritestart = FS_proto.onprogress = FS_proto.onwrite = FS_proto.onabort = FS_proto.onerror = FS_proto.onwriteend = null;
  return saveAs;
})(
  (typeof self !== 'undefined' && self) ||
  (typeof window !== 'undefined' && window) ||
  this.content
);

export const toCode = str => {
  //加密字符串
  //定义密钥，36个字母和数字
  let key = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let l = key.length; //获取密钥的长度
  let a = key.split(''); //把密钥字符串转换为字符数组
  let s = '',
    b,
    b1,
    b2,
    b3; //定义临时变量
  for (let i = 0; i < str.length; i++) {
    //遍历字符串
    b = str.charCodeAt(i); //逐个提取每个字符，并获取Unicode编码值
    b1 = b % l; //求Unicode编码值得余数
    b = (b - b1) / l; //求最大倍数
    b2 = b % l; //求最大倍数的于是
    b = (b - b2) / l; //求最大倍数
    b3 = b % l; //求最大倍数的余数
    s += a[b3] + a[b2] + a[b1]; //根据余数值映射到密钥中对应下标位置的字符
  }
  return s; //返回这些映射的字符
};
export const fromCode = str => {
  //定义密钥，36个字母和数字
  let key = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let l = key.length; //获取密钥的长度
  let b,
    b1,
    b2,
    b3,
    d = 0,
    s; //定义临时变量
  s = new Array(Math.floor(str.length / 3)); //计算加密字符串包含的字符数，并定义数组
  b = s.length; //获取数组的长度
  for (let i = 0; i < b; i++) {
    //以数组的长度循环次数，遍历加密字符串
    b1 = key.indexOf(str.charAt(d)); //截取周期内第一个字符串，计算在密钥中的下标值
    d++;
    b2 = key.indexOf(str.charAt(d)); //截取周期内第二个字符串，计算在密钥中的下标值
    d++;
    b3 = key.indexOf(str.charAt(d)); //截取周期内第三个字符串，计算在密钥中的下标值
    d++;
    s[i] = b1 * l * l + b2 * l + b3; //利用下标值，反推被加密字符的Unicode编码值
  }
  b = eval('String.fromCharCode(' + s.join(',') + ')'); //用fromCharCode()算出字符串
  return b; //返回被解密的字符串
};
/*
 * 根据文件名的尾缀 返回文件类型
 * @param {any} fileName 文件名
 * dzl
 * 2020年5月9日
 */
export const getFileType = fileName => {
  // 后缀获取
  let suffix = '';
  // 获取类型结果
  let result = '';
  try {
    const flieArr = fileName.split('.');
    suffix = flieArr[flieArr.length - 1];
  } catch (err) {
    suffix = '';
  }
  // fileName无后缀返回 false
  if (!suffix) {
    return false;
  }
  suffix = suffix.toLocaleLowerCase();
  // 图片格式
  const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
  // 进行图片匹配
  result = imglist.find(item => item === suffix);
  if (result) {
    return 'image';
  }
  // 匹配txt
  const txtlist = ['txt'];
  result = txtlist.find(item => item === suffix);
  if (result) {
    return 'txt';
  }
  // 匹配 excel
  const excelist = ['xls', 'xlsx'];
  result = excelist.find(item => item === suffix);
  if (result) {
    return 'excel';
  }
  // 匹配 word
  const wordlist = ['doc', 'docx'];
  result = wordlist.find(item => item === suffix);
  if (result) {
    return 'word';
  }
  // 匹配 pdf
  const pdflist = ['pdf'];
  result = pdflist.find(item => item === suffix);
  if (result) {
    return 'pdf';
  }
  // 匹配 ppt
  const pptlist = ['ppt', 'pptx'];
  result = pptlist.find(item => item === suffix);
  if (result) {
    return 'ppt';
  }
  // 匹配 视频
  const videolist = [
    'mp4',
    'm2v',
    'mkv',
    'rmvb',
    'wmv',
    'avi',
    'flv',
    'mov',
    'm4v'
  ];
  result = videolist.find(item => item === suffix);
  if (result) {
    return 'video';
  }
  // 匹配 音频
  const radiolist = ['mp3', 'wav', 'wmv'];
  result = radiolist.find(item => item === suffix);
  if (result) {
    return 'radio';
  }
  // 其他 文件类型
  return 'other';
};

export const generateTimeList = (delta) => {
  let f = n => `${n}`.padStart(2, '0')
  let result = [],
    date = new Date()
  date.setHours(0)
  date.setMinutes(0)
  let day = date.getDate()
  while (date.getDate() === day) {
    let h = date.getHours()
    let m = date.getMinutes()
    // console.log(h, m)
    result.push(`${f(h)}:${f(m)}`)
    date.setMinutes(m + delta)
  }
  /*   result.pop(); */
  return result.map((item, index) => ({
    id: index,
    value: item,
    timestamp: getTimeStamp(item),
    disabled: false
  }))
}
/**
 *  获取小时时间的时间戳
 * @param {*} n 
 * @returns 
 */
export function getTimeStamp(timeString) {
  const [hour, minitue] = String(timeString).split(':');
  const getNumberFromString = (tStr) => {
    const strArr = String(tStr);
    return strArr[0] === '0' ? strArr.slice(1) : strArr;
  }
  const numH = getNumberFromString(hour) * 60 * 60 * 1000;
  const numM = getNumberFromString(minitue) * 60 * 1000;
  return numH + numM;
}
/**
 * 声称包括当前日期及前n天日期
 * @param {*} target n
 * @returns array
 */
export function generateDate(n = 6) {
  let myDate = new Date();
  myDate.setDate(myDate.getDate() - n);
  let dateArray = [];
  let dateTemp = new Date();
  let flag = 1;
  for (let i = 0; i <= n; i++) {
    dateTemp = padd((myDate.getMonth() + 1)) + '-' + padd(myDate.getDate());
    dateArray.push(dateTemp);
    myDate.setDate(myDate.getDate() + flag);
  }
  return dateArray.map((item, index) => ({
    id: index,
    value: item,
    check: false,
    text: formatDateString(item),
  }))
}
/**
 * 补0
 * @param {*} n 
 */
export function padd(n) {
  const num = Number(n);
  return num < 10 ? `0${num}` : num;
}
/**
 * 格式化
 * @param {*} dateString 
 * @returns 
 */
function formatDateString(dateString) {
  return dateString.replace('-', '月') + '日';
}
/*
 *  深拷贝
 *
 **/
export function deepClone(target) {
  let result;
  if (typeof target === 'object') {
    if (Array.isArray(target)) {
      result = [];
      for (let i in target) {
        result.push(deepClone(target[i]))
      }
    } else if (target === null) {
      result = null;
    } else if (target.constructor === RegExp) {
      result = target;
    } else {
      result = {};
      for (let i in target) {
        result[i] = deepClone(target[i]);
      }
    }
  } else {
    result = target;
  }
  return result;
}