/**
 * Ai中心event-dict模块接口列表
 */
import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块
/**
 * getEventDict()请求事件的函数
 */

const aiCenterS = {
  getEventDict() {
    return axios.get(`${base.sq}/aiEventDict`).then(data => {
      return data;
    });
  },
  getAlarms() {
    return axios.get(`${base.sq}/monitor/eventCq/list`).then(data => {
      return data;
    });
  },
  getEvent(data) {
    return axios
      .get(`${base.sq}/monitor/eventAlarm/getEventRanking/${data}`)
      .then(data => {
        return data;
      });
  },
  // 获取操作日志列表
  getLog(data) {
    return axios
      .get(`${base.sq}/monitor/camera-statistics/logList?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  //导出操作日志列表
  exportAnalyseInfo(data) {
    return axios
      .get(
        `${base.sq}/monitor/camera-statistics/logListExport?${qs.stringify(
          data
        )}`,
        {
          responseType: 'arraybuffer'
        }
      )
      .then(({ data }) => {
        return data;
      });
  },
  //导出行为审计列表
  exportActionInfo(data) {
    return axios
      .get(`${base.sq}/data/action-log/export?${qs.stringify(data)}`, {
        responseType: 'arraybuffer'
      })
      .then(data => {
        return data;
      });
  },
  //导出摄像机列表
  exportAnalyseInfos() {
    return axios
      .get(`${base.sq}/device/cameras/download/excel-detail/all`, {
        responseType: 'arraybuffer'
      })
      .then(data => {
        return data;
      });
  },
  // 获取行为日志列表
  getBehavior(data) {
    return axios
      .get(`${base.sq}/data/action-log/list/?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },

  // 撤销审计行为操作
  backwardBehaviorById: id =>
    axios.put(`${base.sq}/data/action-log/action-revoke/${id}`),

  // 上传xls文件
  uploadXlsFile: (url, fileFormData) =>
    axios.post(`${base.sq}${url}`, fileFormData, {
      timeout: 300000
    }),

  // 获取行为审计备份列表
  getActionBackupList: params =>
    axios.get(
      `${base.sq}/data/action-log/backup-list?${qs.stringify(params)}`,
      {
        timeout: 300000
      }
    ),

  // 对行为审计进行取消备份
  cancelBackup: params =>
    axios.delete(`${base.sq}/data/action-log/action-cancel-backup`, params),

  // 对行为审计进行备份
  backupAction: params =>
    axios.post(
      `${base.sq}/data/action-log/action-backup?backTime=${params.backTime}`,
      {},
      {
        timeout: 300000
      }
    ),

  // 对行为审计进行恢复
  restoreAction: backupBatchId =>
    axios.put(`${base.sq}/data/action-log/action-restore/${backupBatchId}`),

  // 查询角色列表
  getRoleList(data) {
    return axios
      .get(`${base.sq}/user/role/list?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  //查看绑定权限
  getChoseListByAllRole(params) {
    return axios
      .get(`${base.sq}/user/role/chooseFunc?${qs.stringify(params)}`)
      .then(({ data }) => {
        return data;
      });
  },
  //新增角色
  getEventAlarmStatistics(data) {
    return axios
      .post(`${base.sq}/monitor/eventAlarm/getEventAlarmStatistics`, data)
      .then(({ data }) => {
        return data;
      });
  }
};

export default aiCenterS;
