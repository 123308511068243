var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-wrap  bd-s-b bd-s-l bd-s-r bd-s-t full-height",attrs:{"id":""}},_vm._l((_vm.size),function(vo,key){return _c('drop',{key:key,staticClass:"screen-camera-item",class:[
      ("screen-item bd-r  width-" + _vm.size + " width-" + _vm.screenMode),
      { 'bd-b': _vm.isBorder(key) }
    ],on:{"drop":function($event){return _vm.dropScreenCamera($event, key, true)}}},[_c('drag',{staticClass:"drag",attrs:{"transfer-data":_vm.cameraList[key]},on:{"dragend":function($event){return _vm.dragendScreenCamera($event, key)}}},[(_vm.cameraList[key])?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showClose),expression:"showClose"}],staticClass:"screen-camera-delete",attrs:{"title":"关闭"}},[_c('a-icon',{attrs:{"type":"close"},on:{"click":function($event){return _vm.screenCameraClose(key)}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showClose && _vm.cameraList[key].cameraName),expression:"showClose && cameraList[key].cameraName"}],staticClass:"screen-camera-title"},[_c('span',{attrs:{"title":_vm.cameraList[key].cameraName}},[_vm._v(_vm._s(_vm.cameraList[key].cameraName))])]),_c('div',{staticClass:"screen-camera-image"},[_c('flv-player',{ref:'flvPlay' + key,refInFor:true,attrs:{"video-type":"flv","control":false},nativeOn:{"dblclick":function($event){$event.stopPropagation();return _vm.fullScreenDom('flvPlay' + key)}}})],1),(
            _vm.cameraList[key].definitions &&
              _vm.cameraList[key].definitions.length &&
              _vm.cameraList[key].videoType !== null
          )?_c('div',{staticClass:"video-type"},[_c('span',{staticClass:"cursor",staticStyle:{"color":"#ccc"},on:{"click":_vm.openEditFault}},[_vm._v(_vm._s(_vm.cameraList[key].detailsInfo))]),_c('i',{staticClass:"pointer icon-a icon-fault m-r-sm m-l-sm",on:{"click":function($event){return _vm.toggleFaultReported(_vm.cameraList[key].cameraId)}}},[_vm._v("异常填报")]),_c('toggle-resolution',{attrs:{"className":"bg-mask","resolutionValue":_vm.cameraList[key].videoType,"definitions":_vm.cameraList[key].definitions},on:{"toggleResolutionHandle":function (value) { return _vm.handleVideoTypeChange(value, key); }}})],1):_vm._e()]:[_c('div',{staticClass:"screen-camera-title"},[_c('span')]),_c('div',{staticClass:"screen-camera-image"},[_c('loading',{attrs:{"loading-status":"empty","message":"没有添加视频"}})],1)],_c('fault-dialog',{attrs:{"visible":_vm.faultVisible,"cameraId":_vm.cameraId,"isLucency":false,"cameraData":_vm.cameraData},on:{"closeFault":_vm.closeFaultHandle,"faultClick":_vm.videoClick}}),_c('edit-fault-dialog',{attrs:{"visible":_vm.editFaultVisible,"cameraId":_vm.cameraId},on:{"closeEditFault":_vm.closeEditFaultHandle,"faultClick":_vm.videoClick}})],2)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }