import Vue from 'vue';
import { getStorage, setStorage, clearStorage } from '@/tools';
import router from '@/router/router';
// import Socket from '@/components/js/socket';
import { createSocket, closeSoceket } from '@/tools/websocket';
import jsencrypt from '@/tools/jsencrypt';
import { toCode } from '@/tools';
// import md5 from 'js-md5';

let vm = new Vue();
const state = {
  userInfo: getStorage('appmonitor', {}),
  userPermissions: null,
  isLogin: false,
  checked: true,
  pubKey: null,
  SECRET_KEY:
    '*……%F%……&FCVW……&#V*&CIE……B&*CF……VFdF*&V%RV*rd*&vrvfdvfsaf&……t&*FfvsfdvfbFFFGFfggug7866t%￥&￥&%*……',
  logoInfo:{}
};
function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
const actions = {
  // 登录前的获取公钥(pubKey)
  getPubKey(store) {
    return Vue.prototype.$api.login.getPubKey().then((res) => {
      store.state.pubKey = res.data?.publicKey;
      jsencrypt.setPublicKey(res.data?.publicKey);
    });
  },
  async userModifyEncryptPass(store,params) {
    store.state.pubKey === null && (await store.dispatch('getPubKey'));
    let objParams = {
      oldPassword: jsencrypt.encrypt(params.oldPassword),
      password: jsencrypt.encrypt(params.password),
      userId: params.userId,
    }
    return  Vue.prototype.$api.home
    .modifyEncryptPass(objParams)
    .then(( data ) => {
      if (data && data.code !== 200) {
          return Promise.reject(data.message);
      }
      return data;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
  },
  async userLoginDepart(store, params) {
    store.state.pubKey === null && (await store.dispatch('getPubKey'));
    // console.error(jsencrypt.decrypt(jsencrypt.encrypt(params.loginPwd)));
    //console.info("userLogin",typeof api.Login.requestLoginFun);
    let objParams = {
      loginName: jsencrypt.encrypt(params.loginName),
      loginPwd: jsencrypt.encrypt(params.loginPwd),
      verificationCode: params.verificationCode,
      uuid: params.uuid,
      monitorLogin:'1'
    }
    if(!Vue.prototype.$g.isDepart){
      delete objParams['verificationCode'];
      delete objParams['uuid'];
      delete objParams['monitorLogin'];
    }
    return Vue.prototype.$api.login
      .requestLoginEncrypt(objParams)
      .then(( data ) => {
        if (data && data.code !== 200) {
          if (data.code == 4008) {
            return router.push({
              path: `/reset-pass?loginame=${encodeURIComponent(
                params.loginName
              )}&loginpass=${toCode(params.loginPwd)}`,
            });
          } else {
            // vm.$message.error('登录失败! [' + data.code + ']: ' + data.message);
            return Promise.reject(data.message);
          }
          // return Promise.reject();fixsxy
        }
        //14天修改密码提示
        if (data.data.changePassDays > 90) {
          vm.$message.warning(
            '您的密码已超过90天未更换，为了您的账号安全，请尽快修改密码。'
          );
        }
        /*  vm.$message.error('登录失败! [' + data.code + ']: ' + data.message);
          return Promise.reject(); */

        /* console.error('haha', data); */
        if (store.state.checked) {
          data.data.userInfo.loginName = params.loginName;
          setStorage('AM_CK_LG', {
            userName: params.loginName,
            userPass: params.loginPwd,
          });
        } else {
          clearStorage('AM_CK_LG');
        }
        data.pass = params.loginPwd;
        sleep(100).then(() => {
          store.dispatch('dologin', data);
          let userId = data.data.userInfo.userId;
          /* let socket = new Socket(userId);
        socket.openSocket(); */
          console.log(`${window.g.webSocketUrl}${userId}`);
          createSocket(`${window.g.webSocketUrl}${userId}`);
        });

        return data;
      })
      .catch((e) => {
        console.info('userLogin', e);
        return Promise.reject(e);
      });
  },
  userLogin(store, params) {
    //console.info("userLogin",typeof api.Login.requestLoginFun);
    return Vue.prototype.$api.login
      .login(params)
      .then(({ data }) => {
        if (data && data.code !== 200) {
          if (data.code == 4008) {
            return router.push({
              path: `/reset-pass?loginame=${encodeURIComponent(
                params.loginName
              )}&loginpass=${toCode(params.loginPwd)}`,
            });
          } else {
            // vm.$message.error('登录失败! [' + data.code + ']: ' + data.message);
            return Promise.reject(data);
          }
          // return Promise.reject();
        }
        //14天修改密码提示
        if (data.data.changePassDays > 14) {
          vm.$message.warning(
            '您的密码已超过14天未更换，为了您的账号安全，请尽快修改密码。'
          );
        }
        /*  vm.$message.error('登录失败! [' + data.code + ']: ' + data.message);
          return Promise.reject(); */

        /* console.error('haha', data); */
        if (store.state.checked) {
          data.data.userInfo.loginName = params.loginName;
          setStorage('AM_CK_LG', {
            userName: params.loginName,
            userPass: params.loginPwd,
          });
        } else {
          clearStorage('AM_CK_LG');
        }
        data.pass = params.loginPwd;
        sleep(100).then(() => {
          store.dispatch('dologin', data);
          let userId = data.data.userInfo.userId;
          /* let socket = new Socket(userId);
        socket.openSocket(); */
          console.log(`${window.g.webSocketUrl}${userId}`);
          createSocket(`${window.g.webSocketUrl}${userId}`);
        });

        return data;
      })
      .catch((e) => {
        console.info('userLogin', e);
        return Promise.reject(e);
      });
  },
  // eslint-disable-next-line no-unused-vars
  getChoseListByAllRoleAction({ state }, obj) {
    return Vue.prototype.$api.login.getChoseListByAllRole(obj).then((data) => {
      if (data?.code !== 200) {
        vm.$message.error({
          content:
            '获取用户权限列表失败, 可能导致部分功能无权使用,建议退出重新登录！！！\n[' +
            data.code +
            ']: ' +
            data.message,
          duration: 3,
        });
      } else {
        vm.$message.success('登录成功');
        state.userPermissions = data.data.full
          .concat(data.data.half)
          .reduce((acc, e) => {
            acc[e] = true;
            return acc;
          }, {});
      }

      // if (rlist && rlist.length) {
      //   let rs = rlist.join(','),
      //     locals = md5(rs + store.state.SECRET_KEY);
      //   localStorage.setItem(
      //     'PM_CK_BN',
      //     JSON.stringify({ data: rs, sign: locals })
      //   );
      // }
    });
  },
  dologin(store, data) {
    let userInfo = data.data.userInfo,
      info = {};

    info['isFrist'] = data.data.isFrist;
    info['logoUrl'] = data.data.logoUrl;
    info['webTitle'] = data.data.name;
    info['account'] = userInfo.loginName;
    info['Authorization'] = data.data.token;
    info['refreshToken'] = data.data.refreshToken;
    info['checkPass'] = userInfo.checkPass;
    info['userName'] = userInfo.userName;
    info['loginName'] = userInfo.loginName;
    info['userType'] = userInfo.userType;
    info['organizationId'] = userInfo.organizationId;
    info['regionName'] = userInfo.regionName;
    info['regionCode'] = parseInt(userInfo.regionCode);
    info['organizationName'] = userInfo.organizationName;
    info['organizationType'] = userInfo.organizationType;
    info['userId'] = userInfo.userId;
    info['role'] = userInfo.role;
    info['source'] = userInfo.source || 1;
    info['loginTime'] = ''; //Utils.date('Y-m-d H:i:s');
    info['pass'] = data.pass;
    //存储登录信息
    setStorage('appmonitor', info);

    //免登录
    let sysInfo = {
      logoUrl: data.data.logoUrl,
      platformName: data.data.name,
    };
    setStorage('cloudsysinfo', sysInfo);
    setStorage('cloudplatform', info);

    //缓存用户
    store.commit('setUserInfo', info);
    store.commit('setIsLogin', true);
    //设置投屏开关
    setStorage('flagArr', new Array(4).fill(true));
    setStorage('cameraList', new Array(4).fill([]));

    store
      .dispatch('getChoseListByAllRoleAction', {
        roleCode: userInfo.role,
      })
      .then(() => {
        if(!Vue.prototype.hasPermission(1092)){
          window.open(
            `${window.g.jumbUrl}?username=${window.btoa(
              userInfo.loginName
            )}&password=${window.btoa(data.pass)}`
          );
          return;
        }
        router.push({ path: '/' });
      });
  },
  /**
   * 退出登录
   * @param store
   */
  logout(store, isModifyPass) {
    vm.$message.destroy();
    //AM_CK_BN
    clearStorage('appmonitor');
    //清空缓存
    clearStorage('flagArr');
    clearStorage('cameraList');
    clearStorage('patrolCamera');
    clearStorage('groupCamera');
    clearStorage('collectCamera')
    if (isModifyPass) {
      clearStorage('AM_CK_LG');
    }
    // clearStorage('AM_CK_BN');
    // clearStorage('AM_CK_MU');
    clearStorage('PM_CK_BN');
    clearStorage('historyURL');
    store.commit('setIsLogin', false);
    //关闭webSocket
    closeSoceket();
    router.push({ path: '/login' });
  },
  /**
   * 得到登录信息
   */
  getLogoInfo(store) {
    return Vue.prototype.$api.aiCenterY.getlogoInfo().then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '获取logo信息失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      store.commit('setLogoInfo',data.data)
      // console.error('haha', list);
      return data;
    });
  }
};
const mutations = {
  setIsLogin(state, val) {
    state.isLogin = val;
  },
  setCheck(state, val) {
    state.checked = val;
  },
  setUserInfo(state, val) {
    state.userInfo = val;
  },
  setLogoInfo(state,value){
    state.logoInfo = value;
  }
};
export default {
  state,
  mutations,
  actions,
};
