/**
 * article模块接口列表
 */

import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块
import Vue from 'vue';
import Base64 from '@/tools/base64';

const resource = {
  getAccessInfo(data) {
    if (Vue.prototype.$g.isDepart) {
      return axios
        .get(
          `${base.sq}/monitor/organizationOnline/runInfo?${qs.stringify(data)}`
        )
        .then(({ data }) => {
          return data;
        });
    } else {
      return axios
        .get(`${base.sq}/sta/online/runInfo?${qs.stringify(data)}`)
        .then(({ data }) => {
          return data;
        });
    }
  },
  getExceptionList(data) {
    return axios
      .get(`${base.sq}/sta/error/errorInfo?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 数据统计接口
   */
  getNetworkStatistics(data) {
    if (Vue.prototype.$g.isDepart) {
      return axios
        .post(
          `${base.sq}/monitor/camera-statistics/getCameraNetWorkByTraffic`,
          data
        )
        .then(({ data }) => {
          return data;
        });
    } else {
      return axios
        .get(
          `${
            base.sq
          }/monitor/camera-statistics/network-statistics?${qs.stringify(data)}`
        )
        .then(({ data }) => {
          return data;
        });
    }
  },
  /**
   * 获取数据统计详情接口
   */
  getNetworkStatisticsDetail(data) {
    return axios
      .get(
        `${
          base.sq
        }/monitor/camera-statistics/network-statistics-detail?${qs.stringify(
          data
        )}`
      )
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 获取截图数量
   */
  getScreenShotCount(data) {
    return axios
      .get(
        `${base.sq}/monitor/screenShot/getScreenShotCount?${qs.stringify(data)}`
      )
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 获取截图信息
   */
  getScreenShotInfo(data) {
    return axios
      .get(
        `${base.sq}/monitor/screenShot/getCameraScreenShot?${qs.stringify(
          data
        )}`
      )
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 获取省级截图信息
   */
  getProvinceScreenShotInfo(data) {
    return axios
      .get(
        `${base.sq}/monitor/screenShot/province-snapshot-list?${qs.stringify(
          data
        )}`
      )
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 批量删除
   */
  deleteScreenShout(data) {
    return axios
      .delete(`${base.sq}/monitor/screenShot/deleteScreenShout`, {
        headers: {
          operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
        },
        data: data.ids
      })
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 获取子级摄像机组数据
   */
  getCameraGroupInfoById(id) {
    return axios
      .get(`${base.sq}/monitor/cameraGroup/getCameraGroupInfo/${id}`)
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 导出联网数据
   */
  exportNetworkStatistics(data) {
    return axios
      .get(
        `${
          base.sq
        }/monitor/camera-statistics/network-statistics-export?${qs.stringify(
          data
        )}`,
        {
          responseType: 'arraybuffer'
        }
      )
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 按单位
   *
   **/
  getByCompany(data) {
    return axios
      .post(`${base.sq}/monitor/cameraOrgTree/CameraTreeByOrg`, data)
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 按路线
   */
  getByRoad(data) {
    return axios
      .post(`${base.sq}/monitor/camera-statistics/home/`, data)
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 按省份
   */
  getByProvince(data) {
    return axios
      .post(`${base.sq}/monitor/camera-statistics/home/province`, data)
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 按区域
   */
  getCameraNetWorkByRegion(data) {
    return axios
      .post(
        `${base.sq}/monitor/camera-statistics/getCameraNetWorkByRegion/${data.regionCodeType}`,
        data.params
      )
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 获取部级子单位统计
   */
  getDepartAccessByRegion(params) {
    return axios
      .post(
        `${base.sq}/monitor/organizationOnline/runRateByOrg/${params.dimensionType}`,
        params.params
      )
      .then(({ data }) => {
        return data;
      });
  },
  //获取联网七天联网数据
  getCameraNetWorkInfo(data) {
    return axios
      .post(
        `${base.sq}/monitor/cameraNetWork/getCameraNetWorkInfo?${qs.stringify(
          data
        )}`
      )
      .then(({ data }) => {
        return data;
      });
  },
  //导出建表数据
  exportCameraNetWork(data) {
    return axios
      .get(
        `${base.sq}/monitor/cameraNetWork/exportCameraNetWork?${qs.stringify(
          data
        )}`,
        {
          responseType: 'arraybuffer'
        }
      )
      .then(({ data }) => {
        return data;
      });
  },
  //获得实时统计
  getNowStatistics() {
    return axios
      .get(`${base.sq}/monitor/camera-statistics/getNowStatistics`)
      .then(({ data }) => {
        return data;
      });
  },
  //实时导出
  exportNowStatistics() {
    /* let instructionsDel = {
      module: '联网状况',
      page: '',
      feature: '数据导出',
      description: '数据导出:接入率列表'
    }; */ //需要加
    return axios
      .get(`${base.sq}/monitor/camera-statistics/exportNowStatistics`, {
        responseType: 'arraybuffer'

        /*  headers: {
            operateNote: Base64.encode(JSON.stringify(instructionsDel))
          } */
      })
      .then(({ data }) => {
        return data;
      });
  },
  //摄像机接入量
  getAccessNumber() {
    return axios
      .get(`${base.sq}/monitor/camera-statistics/getAccessNumber/yunnan`)
      .then(({ data }) => {
        return data;
      });
  },

  // 获得摄像机统计组织单位
  getCameraOrgStatistic: () =>
    axios.post(`${base.sq}/monitor/camera-statistics/cameraOrgStatistic`),

  // 导出摄像机统计组织单位
  exportCameraOrgStatistic: (cameraStatus) =>
    axios.get(
      `${base.sq}/monitor/camera-statistics/cameraOrgStatistic/export/${cameraStatus}`,
      {
        responseType: 'arraybuffer'
      }
    ),
  getNowStatisticsAgain() {
    return axios
      .get(`${base.sq}/monitor/camera-statistics/getNowStatistics-again`, {
        timeout: 420000
      })
      .then(({ data }) => {
        return data;
      });
  },
  //获取某单位在线离线摄像机
  getOrgCameraData(params) {
    return axios.get(`${base.sq}/monitor/camera-statistics/getOrgCamera?${qs.stringify(params)}`)
          .then(({data}) => {
            return data;
    });
  },
  //获取摄像机柱状图运行情况
  getCameraHistogram() {
    return axios.get(`${base.sq}/monitor/camera-statistics/getCameraHistogram`)
          .then(({data}) => {
            return data;
    });
  },
  //导出摄像机
  exportOrgCamera(params) {
    return axios.get(
      `${base.sq}/monitor/camera-statistics/exportOrgCamera?${qs.stringify(params)}`,
      {
        responseType: 'arraybuffer'
      }
    );
  }

};

export default resource;
