<template>
  <div
    class="flex flex-wrap  bd-s-b bd-s-l bd-s-r bd-s-t full-height"
    id=""
  >
    <drop
      class="screen-camera-item"
      v-for="(vo, key) in size"
      :key="key"
      :class="[
        `screen-item bd-r  width-${size} width-${screenMode}`,
        { 'bd-b': isBorder(key) }
      ]"
      @drop="dropScreenCamera($event, key, true)"
    >
      <drag
        class="drag"
        :transfer-data="cameraList[key]"
        @dragend="dragendScreenCamera($event, key)"
      >
        <template v-if="cameraList[key]">
          <div
            title="关闭"
            class="screen-camera-delete"
            v-show="showClose"
          >
            <a-icon
              type="close"
              @click="screenCameraClose(key)"
            />
          </div>
          <div
            class="screen-camera-title"
            v-show="showClose && cameraList[key].cameraName"
          >
            <span :title="cameraList[key].cameraName">{{
              cameraList[key].cameraName
            }}</span>
          </div>
          <div class="screen-camera-image">
            <flv-player
              :ref="'flvPlay' + key"
              video-type="flv"
              :control="false"
              @dblclick.native.stop="
                fullScreenDom('flvPlay' + key)
              "
            ></flv-player>

          </div>
          <div
            v-if="
              cameraList[key].definitions &&
                cameraList[key].definitions.length &&
                cameraList[key].videoType !== null
            "
            class="video-type"
          >
          <span @click="openEditFault" class="cursor" style="color:#ccc" >{{cameraList[key].detailsInfo}}</span>
          <!-- <span @click="openEditFault" class="cursor" style="color:#ccc" >{{cameraList[key]}}</span> -->
          <i
              class="pointer icon-a icon-fault m-r-sm m-l-sm"
              @click="toggleFaultReported(cameraList[key].cameraId)"
            >异常填报</i>
            <toggle-resolution
              className="bg-mask"
              :resolutionValue="cameraList[key].videoType"
              :definitions="cameraList[key].definitions"
              @toggleResolutionHandle="
                (value) => handleVideoTypeChange(value, key)
              "
            ></toggle-resolution>
          </div>
        </template>
        <template v-else>
          <div class="screen-camera-title">
            <span> </span>
          </div>
          <div class="screen-camera-image">
            <loading
              loading-status="empty"
              message="没有添加视频"
            ></loading>
          </div>
        </template>
        <!-- 故障上报 -->
        <fault-dialog 
        :visible="faultVisible" 
        :cameraId="cameraId"
        :isLucency="false"
        @closeFault="closeFaultHandle"
        @faultClick="videoClick"
        :cameraData="cameraData"

        />
        <!-- :cameraData="cameraData" -->
        <edit-fault-dialog 
          :visible="editFaultVisible" 
          :cameraId="cameraId"
          @closeEditFault="closeEditFaultHandle"
          @faultClick="videoClick"
        />
      </drag>
    </drop>
  </div>
</template>

<script>
import flvPlayer from '@/components/module/camera/FlvPlayer'
import ToggleResolution from '@/components/module/camera/ToggleResolution'
import { Drop, Drag } from 'vue-drag-drop'
import FaultDialog from '@/components/fault-dialog'
import EditFaultDialog from '@/components/edit-fault-dialog'

// import { /* mapState, */ mapMutations } from 'vuex';
import moment from 'moment'
import Loading from '@/components/module/Loading'
export default {
  name: 'SplitScreen',
  components: {
    flvPlayer,
    Drop,
    Drag,
    Loading,
    ToggleResolution,
    FaultDialog,
    EditFaultDialog
  },
  data() {
    return {
      getUrlLoading: false,
      cameraList: [],
      ht: 0,
      beforeCameraList: [],
      cameraListBack: [],
      cameraCur: -1,
      cacheCamera: [],
      currentRefObj: {},
      currentCameraInfo: {},
      cameraId:'',
      foFillName:'',
      restTime:'',
      cameraData:{},
      editFaultVisible:false,
      faultVisible: false, // 故障上报弹窗显隐
      dropIndex: null, //拖拽目标区域index
      isVideoArea: false //拖拽结束后是否为拖拽到视频区域内
    }
  },
  props: {
    /* screenType: {
      default() {
        return '';
      },
    }, */
    size: {
      type: Number,
      default() {
        return 4
      }
    },
    setHt: {
      type: Boolean,
      default: false
    },
    isMonitoring: {
      type: Boolean,
      default: false
    },
    screenMode: {
      type: Number,
      default: 0
    },
    showClose: {
      type: Boolean,
      default: true
    }
    /*baseList:{
      default(){
        return [];
      }
    }*/
  },
  watch: {
    size(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.destroyVideo(this.size)
        this.$nextTick(() => {
          this.ht = this.getHeight() + 'px'
        })
      }
    },
  },
  computed: {
    /* ...mapState(['Camera']), */
    heightStyle() {
      return this.setHt ? { height: this.ht } : {}
    },
  },
  /*beforeUpdate(){
    if(this.baseList.length) this.cameraList = this.baseList;
  },*/
  mounted() {
    /*this.$root.$on("removeEventMarker", () => {
      if(!this.chosedMark){
        return false;
      }
      if(this.cameraMarkVisable){
        this.backMark.show();
        return false;
      }
      //console.info(this.chosedMark);
      this.chosedMark.show();
      this.removeMarker(this.backMark);

      this.$refs.dragMarkerGhost.$el.style.top = "-50px";
      this.$refs.dragMarkerGhost.$el.style.left = "-50px";

    });*/
    this.ht = this.getHeight() + 'px'
    // this.totalHeight(this.getHeight());
  },

  methods: {
    moment,
    getHeight() {
      let dom = document.querySelector('#wrap')
      if (!dom) return

      let wd = dom.getBoundingClientRect().width
      console.log(wd, '屏幕宽度')
      switch (this.size) {
        case 1:
          return this.calHeight(wd, 1)
        case 4:
          return this.calHeight(wd, 2)
        case 6:
        case 9:
          return this.calHeight(wd, 3)
        case 12:
        case 16:
          return this.calHeight(wd, 4)
      }
    },
    toggleFaultReported(val){
      console.log(val,'camreaID')
      this.cameraId = val
      this.faultVisible = true
     let params = {
        cameraId : val
      }
      return this.$api.home
        .faultDetails(params)
        .then((res) => {
          if(res.code === 200){
            this.cameraData = res.data
            console.log('this.cameraData', this.cameraData)
      }})
    },
     //关闭故障上报
    closeFaultHandle() {
      this.faultVisible = false
    },
    // 悬浮事件
    videoClick(){
       this.cameraList.map((it, i) => {
          let params = {
            cameraId:it.cameraId
          }
          this.$api.home
            .faultDetails(params).then((res) => {
              let data = res.data
              let detailsInfo = `${data?.faultName}:${this.moment(data?.restoreTime).format('YYYY-MM-DD')}` 
              if(data){
                this.$set(this.cameraList, i, {...it,detailsInfo: detailsInfo})
              }else{
                this.$set(this.cameraList, i, {...it,detailsInfo: null})
              }
              
            console.log('it.detailsInfo', it.detailsInfo)
          })
        });
    },
    //打开编辑故障弹窗
    openEditFault(){
      this.editFaultVisible = true
    },
    //关闭编辑故障弹窗
    closeEditFaultHandle() {
      this.editFaultVisible = false
    },
    calHeight(wd, size) {
      return ((wd / size) * 9) / 16
    },
    isBorder(index) {
      if (this.size == 4) {
        if (index < this.size - 2) {
          return true
        }
      } else if (this.size == 6 || this.size == 9) {
        if (this.screenMode === 2) {
          if (index < this.size - 2) {
            return true
          }
        } else {
          if (index < this.size - 3) {
            return true
          }
        }
      } else if (this.size == 12 || this.size == 16) {
        if (this.screenMode === 3) {
          if (index < this.size - 3) {
            return true
          }
        } else {
          if (index < this.size - 4) {
            return true
          }
        }
      }
    },
    cameraDelete(key) {
      //console.info("cameraDelete", key);
      this.$emit('itemDelete', key)
    },
    setCameraList(list, current) {
      console.log(list, current, this.cameraListBack, 'list, current')
      if (typeof current !== 'undefined') {
        this.cameraCur = current
      }
      if (!list || !list.length) {
        this.cameraList.forEach((vo, idx) => {
          this.removeScreenCamera(idx)
        })
        this.cameraList = []
        return false
      }
      if (this.cameraList.length > 0) {
        this.cameraList.forEach((vo, idx) => {
          this.removeScreenCamera(idx)
        })
      }

      this.cameraList = []
      list.forEach((vo, idx) => {
        this.dropScreenCamera(vo, idx)
      })

      // 获取对应分辨率选项
      const cameraNums = this.cameraList.map(
        (e) => e.cameraNum
      )
      this.$api.home
        .getStreamMediaByCameraNums(cameraNums)
        .then((res) => {
          console.log('getStreamMediaByCameraNums res', res)
          this.cameraList.forEach((e, i) => {
            e.definitions = res[e.cameraNum]?.length
              ? res[e.cameraNum]
              : [
                  {
                    templateId: e.videoType,
                    videoName: {
                      0: '标清',
                      1: '高清',
                      2: '原画'
                    }[e.videoType]
                  }
                ]
          // 更新清晰度状态
         this.cameraListBack.length && this.cameraListBack.map(it => {
            if(it.locationSign == e.locationSign){
              e.videoType = it.videoType
            }
          });
            this.$set(this.cameraList, i, e)
          })
        })
        // 获取摄像机故障信息
        this.videoClick() 
    },
    // 拖拽结束
    dragendScreenCamera(e, key) {
      let tempCameraList = JSON.parse(
        JSON.stringify(this.cameraList)
      )
      let current = this.$parent?.current,
        // cameraData = this.$parent.cameraData,
        dragListIndex = current
          ? parseInt(current) * parseInt(this.size) +
            parseInt(key)
          : parseInt(key),
        dropListIndex = current
          ? parseInt(current) * parseInt(this.size) +
            parseInt(this.dropIndex)
          : parseInt(this.dropIndex),
        /* dragList = this.cameraList[key],
          dropList = this.cameraList[this.dropIndex]fixsxy */
        dragList = tempCameraList[key],
        dropList = tempCameraList[this.dropIndex]
      console.log(
        e,
        key,
        'dragcameraList',
        dropListIndex,
        dragListIndex
      )

      if (!this.isVideoArea) return
      this.isVideoArea = false
      if (
        (!dragList && !dropList) ||
        key === this.dropIndex
      )
        return
      if (!dropList) {
        console.log(dropList, 'dropListdropListdropList')
        let obj =
          this.$refs['flvPlay' + key] &&
          this.$refs['flvPlay' + key][0]
        if (obj) {
          console.log(obj, '123')
          obj.flv_destroy()
        }
        delete this.cameraList[key]
        // delete this.$parent.cameraData[dragListIndex];
      } else {
        this.$set(this.cameraList, key, dropList)
        // this.$set(tempCameraList, key, dropList);
        //  this.$parent.cameraData[dragListIndex] = dropList fixsxy
      }
      if (!dragList) {
        let obj =
          this.$refs['flvPlay' + this.dropIndex] &&
          this.$refs['flvPlay' + this.dropIndex][0]
        if (obj) {
          obj.flv_destroy()
        }
        delete this.cameraList[this.dropIndex]
        // delete this.$parent.cameraData[dropListIndex];
      } else {
        this.$set(this.cameraList, this.dropIndex, dragList) //fixsxy
        //  this.$set(tempCameraList, this.dropIndex,dragList);
        //  this.$parent.cameraData[dropListIndex] = dragList fixsxy
      }
      // console.log('dragendScreenCamera',this.cameraList,dragList)
      this.$forceUpdate()
      this.batchCameraList(this.cameraList)
      this.$emit('updateCameraList', this.cameraList)
    },

    // 批量刷新视频
    batchCameraList(list) {
      console.log('批量刷新视频', list)
      if (!list || !list.length) {
        this.cameraList.forEach((vo, idx) => {
          this.removeScreenCamera(idx)
        })
        this.cameraList = []
        return false
      }

      this.cameraList = []
      list.forEach((vo, idx) => {
        this.$set(this.cameraList, idx, vo)
        this.$nextTick(() => {
          setTimeout(() => {
            let obj = this.$refs['flvPlay' + idx][0]
            // console.info('dropScreenCamera', obj);
            this.cameraPlay(obj, vo)
          }, 500)
        })
        // this.cameraPlay(idx, vo);
      })
    },
    dropScreenCamera(cameraInfo, key, isExChanged = false) {
      console.log('drop', cameraInfo, this.cameraList, key)
      this.dropIndex = key
      this.isVideoArea = true
      this.currentBorderIndex = this.$parent.currentActiveIndex = key
      this.$parent.isVideoArea = true
      if (!cameraInfo) {
        /* debugger
        if (this.$root.dragSelectCamera.cameraId) {
          cameraInfo = this.$root.dragSelectCamera;
        } else { */
        return false
        /*  } */
      }

      this.$root.$emit('removeEventMarker', '')
      if (this.cameraList.length >= this.screenSize) {
        this.$message.info('超过分屏数量限制！')
        return false
      }
      if (
        this.cameraList.indexOf(cameraInfo) >= 0 &&
        !isExChanged
      ) {
        this.$message.info('视频已存在！')
        return false
      }
      //this.screenSizeList.push(cameraInfo);
      if (!isExChanged) {
        this.beforeCameraList[key] = this.cameraList[
          key
        ] = cameraInfo
      } else {
        this.beforeCameraList = this.cameraList
      }
      console.error('papa', this.cameraList)
      //触发响应式
      // this.$set(this.cameraList, key, cameraInfo);
      // console.log('haha', this.cacheCamera);
      // console.log('fafa', this.cacheCamera);

      // if (this.cameraCur !== -1) {
      //   //轮巡后保持高清
      //   // debugger;
      //   cameraInfo.videoType = this.cacheCamera[
      //     this.cameraCur
      //   ]
      //     ? this.cacheCamera[this.cameraCur][key].videoType
      //     : cameraInfo.videoType
      // }

      //开始
      let extent = null
      extent = this.cameraList.length
      if (extent >= 1 && extent <= 16) {
        cameraInfo.playDomain =
          extent % 4 == 0
            ? extent / 4 - 1
            : Math.floor(extent / 4)
      } else {
        cameraInfo.playDomain = null
      }
      //结束
      this.$forceUpdate()
      //this.cameraList.push(cameraInfo);
      //this.cameraList = [...[cameraInfo]];
      this.$nextTick(() => {
        setTimeout(() => {
          let idx = key, //this.cameraList.length - 1,
            obj = this.$refs['flvPlay' + idx][0]
          // console.info('dropScreenCamera', obj);
          this.cameraPlay(obj, cameraInfo)
        }, 500)
      })
      /*setTimeout(() => {
        let idx = key, //this.cameraList.length - 1,
          obj = this.$refs["flvPlay"][idx];
        console.info("dropScreenCamera", obj);
        this.cameraPlay(obj, cameraInfo);
      },500);*/

      // console.info('dropScreenCamera list', this.cameraList);
    },
    destroyVideo(size) {
      for (let i = 0; i < size; i++) {
        this.removeScreenCamera(i)
      }
    },
    screenCameraClose(idx) {
      if (this.isMonitoring) {
        this.removeScreenCamera(idx)
        this.$emit('updateCamera', idx)
        return false
      }
      this.removeScreenCamera(idx)
      this.$emit('deleteCamera', idx)
    },
    removeScreenCamera(idx) {
      let obj =
        this.$refs['flvPlay' + idx] &&
        this.$refs['flvPlay' + idx][0]
      console.log(idx, obj, '删除的视频')

      if (obj) {
        obj.flv_destroy()
        // console.error('haha');
      }
      //投屏停止高清推流(轮巡切换不停流)
      if (
        typeof this.cameraCur == undefined &&
        this.cameraList[idx].videoType === 1
      ) {
        this.stopStreamSingleHandle(this.cameraList[idx])
      }
      delete this.cameraList[idx]
      this.$forceUpdate()
      //删除，添加一个派发事件
      this.$emit('delCamera', this.cameraList)
      //this.cameraList.splice(idx, 1);
      // console.info('removeScreenCamera', obj, this.cameraList);
    },
    stopStreamSingleHandle(cameraInfo) {
      let cameraNums = [cameraInfo.cameraNum]
      let data = {
        cameraNums,
        streamType: -2
      }
      this.$api.home.stopStream(data).then(() => {
        /*  if (res.code) {
          console.log('停止高清推流');
        } */
      })
    },
    stopStreamMulHandle() {
      let cameraNums = this.cameraList.map((item) => {
        if (item.videoType === 1) {
          return item.cameraNum
        }
      })
      let data = {
        cameraNums,
        streamType: -2
      }
      this.$api.home.stopStream(data).then(() => {
        /*  if (res.code) {
          console.log('停止高清推流');
        } */
      })
    },
    pauseSCreenCamera(idx) {
      let obj = this.$refs['flvPlay' + idx][0]
      if (obj) {
        obj.flv_pause()
        // console.error('haha');
      }
    },
    startScreenCameral(idx) {
      let obj = this.$refs['flvPlay' + idx][0]
      if (obj) {
        obj.flv_play()
        // console.error('haha');
      }
    },
    cameraPlay(refObj, cameraInfo, change) {
      console.log('获取播放器2', cameraInfo, this.cameraList)
      this.currentRefObj = refObj
      this.currentCameraInfo = cameraInfo
      let that = this,
        cameraNum = cameraInfo.cameraNum
      //m3u8Url 播放器暂时有问题
      let mediatype = that.$root.mediatype // 'flvUrl';//rtmpUr flvUrl m3u8Url
      let obj = {
        cameraNum: cameraNum, //record.data.cameraName,
        videoType: cameraInfo.videoType || '',
        mediatype: mediatype,
        source: cameraInfo.source 
        // playDomain: cameraInfo.playDomain,
      }
      if (!this.$g.isDepart) {
        obj.playDomain = cameraInfo.playDomain
      }
      this.$api.home
        .getCameraPlayUrlTemp(obj)
        .then((res) => {
          if (res.code === 200) {
            if (!res.data || !res.data.flv) {
              this.$message.error(
                '视频地址请求失败，' + res.message
              )
              //this.$emit("update:visible", false);
              return false
            }

            // 默认分辨率赋值
            cameraInfo.videoType === '' &&
              (cameraInfo.videoType = res.data.videoType)
            refObj.flv_Play(res.data.flv, true)
            if (!change) {
              //TODO: 增加摄像头访问记录
              // that.setCameraVisitRecords({
              //   cameraName: cameraInfo.cameraName,
              //   cameraNum: cameraInfo.cameraNum,
              //   organizationId: cameraInfo.organizationId,
              //   regionCode: cameraInfo.regionCode,
              //   roadId: cameraInfo.roadId
              //   //cameraType      : cameraInfo.cameraType,
              //   //classIfy        : cameraInfo.classIfy
              // });
            }
          } else {
            this.$message.error(res.message)
            //this.reconnect();
          }
        })
      /* .catch(() => {
          this.reconnect();
        }) */
    },
    reconnect() {
      this.reconnectTimer &&
        clearTimeout(this.reconnectTimer)
      this.reconnectTimer = setTimeout(() => {
        console.log('info', '3s后断线重连')
        this.cameraPlay(
          this.currentRefObj,
          this.currentCameraInfo
        )
      }, 3000)
    },
    handleVideoTypeChange(value, index) {
        console.log('list, current-112', value)
        let cameraInfoBack = this.cameraList[index]
        cameraInfoBack.videoType = value
        let dirList = []
        dirList = this.cameraListBack.filter(
        (value) => value.locationSign == cameraInfoBack.locationSign
          )
          if(dirList.length) {
               this.cameraListBack.map((it, i) => {
            console.log('list, current-345', it?.locationSign, cameraInfoBack.locationSign, cameraInfoBack.videoType)
            if(it?.locationSign == cameraInfoBack.locationSign){
              this.$set(this.cameraListBack, i, cameraInfoBack)
            }
          });
          }else{
            this.cameraListBack.push(cameraInfoBack)
          }
        console.log('list, current，dirList', dirList, this.cameraListBack, cameraInfoBack.locationSign)
      // console.log(value, index);
      this.$nextTick(() => {
        let idx = index,
          obj = this.$refs['flvPlay' + idx][0]
        let cameraInfo = this.cameraList[idx]
        cameraInfo.videoType = value

        // cameraInfo.loading = true;
        this.$set(this.cameraList, idx, cameraInfo)
        let copyCameraList = this.cameraList.slice()
        this.cacheCamera[this.cameraCur] = copyCameraList
        // console.log('papa', this.cameraList, this.cacheCamera);
        this.cameraPlay(obj, cameraInfo)
      })
    },
    toggleResolution(resolutionValue) {
      // debugger;
      this.resolutionValue = resolutionValue

      this.getVideoUrlToPlay(
        this.currentCamera,
        false,
        () => {
          this.$message.success(
            resolutionValue === 0
              ? '切换标清成功'
              : '切换高清成功'
          )
        }
      )
    },

    // 全屏dom
    fullScreenDom(ref) {
      const dom_flvWrap = this.$refs[ref]?.[0]?.$el

      if (dom_flvWrap) {
        if (
          document.getFullscreenElement() === dom_flvWrap
        ) {
          document.exitFullscreen()
        } else {
          document.requestFullscreen.apply(dom_flvWrap)
        }
      }
    }
  },

  created() {
  },

  destroyed() {
    this.cacheCamera = []
    this.cameraCur = -1
    this.reconnectTimer && clearTimeout(this.reconnectTimer)
  }
}
</script>

<style lang="less" scoped>
.ht {
  height: 240px;
}
.screen-camera-item {
  position: relative;
  height: 192px;
  // margin-bottom: 10px;
  // padding: 0 20px;
  > span {
    display: inline-block;
    width: 20px;
    position: absolute;
    top: 2px;
    left: 0;
    color: #fff;
    text-align: center;
  }
  &.width-1 {
    width: 100%;
    height: 100%;
    border-right: none;
    border-bottom: none;
  }
  &.width-4 {
    width: 50%;
    height: 50%;
    &:nth-child(2n) {
      border-right: none;
    }
  }
  &.width-6,
  &.width-9 {
    width: 33.3%;
    height: 50%;
    &:nth-child(3n) {
      border-right: none;
    }
  }
  &.width-6.width-2 {
    width: 50%;
    height: 33.3%;
    &:nth-child(2n) {
      border-right: none;
    }
    &:nth-child(3n) {
      border-right: 1px dashed rgba(43, 82, 134, 1);
    }
  }
  &.width-9 {
    height: 33.3%;
  }
  &.width-12,
  &.width-16 {
    width: 25%;
    height: 33.3%;
    &:nth-child(4n) {
      border-right: none;
    }
  }
  &.width-12.width-3 {
    width: 33.3%;
    height: 25%;
    &:nth-child(4n) {
      border-right: 1px dashed rgba(43, 82, 134, 1);
    }
    &:nth-child(3n) {
      border-right: none;
    }
  }
  &.width-16 {
    height: 25%;
  }
  &:hover .drag > .screen-camera-delete,
  &:hover .drag > .screen-camera-title,
  &:hover .drag > .video-type {
    visibility: visible;
  }

  .screen-camera-delete {
    position: absolute;
    top: 5px;
    right: 8px;
    z-index: 15;
    text-align: center;
    visibility: hidden;
    i {
      font-size: 0.675rem;
      color: #fff;
    }
  }
  .screen-camera-title {
    height: 28px;
    line-height: 28px;
    background: #000c;
    color: #fff;
    padding: 0 20px 0 5px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    visibility: hidden;
  }
  .screen-camera-image {
    // border: 1px solid #236da1;
    // padding: 4px;
    height: 100%;
    /*background-image: url("../../../assets/images/1.jpeg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: left center;*/
    img {
      width: 100%;
      height: 100%;
    }
  }
  .drag {
    width: 100%;
    height: 100%;
  }
}
.video-type {
  display: flex;
  position: absolute;
  right: 10px;
  bottom: 10px;
  visibility: hidden;
  z-index: 99;
}
.icon-fault {
  /* background-image: url("../assets/dialog/icon-hd.png"); */
  display: inline-block;
  font-style: unset;
  width: 70px;
  font-size: 14px;
  color: #86b3e9;
  padding: 0 5px;
  border: 1px solid #999;
  // border-radius: 2px;
  background: rgba(0, 0, 0, 0.7);

}
.ant-select-selection {
  background: blue;
}
</style>
