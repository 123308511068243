import Vue from 'vue';
import Vuex from 'vuex';
// import { formatGroupTreeData, formatOrganizationTree } from '@/tools';

/*import router from '@/router/router'; */

Vue.use(Vuex);
let vm = new Vue();
/*  */
const state = {
  messageList: [],
  noReadMessageList:[],
  readNum:0,
  noReadNum:0,
};
const actions = {
  /**
   * 获取摄像机列表
   * @param store
   * @param obj
   * @returns {Promise<*>}
   */
  // eslint-disable-next-line no-unused-vars
  getMessageList(store, params) {
    return Vue.prototype.$api.message.getMessageList(params).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '获取消息中心数据失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      if(params.alreadyRead === 0) {
        store.commit('setNoReadMessageList',data.data)
      }
      store.commit('setMessageList', data.data);
      return data;
    });
  },
  getMessageCount(store) {
    return Vue.prototype.$api.message.getMessageCount().then(data => {
      if(data.code !== 200) {
        vm.$message.error('获取消息数据失败');
        return Promise.reject();
      }
      let readNum = data.data.filter(item=>item.already_read === 1).length>0?data.data.filter(item=>item.already_read === 1)[0]['messageNumber']:0;
      let noReadNum = data.data.filter(item=>item.already_read === 0).length>0?data.data.filter(item=>item.already_read === 0)[0]['messageNumber']:0;
      store.commit('setReadNum',readNum);
      store.commit('setNoReadNum',noReadNum);
      return data;
    })
  }
   
};
const mutations = {
  setMessageList(state, val) {
    state.messageList = val;
  },
  setReadNum(state,val) {
    state.readNum = val;
  },
  setNoReadNum(state,val) {
    state.noReadNum = val;
  },
  setNoReadMessageList(state,val) {
    state.noReadMessageList = val;
  }
};

export default {
  state,
  mutations,
  actions
};
