<template>
  <a-modal
    width="70%"
    :visible="visible"
    :dialogStyle="dialogStyle"
    dialogClass="dialog"
    :centered="true"
    @cancel="handleCancel"
    :footer="null"
  >
    <div slot="title" class="flex items-center">
      <span class="text-white m-r-sm">报警详情</span>
      <span
        class="report-handler pointer"
        @click="misReport"
        v-if="currentDetail.misReport"
        >误报</span
      >
    </div>
    <div class="flex full-height" ref="conDom">
      <div class="fl full-height" ref="leftDom">
        <div class="on-con relative p-md full-width full-height">
          <span class="cor absolute t-lf"></span>
          <span class="cor absolute t-rf"></span>
          <span class="cor absolute b-lf"></span>
          <span class="cor absolute b-rf"></span>
          <div class="con-main full-height">
            <div class="m-b-sm">
              <span class="text-white m-b-xs block">{{
                currentDetail.cameraName || '--'
              }}</span>
              <span class="text-lightgray">{{
                currentDetail.cameraNum || '--'
              }}</span>
            </div>
            <div class="flex m-b-sm">
              <span class="text-lightblue wd56  text-right m-r-xs"
                >报警事件</span
              >
              <template>
                <span class="flex-one text-white" v-show="!isEdit">{{
                  currentDetail.eventName || '--'
                }}</span>
                <a-input
                  v-show="isEdit"
                  class="flex-one"
                  v-model="eventTypeName"
                />
              </template>
            </div>

            <div class="flex  m-b-sm">
              <span class="text-lightblue wd56 text-right m-r-xs"
                >上报时间</span
              >
              <span class="flex-one text-white">{{
                currentDetail.startTime || '--'
              }}</span>
            </div>

            <div class="flex  m-b-sm">
              <span class="text-lightblue wd56 text-right m-r-xs"
                >发生路线</span
              >
              <span
                class="flex-one text-white ellipsis"
                :title="getRoad(currentDetail)"
                >{{ getRoad(currentDetail) || '--' }}</span
              >
            </div>

            <div class="flex  m-b-sm">
              <span class="text-lightblue wd56 text-right m-r-xs">桩号</span>
              <span class="flex-one text-white">{{
                currentDetail.pileNum || '--'
              }}</span>
            </div>
            <div class="flex  m-b-sm">
              <span class="text-lightblue wd56 text-right m-r-xs">方向</span>
              <span class="flex-one text-white">{{
                currentDetail.directionStr || '--'
              }}</span>
            </div>
            <div class="flex  m-b-sm">
              <span class="text-lightblue wd56 text-right m-r-xs"
                >管辖单位</span
              >
              <span class="flex-one text-white">{{
                currentDetail.organizationName || '--'
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-one full-height m-l-sm">
        <div class="hd flex justify-between m-b-sm" ref="hdDom">
          <div class="fl-menu flex items-center">
            <span
              class="pointer"
              v-for="(item, index) in menuList"
              :class="{ current: item.id === activeMenu }"
              :key="index"
              @click="tabClick(item)"
              >{{ item.name }}</span
            >
          </div>
          <div class="flex items-center" v-if="type === 'map'">
            <a-button
              type="primary"
              class="m-r-xs"
              @click="prevHandle"
              :disabled="currentIndex === 0"
              >上一个</a-button
            >
            <a-button
              type="primary"
              @click="nextHandle"
              :disabled="getNextDisabled"
              >下一个</a-button
            >
            <span class="text-lightblue m-r-sm" v-if="false"
              >关联摄像机：<span class="text-white">{{
                cameraCount
              }}</span></span
            >
            <a-select
              v-model="selectCurCamera"
              style="width: 120px"
              placeholder="请选择摄像机"
              @change="selectCamera"
              v-if="false"
            >
              <a-select-option
                v-for="item in cameraListNearby"
                :key="item.cameraId"
                :value="item.cameraNum"
              >
                {{ item.khPile }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div ref="rightDom" class="right-dom">
          <div v-if="activeMenu === 0">sadf</div>
          <div v-else-if="activeMenu === 1" class="full-height">
            <div class="full-height flex items-center justify-center">
              <img :src="getImg" v-if="getImg" />
            </div>
          </div>
          <div v-else-if="activeMenu === 2" class="full-height">
            <split-screen ref="splitScreenFull" :size="1" :showClose="false" />
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import SplitScreen from '@/components/SplitScreen';
const menuList = [
  {
    id: 1,
    name: '事件截图',
  },
  {
    id: 2,
    name: '当前画面',
  },
];
export default {
  name: 'EventAlarmDetail',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    cameraDetailId: {
      type: String,
      default: '',
    },
    cameraDetail: {
      type: Object,
      default() {
        return {};
      },
    },
    sourceType: {
      type: String,
      default: 'hisId',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isEdit: false,
      plainValue: 0,
      activeMenu: 0,
      menuList: menuList,
      inputText: '',
      eventSelect: [],
      plainOptions: [
        { label: '一般', value: '0' },
        { label: '严重', value: '1' },
        { label: '特严重', value: '2' },
      ],
      cameraCount: 0,
      cameraListNearby: [],
      selectCurCamera: undefined,
      eventTypeName: '',
      dialogStyle: { maxWidth: '1000px', maxHeight: '550px', height: '80%' },

      currentEventDetailList: [],
      currentIndex: 0,
    };
  },
  computed: {
    getEventLevel() {
      return this.plainValue === '0'
        ? '一般'
        : this.plainValue === '1'
        ? '严重'
        : this.plainValue === '2'
        ? '特严重'
        : '';
    },
    getNextDisabled() {
      return (
        (this.currentIndex === this.currentEventDetailList.length - 1 &&
          this.currentEventDetailList.length > 0) ||
        this.currentEventDetailList.length <= 0
      );
    },
    currentDetail() {
      return this.currentEventDetailList[this.currentIndex] || {};
    },
    getMediaList() {
      let currentMedia = this.currentDetail['mediaList'];
      return currentMedia ? JSON.parse(currentMedia) : [];
    },
    getImg() {
      return this.getMediaList.length ? this.getMediaList[0]['src'] : '';
    },
  },
  watch: {
    getMediaList(nv) {
      this.menuList = nv.length
        ? menuList
        : [
            {
              id: 2,
              name: '当前画面',
            },
          ];
      this.activeMenu = this.menuList[0].id;
    },
    /* activeMenu(nv) {
      if (nv == 2) {
        this.$nextTick(() => {
          this.$refs.splitScreenFull.setCameraList([
            this.currentEventDetailList[0],
          ]);
        });
      }
    }, */
    cameraDetailId(newValue, oldValue) {
      if (newValue && newValue != oldValue) {
        this._getEventAlarmDetail(newValue);
      }
    },
    currentDetail: {
      handler(nv) {
        this.openStream(nv);
      },
      deep: true,
    },
    /* cameraDetail: {
      handler(newValue) {
        this.setDefault(newValue);
      },
      deep: true,
    }, */
  },
  created() {
    this.activeMenu = this.menuList[0].id;
  },
  methods: {
    openStream(nv) {
      if (this.activeMenu == 2) {
        this.$nextTick(() => {
          this.$refs.splitScreenFull.setCameraList([nv]);
        });
      }
    },
    nextHandle() {
      this.currentIndex++;
    },
    prevHandle() {
      this.currentIndex--;
    },
    getRoad(currentDetail) {
      return `${currentDetail.roadCode} ${currentDetail.roadName}`;
    },
    _getEventAlarmDetail(eventCqId) {
      let data = {
        [this.sourceType]: eventCqId,
      };

      this.$api.aiCenterY.queryEventResultListByCondition(data).then((res) => {
        if (res.code === 200) {
          // // console.error(res);
          // this.cameraCount = res.data['关联摄像机数'];
          // this.cameraListNearby = res.data['摄像机列表'];
          this.currentEventDetailList = res.data.map((item) => {
            item.misReport = true;
            return item;
          });
          /* this.$nextTick(() => {
            this.$refs.splitScreenFull.setCameraList([
              this.currentEventDetailList[0],
            ]);
          }); */
        }
      });
    },
    handleCancel() {
      this.activeMenu === 2 && this.$refs.splitScreenFull.destroyVideo(1);
      this.activeMenu = 0;
      this.currentIndex = 0;
      this.$emit('close');
    },
    editEvent() {
      this.isEdit = true;
    },
    submitHandle() {
      this.isEdit = false;
      //修改数据
      let obj = {
        eventTypeName: this.eventTypeName,
        eventLevel: this.plainValue,
        eventSummary: this.inputText,
      };
      let params = {
        eventLevel: obj.eventLevel,
        eventSummary: obj.eventSummary,
        eventTypeName: obj.eventTypeName,
        id: this.cameraDetailId,
      };
      this.$api.aiCenterY.modifyEventAlarm(params).then((res) => {
        if (res.code === 200) {
          this.$message.success('修改成功!');
          this.setOrigin(obj);
        } else {
          this.$message.error('修改失败!');
        }
      });
    },
    cancelHandle() {
      /* this.isEdit = false;
      //重置为上次数据
      this.eventTypeName = this.originName;
      this.plainValue = this.originPlainValue;
      this.inputText = this.originInputText; */
      this.activeMenu = 0;
    },
    handleChange(value) {
      this.eventSelect = value;
    },
    onChangeRadio(e) {
      this.plainValue = e.target.value;
    },
    tabClick(item) {
      this.activeMenu = item.id;
      console.log('haha', this.currentDetail);
      this.openStream(this.currentDetail);
    },
    selectCamera(e) {
      this.selectCurCamera = e;
      let cameraList = this.cameraListNearby.filter(
        (item) => item.cameraNum === this.selectCurCamera
      );
      // console.error('hah', this.selectCurCamera, cameraList);
      this.$nextTick(() => {
        this.$refs.splitScreenFull.setCameraList(cameraList);
      });
    },
    /* setDefault(newValue) {
      this.eventTypeName = newValue.eventTypeName;
      this.plainValue = newValue.eventLevel;
      this.inputText = newValue.eventSummary;
      this.setOrigin(newValue);
      this.$nextTick(() => {
        this.$refs.splitScreenFull.setCameraList([this.cameraDetail]);
      });
    }, */
    setOrigin(newValue) {
      this.originName = newValue.eventTypeName;
      this.originPlainValue = newValue.eventLevel;
      this.originInputText = newValue.eventSummary;
    },
    misReport() {
      if(!this.hasPermission(109212111010)) {
        this.permissionWarning();
        return;
      }
      let _this = this;
      this.$confirm({
        content: '确认误报将删除此条报警信息，确认该报警为误报吗？',
        zIndex: 2147483649,
        onOk() {
          _this.misReportHandle();
        },
        onCancel() {},
      });
    },
    misReportHandle() {
      let params = {
        id: this.currentDetail.hisId,
      };
      let objParams = {
        data: params,
        instructionsDel: {
          module: '路线报警统计',
          page: '查看报警',
          feature: '误报',
          description: this.currentDetail.eventName,
        },
      };
      this.$api.aiCenterY.misinformation(objParams).then((res) => {
        if (res.code === 200) {
          this.$message.success('提交成功!');
          this.currentDetail.misReport = false;
          this.$emit('misReportHandle');
        } else {
          this.$message.error('提交失败!');
        }
      });
    },
  },
  components: {
    SplitScreen,
  },
};
</script>

<style scoped>
.fl {
  width: 38%;
}
.wd56 {
  width: 68px;
}
.right-dom {
  height: calc(100% - 40px);
}
::v-deep .ant-modal-content {
  height: 100%;
}
::v-deep .ant-modal-body {
  height: calc(100% - 50px);
}
</style>
