import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
// import qs from 'qs'; // 根据需求是否导入qs模块

const alarm = {
  getAlarm() {
    return axios
      .get(`${base.sq}/monitor/eventAlarm/getAlarm`)
      .then(({ data }) => {
        return data;
      });
  },
  modifyAlarm(data) {
    return axios
      .post(`${base.sq}/monitor/eventAlarm/updateAlarm`, data)
      .then(({ data }) => {
        return data;
      });
  },
  //数据看板-获取摄像机故障列表
  getCameraList(data) {
    return axios
      .post(`${base.sq}/monitor/treeAccess/getCameraList`, data)
      .then(({ data }) => {
        return data;
      });
  },
  //修改异常类型
  modifyFault(data) {
    return axios
      .post(`${base.sq}/monitor/treeAccess/modifyFault`, data.data)
      .then(({ data }) => {
        return data;
      });
  }
};
export default alarm;
