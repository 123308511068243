<template>
  <div class="flex menu">
    <div class="left-menu relative top10">
      <div class="left-title flex items-center weight text-white">
        <!-- <i class="m-r"></i> -->
       <template v-if="$g.isDepart">
          <img :src="logoInfo.logoUrl" class="logo m-r-sm" />{{ logoInfo.name }}
        </template>
        <template v-else>
          <img :src="userInfo.logoUrl" class="logo m-r-sm" />{{ userInfo.webTitle }}
        </template>
      </div>
    </div>
    <div class="center-menu relative">
      <span class="c-m-l"></span>
      <span class="c-m-r"></span>
      <div class="c-m-c flex items-center justify-around">
        <router-link to="/home" v-show="hasPermission(109210)"
          ><i class="ve-m icon-head-two in-block m-r-xs icon-m"></i
          >监控中心</router-link
        >
        <!-- v-hasPermiss="'109211'" -->
        <router-link to="/resource" v-show="hasPermission(109211)"
          ><i class="ve-m icon-head-two in-block m-r-xs icon-r"></i
          >资源中心</router-link
        >
        <router-link to="/ai-center" v-if="$g.isDepart" v-show="hasPermission(109212)"
          ><i class="ve-m icon-head-two in-block m-r-xs icon-a"></i
          >AI中心</router-link
        >
      </div>
    </div>
    <div class="right-menu relative top10">
      <div class="right-title flex items-center justify-between">
        <div class="flex-one text-lightblue flex justify-around">
          <!-- <span class="pointer" @click="viewMap" v-show="$g.isDepart"
            ><i class="in-block icon-head icon-map ve-m m-r-xs"></i>地图</span
          > -->
           <span class="flex items-center pointer text-lightblue" v-show="$g.isDepart" @click="viewMonitor"
            ><i class="in-block icon-head icon-monitor ve-m m-r-xs"></i
            >监控</span
          >
          <span
            class="flex items-center pointer text-lightblue"
            @click="viewHandle()"
            v-hasPermiss="'109214'"
          >
            <i class="icon-patrol pointer m-r-xs"></i>巡检
          </span>
          <span
            class="flex items-center pointer text-lightblue"
            @click="spotCheckHandle()"
            v-show="$g.isDepart"
          >
            <i class="icon-patrol pointer m-r-xs"></i>抽检
          </span>
          <span class="pointer" v-show="!hasMessage" @click="viewMessage()"><i class="in-block icon-head icon-mess ve-m m-r-xs"></i>消息</span>
          <a-popover placement="bottom" :style="{'pointerEvents':hasMessage ? 'auto':'none'}" v-show="hasMessage">
            <template slot="content">
              <div class="content-container">
                <div class="text-right m-b-sm">
                  <span
                    class="pointer text-lightblue f12"
                    @click="handleAllRead()"
                    >全部设为已读</span
                  >
                </div>
                <div
                  :class="[
                    'content-main m-b-sm',
                    { 'text-white': !readAll, 'text-lightgray': readAll },
                  ]"
                >
                  <vue-scroll :ops="$root.scrollOpsY" style="height:260px">
                    <div
                      class="c-m-list m-b-sm pointer"
                      v-for="item in headMessageList"
                      :key="item.id"
                    >
                      <span class="block ellipsis">{{ item.content }}</span>
                      <span class="f12 text-lightblue">{{
                        item.gmtCreate
                      }}</span>
                    </div>
                  </vue-scroll>
                </div>
                <div
                  class="text-center text-lightblue bdt pointer f12"
                  @click="viewMessage()"
                >
                  查看所有消息
                </div>
              </div>
            </template>
            <span class="pointer" @click="viewMessage()"><a-badge :count="noReadNum" dot><i class="in-block icon-head icon-mess ve-m m-r-xs"></i
              ></a-badge>消息</span>
           
          </a-popover>
          <a-dropdown
            v-show="$g.isDepart"
            :style="{
              pointerEvents:
                userInfo['role'].includes(100000001) || hasPermission(109217)
                  ? 'auto'
                  : 'none',
            }"
          >
            <span class="pointer"
              ><i class="in-block icon-head icon-sys ve-m m-r-xs"></i>系统</span
            >
            <a-menu slot="overlay" @click="systemHandle">
              <a-menu-item key="1" v-if="hasPermission(10921710)">
                <a href="javascript:;">用户管理</a>
              </a-menu-item>
              <a-menu-item key="4" v-if="hasPermission(10921711)">
                <a href="javascript:;">角色管理</a>
              </a-menu-item>
              <a-menu-item key="2" v-if="hasPermission(10921712)">
                <a href="javascript:;">操作日志</a>
              </a-menu-item>
              <a-menu-item key="6" v-if="userInfo['role'].includes(100000001)">
                <a href="javascript:;">行为审计</a>
              </a-menu-item>
              <a-menu-item key="5" v-if="userInfo['role'].includes(100000001)">
                <a href="javascript:;">数据字典</a>
              </a-menu-item>
              <a-menu-item key="7" v-if="userInfo['role'].includes(100000001)">
                <a href="javascript:;">存储管理</a>
              </a-menu-item>
              <a-menu-item key="8" v-if="userInfo['role'].includes(100000001)">
                <a href="javascript:;">权限管理</a>
              </a-menu-item>
              <a-menu-item key="3" v-if="userInfo['role'].includes(100000001)">
                <a href="javascript:;">系统设置</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <span class="pointer" @click="goHisHandle()" v-show="!$g.isDepart"
            ><i class="in-block icon-head icon-prot ve-m m-r-xs"></i>运维</span
          >

          <a-dropdown>
            <span class="pointer">
              <a-icon type="user" class="ve-m m-r-xs f16" />{{
                userInfo['userName']
              }}
            </span>
            <a-menu slot="overlay" @click="handleMenuClick">
              <a-menu-item key="2">修改密码</a-menu-item>
              <a-menu-item key="1">退出</a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div>
          <i
            class="in-block icon-head icon-cloth ve-m m-r m-l pointer"
            v-if="false"
          ></i>
          <i
            class="in-block icon-head icon-screen ve-m relative pointer"
            @click="fullScreenHandle()"
          ></i>
        </div>
      </div>
    </div>
    <log-action-dialog :visible="addVisible" @close="closeHandle" />
    <modify-pass ref="modifyPass" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
// import { compileStr } from '@/tools';
import logActionDialog from '@/components/logActionDialog';
import ModifyPass from '@/components/ModifyPass';
// import { setStorage } from '@/tools';

export default {
  components: {
    logActionDialog,
    ModifyPass,
  },
  name: 'Header',
  data() {
    return {
      readAll: false,
      webTitle: '',
      addVisible: false,
      messageTotal:0,
      // headMessageList:[]
    };
  },
  computed: {
    ...mapState({
      hasFullScreen: (state) => state.home.hasFullScreen,
      noReadMessageList: (state) => state.message.noReadMessageList,
      userInfo: (state) => state.login.userInfo,
      drawerDom: (state) => state.home.drawerDom,
      noReadNum: (state) => state.message.noReadNum,
      logoInfo: (state) => state.login.logoInfo,
    }),
    hasMessage(){
      return this.noReadNum > 0;
    },
    headMessageList() {
      return this.noReadMessageList.slice(0, 5);
    }
  },
  watch: {},
  mounted() {
    this.webTitle = this.$g.webTitle;
    this._getMessageList();
    this.getMessageCount();
    //获取logo信息
    this.getLogoInfo();
  },
  methods: {
    ...mapMutations(['setHasFullScreen', 'setCurrentDrawerCom']),
    ...mapActions(['getMessageList', 'logout','getMessageCount','getLogoInfo']),
    _getMessageList() {
      this.getMessageList({ currPage: 1, pageSize: 10,alreadyRead:0 }).then((data) => {
        if (data.code === 200) {
          this.messageTotal = data.total;
          // this.headMessageList = data.data;
        }
      });
    },
    handleAllRead() {
      // this.readAll = true;
      this.$confirm({
        title: '提示',
        content: '是否标记所有为已读?',
        onOk: () => {
           this.$api.message.updateMessage().then(res=>{
             if(res.code === 200) {
               this.$message.success('标记所有已读成功！');
               //更新数量
               this.getMessageCount();
               this._getMessageList();
             }
           })
        },
        onCancel: () => {},
      });
    },
    systemSet() {
      this.addVisible = true;
    },

    viewMessage() {
      this.$router.push({ path: '/home/all-message' });
    },
    viewMap() {
      this.$router.push({ path: '/map-com' });
    },
    fullScreenHandle() {
      let element = document.documentElement;
      // 判断是否已经是全屏
      // 如果是全屏，退出
      if (this.hasFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.setHasFullScreen(false);
        //console.log('已还原！');
      } else {
        // 否则，进入全屏
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
        // console.log("已全屏！");

        this.setHasFullScreen(true);
      }
      // 改变当前全屏状态
      // this.hasFullScreen = !this.hasFullScreen;
    },
    viewTurn() {
      this.$router.push({
        path: '/home/turn-pro',
      });
    },
    viewMonitor() {
      this.$router.push({
        path: '/home/project-screen',
      });
    },
    handleMenuClick({ key }) {
      switch (key) {
        case '1':
          this.$confirm({
            title: '提示',
            content: '确认退出登录吗?',
            onOk: () => {
              this.logout();
            },
            onCancel: () => {},
          });
          // this.logout();
          break;
        case '2':
          this.$refs.modifyPass.show();
          break;
      }
    },
    goHisHandle() {
      /* window.open(
        `https://cqvideo.gandongyun.com/#/dashboard/?cloudplatform=${JSON.stringify(
          localStorage.getItem('cloudplatform')
        )}`
      ); */
      // http://139.9.157.61:2020/
      /* window.open(
        `http://139.9.157.61:8098/#/auto-login/?username=${this.userInfo.userName}&password=gdkjgdkj!`
      ); */
      /* window.open(
        `http://localhost:8081/#/auto-login/?username=${window.btoa(
          this.userInfo.userName
        )}&password=${window.btoa(this.userInfo.pass)}`
      ); 本地*/
      if(!this.hasPermission(1093)){
        this.permissionWarning();
        return;
      }
      window.open(
        `${window.g.jumbUrl}?username=${window.btoa(
          this.userInfo.loginName
        )}&password=${window.btoa(this.userInfo.pass)}`
      );
    },
    systemHandle({ key }) {
      // console.log('haha', key, this.drawerDom);
      // this.drawerDom.showDrawer();
      switch (key) {
        case '1':
          this.$router.push({ path: '/system/user-manage?initTab=0' });
          break;
        case '2':
          this.$router.push({ path: '/system/log-action?initTab=3' });
          break;
        case '3':
          // this.$router.push({ path: '/ai-center' });
          // this.systemSet();
          /* if (!this.getPermistion('109216')) {
            this.$message.error('你没有权限访问！');
            return;
          } */
          this.$router.push({ path: '/system/system-setting?initTab=6' });
          break;
        case '4':
          this.$router.push({ path: '/system/role-manage?initTab=1' });
          break;
        case '5':
          this.$router.push({ path: '/system/camera-dict?initTab=4' });
          break;
        case '6':
          this.$router.push({ path: '/system/act-action?initTab=2' });
          break;
        case '7':
          this.$router.push({ path: '/system/store-manage?initTab=5' });
          break;
        case '8':
          this.$router.push({ path: '/system/authority-manage?initTab=7' });
          break;
      }
    },
    getPermistion(value) {
      return this.$_btnPermissionCheck(value);
    },
    closeHandle() {
      this.addVisible = false;
    },
    viewHandle() {
      // this.$router.push({ path: '/home/project-screen' });
      this.$router.push({
        path: '/home/turn-pro',
      });
    },
    spotCheckHandle() {
      if(!this.hasPermission(109214)) {
        this.permissionWarning();
        return;
      }
      //跳转到抽检页面
      this.$router.push({
        path: '/spot-check',
      });
    },
  },
};
</script>

<style scoped>
.menu {
  background: #0f182d;
  padding-bottom: 10px;
}
.left-menu,
.right-menu {
  width: 35%;
  border-top: 1px solid #4a6080;
  padding-top: 4px;
}
.left-menu {
  /* padding-left: 20px; */
  border-left: 1px solid #4a6080;
}
.left-menu::before {
  content: '';
  position: absolute;
  left: -1px;
  top: -1px;
  background: url('../assets/home/left-top-corner.png') no-repeat;
  width: 33px;
  height: 30px;
  background-size: 100% 100%;
}
.right-menu {
  border-right: 1px solid #4a6080;
}
.right-menu::before {
  content: '';
  position: absolute;
  right: -1px;
  top: -1px;
  background: url('../assets/home/right-top-corner.png') no-repeat;
  width: 36px;
  height: 32px;
  background-size: 100% 100%;
  z-index: 0;
}
.center-menu {
  width: 30%;
  border-bottom: 1px solid #4a6080;
  height: 58px;
  padding-bottom: 3.9px;
}
.center-menu .c-m-l {
  position: absolute;
  /* background: url('../assets/home/header-left-corner.jpg') no-repeat; */
  background: url('../assets/home/1.png') no-repeat;
  width: 62px;
  height: 57px;
  left: -20px;
  top: 1px;
  background-size: 100% 100%;
}
.center-menu .c-m-r {
  position: absolute;
  /* background: url('../assets/home/header-right-corner.png') no-repeat; */
  background: url('../assets/home/2.png') no-repeat;
  width: 63px;
  height: 57px;
  right: -28px;
  top: 1px;
  z-index: 2;
  background-size: 100% 100%;
}
.center-menu .c-m-c {
  /* background: linear-gradient(0deg, #2e568e, #0f283d); */
  background: linear-gradient(0deg, #2e568e, #204268, #12233b);
  border: 1px solid;
  border-image: linear-gradient(0deg, #6285b7, #1e3c5b) 1 1;
  height: 100%;
  padding: 0 20px;
}
.center-menu a {
  color: #96b9e4;
  position: relative;
}
.center-menu a:hover,
.center-menu a.active {
  color: #fff;
}
.center-menu a:hover::after,
.center-menu a.active::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom-color: #9ebce2;
  left: 50%;
  bottom: -14px;
  transform: translateX(-50%);
}
.top10 {
  top: 10px;
}
.left-title {
  background: url('../assets/home/left-top.png') no-repeat;
  width: calc(100% - 40px);
  height: 38px;
  font-size: 22px;
  background-size: 100% 100%;
  margin-left: 40px;
  position: relative;
  z-index: 22;
}
.left-title i,.left-title .logo {
  /* background: url('../assets/home/logo.png') no-repeat; */
  width: 34px;
  height: 34px;
  /* background-size: 100% 100%; */
}
.right-title {
  background: url('../assets/home/right-top.png') no-repeat;
  width: 100%;
  height: 38px;
  background-size: 100% 100%;
  padding: 0 24px 0 28px;
  position: relative;
  z-index: 22;
}
.icon-head-two {
  width: 18px;
  height: 18px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.icon-map {
  background-image: url('../assets/home/map.png');
}

.icon-mess {
  background-image: url('../assets/home/message.png');
}
.icon-sys {
  background-image: url('../assets/home/setting.png');
}
.icon-prot {
  background-image: url('../assets/home/protect.png');
}
.icon-cloth {
  background-image: url('../assets/home/skin.png');
}
.icon-screen {
  background-image: url('../assets/home/max.png');
  z-index: 1;
}
.icon-m {
  background-image: url('../assets/home/icon-m.png');
}
.icon-r {
  background-image: url('../assets/home/icon-r.png');
}
.icon-a {
  background-image: url('../assets/home/icon-a.png');
}

.icon-head.ve-m {
  vertical-align: -5px;
}
.content-container {
  width: 240px;
}
.content-container .bdt {
  border-top: 1px solid #9ebce2;
  padding-top: 10px;
  margin: 0 -15px;
}
</style>
