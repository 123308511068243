import base from './base'; // 导入接口域名列表
import a from '@/request/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

// 定时录制
export default {
  getTableData: params => a.get(`${base.sq}/monitor/screenShot/videoTimerList?${qs.stringify(params)}`).then(res => res.data),

  // 综合操作,{...params}为新增, {...params, id}为修改, {id, status: '0'}为删除(status必须字符串)
  handle: params => a.post(`${base.sq}/monitor/screenShot/videoTimer`, params).then(res => res.data),
}
