import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
// import qs from 'qs'; // 根据需求是否导入qs模块

const storeManage = {
  //拉取列表
  getList(data) {
    return axios
      .post(`${base.sq}/monitor/memoryInfo/getList`, data)
      .then(({ data }) => {
        return data;
      });
  },
  //获取存储空间信息
  getSpaceInfo(data) {
    return axios
      .post(`${base.sq}/monitor/memoryInfo/getSpaceInfo`, data)
      .then(({ data }) => {
        return data;
      });
  },
  //修改存储空间
  modifyMemory(data) {
    return axios
      .put(`${base.sq}/monitor/memoryInfo/modifyMemory`, data)
      .then(({ data }) => {
        return data;
      });
  }
};
export default storeManage;
