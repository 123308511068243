import Vue from 'vue';
import Vuex from 'vuex';
import { handleExportFile } from '@/tools';
/*import router from '@/router/router'; */

Vue.use(Vuex);
let vm = new Vue();
/*  */
const state = {
  accessInfo: [],
  accessFirstInfo: [],
  classifyInfo: [],
  backFlag: false,
  backPath: [],
  exceptionList: [],
  errorDetails: [],
  backExcepFlag: false,
  backExcepPath: [],
  homepagePot: [],
  cameraGroupLists: [],
  networkStatistic: [],
  networkStatisticDetail: [],
  screenShotData: null,
  screenShotDataInfo: [],
  screenShotSearchParams: {
    currPage: 1,
    pageSize: 24,
    cameraId: '',
    type: '',
    cameraName: '',
    startTime: '',
    endTime: ''
  },
  screenShotProvinceParams: {
    currPage: 1,
    pageSize: 24,
    cameraNum: '',
    startTime: '',
    endTime: ''
  },
  screenTotal: 0,
  selectScreenCameraList: [],
  cameraGroupInfoById: [],
  isClick: true,
  picLoading: false,
  currentMode: 'pic',
  isProvince: false,
  isLoading: false
};
const actions = {
  /**
   * 获取统计数据
   * @param store
   * @param obj
   * @returns {Promise<*>}
   */
  // eslint-disable-next-line no-unused-vars
  getAccessInfo(store, params) {
    return Vue.prototype.$api.resource.getAccessInfo(params).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '获取统计数据失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      // console.error('haha', data);
      let childInfo = data.data.childInfo;
      let classifyInfo = data.data.classifyInfo;
      if (childInfo && childInfo.length <= 0) {
        // vm.$message.error('没有下级组织！');
        // return childInfo;fixsxy
        childInfo = [{ ...data.data }];
      }
      if (Vue.prototype.$g.isDepart) {
        store.commit('setBackFlag', true);
      } else {
        if (params.organizationId) {
          store.commit('setBackFlag', true);
        } else {
          store.commit('setBackFlag', false);
        }
      }

      if (params.organizationId === '') {
        store.commit('setAccessFirstInfo', childInfo);
      }
      store.commit('setAccessInfo', childInfo);
      store.commit('setClassifyInfo', classifyInfo);
      return childInfo;
    });
  },
  getRegion(store) {
    return Vue.prototype.$api.aiCenterY.getRegion().then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '获取统计数据失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      //  console.error('haha', data);
      let childInfo = data.data.runRateEntity.map(item => {
        item.name = item.regionName;
        return item;
      });
      let classifyInfo = data.data.cameraConnecInfo;
      store.commit('setBackFlag', false);
      store.commit('setAccessFirstInfo', childInfo);

      store.commit('setAccessInfo', childInfo);
      store.commit('setClassifyInfo', classifyInfo);
      return childInfo;
    });
  },
  getDepartAccessInfo(store, params) {
    return Vue.prototype.$api.resource
      .getDepartAccessByRegion(params)
      .then(data => {
        if (data.code !== 200) {
          vm.$message.error(
            '获取统计数据失败! [' + data.code + ']: ' + data.message
          );
          return Promise.reject();
        }
        // console.error('haha', data);
        let childInfo = data.data;
        let classifyInfo = data.data[0].classifyInfo;
        /*  if (childInfo && childInfo.length <= 0) {
          childInfo = [{ ...data.data }];
        } */

        store.commit('setBackFlag', true);

        store.commit('setAccessInfo', childInfo);
        store.commit('setClassifyInfo', classifyInfo);
        return childInfo;
      });
  },
  /**
   * 获取异常数据
   */
  getExceptionList(store, params) {
    return Vue.prototype.$api.resource.getExceptionList(params).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '获取异常统计数据失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      let childInfo = data.data.childInfo;
      let errorDetails = data.data.errorDetails;
      if (childInfo.length <= 0) {
        childInfo = [{ ...data.data }];
        errorDetails = [{ ...data.data }];
      }
      if (params.organizationId) {
        store.commit('setBackExcepFlag', true);
      } else {
        store.commit('setBackExcepFlag', false);
      }
      store.commit('setExceptionList', childInfo);
      store.commit('setErrorDetails', errorDetails);
      return childInfo;
    });
  },
  /**
   * 获取点位
   */
  getPagePot(store, params) {
    return Vue.prototype.$api.home.getCameraListForPot(params).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '获取点位数据失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      store.commit('setHomepagePot', data.data);
      // console.error('aaaaaaaaaaaaa', data);
      return data;
    });
  },
  /**
   * 获取摄像机组信息
   */
  getCameraGroupCondition(store, params) {
    return Vue.prototype.$api.cameraPatrol
      .getCameraGroupCondition(params)
      .then(data => {
        if (data.code !== 200) {
          vm.$message.error(
            '获取摄像机组失败! [' + data.code + ']: ' + data.message
          );
          return Promise.reject();
        }
        store.commit('setCameraGroupLists', data.data);
        return data;
      });
  },
  /**
   * 删除组内摄像机
   */
  deleteCameraGroupItem(store, params) {
    return Vue.prototype.$api.collect
      .deleteCameraGroupItem(params)
      .then(data => {
        if (data.code !== 200) {
          vm.$message.error(
            '删除摄像机组内摄像机失败! [' + data.code + ']: ' + data.message
          );
          return Promise.reject();
        }
        return data;
      });
  },
  /**
   * 导出摄像机组
   */
  exportCameraGroup(store, id) {
    return Vue.prototype.$api.collect.exportCameraGroup(id).then(data => {
      vm.$message.success('导出摄像机组成功');
      handleExportFile(data.data, '摄像机组列表.xls');
      return data;
    });
  },
  /**
   * 导出联网数据
   * @param {*} store
   * @param {*} params
   */
  exportNetworkStatistics(store, params) {
    return Vue.prototype.$api.resource
      .exportNetworkStatistics(params)
      .then(data => {
        vm.$message.success('摄像机联网状况统计详情');
        handleExportFile(data, '摄像机联网状况统计详情.xls');
        return data;
      });
  },
  /**
   * 获取统计数据
   */
  getNetworkStatistics(store, params) {
    return Vue.prototype.$api.resource
      .getNetworkStatistics(params)
      .then(data => {
        if (data.code !== 200) {
          vm.$message.error(
            '获取统计数据失败! [' + data.code + ']: ' + data.message
          );
          return Promise.reject();
        }
        let newData = data.data.map((item, index) => {
          item.index = index + 1;
          return item;
        });
        store.commit('setNetworkStatistic', newData);
        return data;
      });
  },
  getNetworkStatisticsDetail(store, params) {
    return Vue.prototype.$api.resource
      .getNetworkStatisticsDetail(params)
      .then(data => {
        if (data.code !== 200) {
          vm.$message.error(
            '获取统计数据详情失败! [' + data.code + ']: ' + data.message
          );
          return Promise.reject();
        }
        store.commit('setNetworkStatisticDetail', data.data);
        return data;
      });
  },
  /**
   * 按单位
   * @param {*} store
   * @param {*} params
   */
  getByCompany(store, params) {
    return Vue.prototype.$api.resource.getByCompany(params).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '获取统计数据详情失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      store.commit('setNetworkStatisticDetail', data.data);
      return data;
    });
  },
  getScreenShotCount(store, params) {
    return Vue.prototype.$api.resource.getScreenShotCount(params).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '获取截图数据失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      store.commit('setScreenShotData', data.data);
      return data;
    });
  },
  getScreenShotInfo(store, params) {
    //设置loading
    store.commit('setPicLoading', true);
    store.commit('setScreenShotDataInfo', []);
    store.commit('setScreenTotal', 0);
    return Vue.prototype.$api.resource.getScreenShotInfo(params).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '获取截图信息失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      let newData = data.data.map((item, index) => {
        item.index = index + 1;
        item.check = false;
        return item;
      });
      if (newData && newData.length >= 0) {
        store.commit('setIsLoading', false);
      } else {
        store.commit('setIsLoading', true);
      }
      store.commit('setScreenShotDataInfo', newData);
      store.commit('setScreenTotal', data.total);
      store.commit(
        'setScreenSelectCameraList',
        new Array(data.data.length).fill(null)
      );
      //解锁loading
      store.commit('setPicLoading', false);
      return data;
    });
  },
  getProvinceScreenShotInfo(store, params) {
    //设置loading
    store.commit('setPicLoading', true);
    store.commit('setScreenShotDataInfo', []);
    store.commit('setScreenTotal', 0);
    /*  if (!params.cameraNum) {
      store.commit('setPicLoading', false);
      return;
    } */
    return Vue.prototype.$api.resource
      .getProvinceScreenShotInfo(params)
      .then(data => {
        if (data.code !== 200) {
          vm.$message.error(
            '获取省级截图信息失败! [' + data.code + ']: ' + data.message
          );
          return Promise.reject();
        }
        let newData = data.data.map((item, index) => {
          item.index = index + 1;
          item.snapshotUrl = item.url;
          item.snapshotName = item.message;
          item.snapshotTime = item.message;//默认无时间，加个时间提示
          item.check = false;
          return item;
        });
        if (newData && newData.length >= 0) {
          store.commit('setIsLoading', false);
        } else {
          store.commit('setIsLoading', true);
        }
        store.commit('setScreenShotDataInfo', newData);
        store.commit('setScreenTotal', data.total);
        store.commit(
          'setScreenSelectCameraList',
          new Array(data.data.length).fill(null)
        );
        //解锁loading
        store.commit('setPicLoading', false);
        return data;
      });
  },
  deleteScreenShout(store, params) {
    return Vue.prototype.$api.resource.deleteScreenShout(params).then(data => {
      if (data.code !== 200) {
        vm.$message.error('批量删除失败! [' + data.code + ']: ' + data.message);
        return Promise.reject();
      }
      return data;
    });
  },
  getCameraGroupInfoById(store, id) {
    return Vue.prototype.$api.resource.getCameraGroupInfoById(id).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '获取组详情失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      store.commit('setCameraGroupInfoById', data.data);
      return data;
    });
  }
};
const mutations = {
  setAccessInfo(state, val) {
    state.accessInfo = val;
  },
  setClassifyInfo(state, val) {
    state.classifyInfo = val;
  },
  setBackFlag(state, val) {
    state.backFlag = val;
  },
  setBackPath(state, val) {
    state.backPath = val;
  },
  setAccessFirstInfo(state, val) {
    state.accessFirstInfo = val;
  },
  setExceptionList(state, val) {
    state.exceptionList = val;
  },
  setErrorDetails(state, val) {
    state.errorDetails = val;
  },
  setBackExcepFlag(state, val) {
    state.backExcepFlag = val;
  },
  setBackExcepPath(state, val) {
    state.backExcepPath = val;
  },
  setHomepagePot(state, val) {
    state.homepagePot = val;
  },
  setCameraGroupLists(state, val) {
    state.cameraGroupLists = val;
  },
  setNetworkStatistic(state, val) {
    state.networkStatistic = val;
  },
  setNetworkStatisticDetail(state, val) {
    state.networkStatisticDetail = val;
  },
  setScreenShotData(state, val) {
    state.screenShotData = val;
  },
  setScreenShotDataInfo(state, value) {
    state.screenShotDataInfo = value;
  },
  setScreenShotSearchParams(state, value) {
    state.screenShotSearchParams = value;
  },
  setScreenTotal(state, value) {
    state.screenTotal = value;
  },
  setScreenSelectCameraList(state, val) {
    state.selectScreenCameraList = val;
  },
  setCameraGroupInfoById(state, val) {
    state.cameraGroupInfoById = val;
  },
  setIsClick(state, val) {
    state.isClick = val;
  },
  setPicLoading(state, val) {
    state.picLoading = val;
  },
  setCurrentMode(state, val) {
    state.currentMode = val;
  },
  setIsProvince(state, val) {
    state.isProvince = val;
  },
  setScreenShotProvinceParams(state, val) {
    state.screenShotProvinceParams = val;
  },
  setIsLoading(state, val) {
    state.isLoading = val;
  }
};

export default {
  state,
  mutations,
  actions
};
