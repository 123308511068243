/**
 * article模块接口列表
 */

import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块
import Vue from 'vue';

const login = {
  // 新闻列表
  login(data) {
    // return axios.get(`${base.sq}/user/login/${username}/${password}`);
    // return axios.post(`${base.sq}/user/auth/login`, data);POST /user/auth/sign
    if (!Vue.prototype.$g.isDepart) {
      // return axios.post(`${base.sq}/user/auth/sign`, data);fix云南软测
      return axios.post(`${base.sq}/user/auth/login`, data);
    } else {
      return axios.post(`${base.sq}/user/auth/login`, data);
    }
  },
  getCaptcha() {
    return axios.get(`${base.sq}/user/auth/captcha`).then(({ data }) => {
      return data;
    });
  },
  modifyPass(data) {
    return axios.put(`${base.sq}/user/password`, data).then(({ data }) => {
      return data;
    });
  },
  getChoseListByAllRole(params) {
    return axios
      .get(`${base.sq}/user/role/chooseFunc?${qs.stringify(params)}`)
      .then(({ data }) => {
        return data;
      });
  },
  getPubKey() {
    return axios
      .get(`${base.sq}/user/auth/publicKey`)
      .then(({ data }) => {
        return data;
      });
   
  },
  // 加密登录
  requestLoginEncrypt(params) {
    return axios.post(`${base.sq}/user/auth/encrypted/login`, params).then(({ data }) => {
      return data;
    });
  },
};

export default login;
