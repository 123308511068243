<template>
   <div class="loading-wrap">
    <div>
      <a-icon type="loading" style="color: #0adef1;z-index:999" />
      <span style="color: #0adef1;" class="ellipsis full-width" v-if="!showLoad">视频加载时间过长，请检查视频源或更换一个试试吧！</span>
    </div>
  
  </div>
</template>

<script>
export default {
  name: 'longLoading',
  props: {
    showLoad: {
      type: Boolean,
      default: true,
    },
  },
  methods: {},
  mounted() {
        console.log(this.message,this.loadingStatus,this.showLoad,'111222333');

  },
};
</script>

<style lang="less" scoped>
.loading-wrap {
  text-align: center;
  height: 100%;
  width: 100%;
  position: relative;
  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    span {
      display: block;
      // color: #484d57;
      color: #506f94;
      font-size: 0.7rem;
    }
    i {
      font-size: 0.8rem;
      // color: #484d57;
      &.img-no-data {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-image: url('../../assets/home/no-data@2x.png');
      }
    }

   
  }
}
</style>
