import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块
import Base64 from '@/tools/base64';
const cameraGroup = {
  //拉取列表
  getGroupList(data) {
    return axios
      .get(`${base.sq}/user/group/list?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  //启停用摄像机组
  openStatus(data) {
    return axios
      .put(`${base.sq}/user/group/${data.groupId}/openStatus`, data.params)
      .then(({ data }) => {
        return data;
      });
  },
  //删除摄像机组

  /* batchDel(data) {
    return axios
      .delete(`${base.sq}/user/group/batch`, {
        headers: {
          operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
        },
        data: data.params
      })
      .then(({ data }) => {
        return data;
      });
  }, */
  batchDel(data) {
    return axios
      .delete(`${base.sq}/user/group/batch`, {
        headers: {
          operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
        },
        data: data.data
      })
      .then(({ data }) => {
        return data;
      });
  },
  //角色
  getRoleList() {
    return axios.get(`${base.sq}/user/role/list`).then(({ data }) => {
      return data;
    });
  },
  //用户
  getUserList() {
    return axios.get(`${base.sq}/user/list`).then(({ data }) => {
      return data;
    });
  },
  //选择的摄像机
  getSelectedCamera(data) {
    return axios
      .get(`${base.sq}/user/group/selectedCamera?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  //设备组已关联的角色
  getSelectedRole(data) {
    return axios
      .get(`${base.sq}/user/group/selectedRole?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  //设备组关联用户
  getSelectedUser(data) {
    return axios
      .get(`${base.sq}/user/group/selectedUser?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  //新增设备组
  addGroups(data) {
    return axios
      .post(`${base.sq}/user/group/groups`, data.data, {
        headers: {
          operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
        }
      })
      .then(({ data }) => {
        return data;
      });
  },
  //编辑设备组
  updateGroups(data) {
    return axios
      .put(`${base.sq}/user/group/group`, data.data, {
        headers: {
          operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
        }
      })
      .then(({ data }) => {
        return data;
      });
  },
  //关联用户和角色
  batchControl(data) {
    return axios
      .put(`${base.sq}/user/group/batch`, data.data, {
        headers: {
          operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
        }
      })
      .then(({ data }) => {
        return data;
      });
  },
  //设备组详情
  getCemeraListDetail(data) {
    return axios
      .get(
        `${base.sq}/user/group/${data.groupId}/cameraList?${qs.stringify(
          data.params
        )}`
      )
      .then(({ data }) => {
        return data;
      });
  },
  //导出摄像机
  exportCameraList(data) {
    return axios
      .get(
        `${base.sq}/user/group/${data.data.groupId}/export?${qs.stringify(
          data.data.params
        )}`,
        {
          responseType: 'arraybuffer',
          headers: {
            operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
          }
        }
      )
      .then(({ data }) => {
        return data;
      });
  }
};
export default cameraGroup;
