import Vue from 'vue';
import Vuex from 'vuex';

/*import router from '@/router/router'; */

Vue.use(Vuex);
let vm = new Vue();
/*  */
const state = {
  turnList: [],
  userNames: [],
  searchParams: {
    cameraPatrolPlanStatus: '',
    userName: '',
    cameraPatrolStartTime: '',
    cameraPatrolEndTime: '',
    cameraPatrolName: ''
  },
  editTurnParams: null
};
const actions = {
  /**
   * 新增轮询方案
   * @param store
   * @param obj
   * @returns {Promise<*>}
   */
  // eslint-disable-next-line no-unused-vars
  addCameraPatrol(store, data) {
    return Vue.prototype.$api.cameraPatrol.addCameraPatrol(data).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '新增轮巡方案失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      return data;
    });
  },
  /**
   * 获取轮巡预案
   */
  getCameraPatrolPlanList(store, params) {
    return Vue.prototype.$api.cameraPatrol
      .getCameraPatrolPlanList(params)
      .then(data => {
        if (data.code !== 200) {
          vm.$message.error(
            '获取轮巡方案失败! [' + data.code + ']: ' + data.message
          );
          return Promise.reject();
        }
        store.commit('setTurnList', data.data);
        return data;
      });
  },
  /**
   * 获取用户名称
   */
  getUserName(store) {
    return Vue.prototype.$api.cameraPatrol.getUserName().then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '获取用户名失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      store.commit('setUserNames', data.data);
      return data;
    });
  },
  /**
   * 批量删除轮询预案
   */
  deleteCameraPatrolPlan(store, data) {
    return Vue.prototype.$api.cameraPatrol
      .deleteCameraPatrolPlan(data)
      .then(data => {
        if (data.code !== 200) {
          vm.$message.error(
            '批量删除轮巡预案失败! [' + data.code + ']: ' + data.message
          );
          return Promise.reject();
        }
        return data;
      });
  },
  /**
   * 修改轮巡预案
   */
  modifyCameraPatrol(store, data) {
    return Vue.prototype.$api.cameraPatrol
      .modifyCameraPatrol(data)
      .then(data2 => {
        if (data2.code !== 200) {
          console.error(
            '修改轮巡预案失败! [' + data2.code + ']: ' + data2.message
          );
          return Promise.reject();
        }
        //socket里面设置
        if (data.data2) {
          store.dispatch('getCameraPatrolPlanList', data.data2);
        }
        return data2;
      });
  },
  /**
   * 播放暂停轮巡方案
   */
  playOrPausePlan(store, data) {
    return Vue.prototype.$api.cameraPatrol.playOrPausePlan(data).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '操作轮巡预案失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      return data;
    });
  }
};
const mutations = {
  setTurnList(state, val) {
    state.turnList = val;
  },
  setUserNames(state, val) {
    state.userNames = val;
  },
  setSearchParams(state, val) {
    state.searchParams = val;
  },
  setEditTurnParams(state, val) {
    state.editTurnParams = val;
  }
};

export default {
  state,
  mutations,
  actions
};
