import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const dict = {
  //拉取列表
  getCodeMaster(data) {
    return axios
      .post(`${base.sq}/code/master/getCodeMaster`, data)
      .then(({ data }) => {
        return data;
      });
  },
  //删除单个字典
  deleteDict(data) {
    return axios
      .delete(`${base.sq}/code/master/deleteDict?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  //新增单个字典表
  insertDict(data) {
    return axios
      .post(`${base.sq}/code/master/insertDict`, data)
      .then(({ data }) => {
        return data;
      });
  },
  //修改字典表
  updateDict(data) {
    return axios
      .put(
        `${base.sq}/code/master/updateDict?codeValue=${data.codeValue}`,
        data.params
      )
      .then(({ data }) => {
        return data;
      });
  },
  //批量新增
  batchInsertDict(data) {
    return axios
      .post(`${base.sq}/code/master/batchInsertDict`, data)
      .then(({ data }) => {
        return data;
      });
  },
  //批量删除
  batchDeleteDict(data) {
    return axios
      .delete(
        `${base.sq}/code/master/batchDeleteDict?codeType=${data.codeType}`,
        { data: data.params }
      )
      .then(({ data }) => {
        return data;
      });
  },
  //一键置顶
  updateSort(data) {
    return axios
      .post(`${base.sq}/code/master/updateSort?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  //上移动
  updateByCode(data) {
    return axios
      .post(`${base.sq}/code/master/updateByCode?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  }
};
export default dict;
