import Vue from 'vue';
import Vuex from 'vuex';
import login from './modules/login';
import home from './modules/home';
import collect from './modules/collect';
import message from './modules/message';
import cameraPatrol from './modules/cameraPatrol';
import resource from './modules/resource';
import aiEvent from './modules/aiEvent';
import aiCenter from './modules/aiCenter';
import sharing from './modules/sharing';
import axiosCancel from './modules/axios'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login,
    home,
    collect,
    message,
    cameraPatrol,
    resource,
    aiEvent,
    aiCenter,
    sharing,
    axiosCancel
  },
  getters: {
    getUserInfo: state => {
      return state.login.userInfo;
    },
    getMapInstance: state => {
      return state.home.amapIns;
    },
    getBotClassify: state => {
      return state.home.botClassify;
    },
    getOnlineStatus: state => {
      return state.home.onlineStatus;
    },
    getAddressValue: state => {
      return state.home.addressValue;
    },
    getLatValue: state => {
      return state.home.lat;
    },
    getSearchValue: state => {
      return state.home.searchValue;
    },
    getDefaultZoom: state => {
      return state.home.defaultZoom;
    },
    getSearchCameraId: state => {
      return state.home.searchCameraId;
    },
    geIsSearchBtn: state => {
      return state.home.isSearchBtn;
    },
    getSearchOk: state => {
      return state.home.searchOk;
    }
  }
});
