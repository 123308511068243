import Vue from 'vue';
import Vuex from 'vuex';
/*import router from '@/router/router'; */

Vue.use(Vuex);
let vm = new Vue();
const state = {
  cameraMapList: [],
  todayList: [],
  todayInfo: [],
  searchParams: {
    currPage: 1,
    pageSize: 10
  },
  total: 0,
  barData: [],
  videoList: [],
  analyseList: [],
  analysedList: [],
  alarmList: []
};
const actions = {
  /**
   * 获取摄像机列表
   * @param store
   * @param obj
   * @returns {Promise<*>}
   */
  getTotayList(store, params) {
    return Vue.prototype.$api.aiCenterY
      .getTotayList(params)
      .then(data => {
        if (data && data.code !== 200) {
          vm.$message.error('获取今日事件全部列表失败');
          return Promise.reject();
        }
        /* console.error('haha', data, data.code); */
        store.commit('setTodayList', data.data);
        store.commit('setTotal', data.total);
        return data;
      })
      .catch(() => {});
  },
  /**
   * 获取摄像机列表
   * @param store
   * @param obj
   * @returns {Promise<*>}
   */
  getAlarmList(store) {
    return Vue.prototype.$api.aiCenterY
      .queryNewestList()
      .then(data => {
        if (data && data.code !== 200) {
          vm.$message.error('获取事件报警列表失败');
          return Promise.reject();
        }
        /* console.error('haha', data, data.code); */
        store.commit('setAlarmList', data.data);
        return data;
      })
      .catch(() => {});
  },
  /*  _getTodayInfo(store) {
    return Vue.prototype.$api.aiCenterY
      .getTotayInfo()
      .then(data => {
        if (data && data.code !== 200) {
          vm.$message.error('获取今日事件全部列表失败');
          return Promise.reject();
        }
        // console.error('haha', data);
        store.commit('setTodayInfo', data.data);
        // store.commit('setTotal', data.total);
        return data;
      })
      .catch(() => {});
  }, */
  modifyAnStatus(store, schemaId) {
    return Vue.prototype.$api.aiCenterY
      .modifyAnaStatus(schemaId)
      .then(data => {
        if (data && data.code !== 200) {
          vm.$message.error('修改状态失败');
          return Promise.reject();
        }
        vm.$message.success('修改状态成功');
        return data;
      })
      .catch(() => {});
  },
  deleteAnaStatus(store, schemaId) {
    return Vue.prototype.$api.aiCenterY
      .deleteAnaStatus(schemaId)
      .then(data => {
        if (data && data.code !== 200) {
          vm.$message.error('删除方案失败');
          return Promise.reject();
        }
        vm.$message.success('删除方案成功');
        return data;
      })
      .catch(() => {});
  },
  //获取浏览历史
  getCameraHistory(store) {
    return Vue.prototype.$api.aiCenterY
      .getCameraHistory()
      .then(data => {
        if (data && data.code !== 200) {
          vm.$message.error('拉取浏览历史失败');
          return Promise.reject();
        }
        store.commit('setVideoList', data.data);
        return data;
      })
      .catch(() => {});
  }
};
const mutations = {
  setVideoList(state, val) {
    state.videoList = val;
  },
  setCameraMapList(state, val) {
    state.cameraMapList = val;
  },
  setTodayList(state, val) {
    state.todayList = val;
    // console.log(state.todayList);
  },
  setTotal(state, val) {
    state.total = val;
  },
  setSearchParams(state, val) {
    /*     let params = { ...state.searchParams, ...val }; */
    state.searchParams = val;
  },
  setTodayInfo(state, val) {
    state.todayInfo = val;
  },
  setBarData(state, val) {
    state.barData = val;
  },
  setAnalyseList(state, val) {
    state.analyseList = val;
  },
  setAnalysedList(state, val) {
    state.analysedList = val;
  },
  setAlarmList(state, val) {
    state.alarmList = val;
  }
};

export default {
  state,
  mutations,
  actions
};
