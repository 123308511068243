import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块
import Base64 from '@/tools/base64';
const video = {
  //获取录像管理
  getVideos(data) {
    return axios
      .post(`${base.sq}/monitor/screenShot/videoList`, data)
      .then(({ data }) => {
        return data;
      });
  },
  //下载录像
  downloadVideo(data) {
    return axios
      .get(
        `${base.sq}/monitor/screenShot/downloadVideo?${qs.stringify(
          data.data
        )}`,
        {
          responseType: 'blob'
        }
      )
      .then(({ data }) => {
        return data;
      });
  },
  //删除摄像机
  deleteVideo(data) {
    return axios
      .delete(`${base.sq}/monitor/screenShot/deleteVideo`, {
        headers: {
          operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
        },
        data: data.id
      })
      .then(({ data }) => {
        return data;
      });
  },
  //视频录制
  manualBeginRecord(data) {
    return axios
      .post(`${base.sq}/video-record/manual-begin-record`, data.data)
      .then(({ data }) => {
        return data;
      });
  },
  //视频停止
  manualEndRecord(id) {
    return axios
      .put(`${base.sq}/video-record/manual-end-record/${id}`)
      .then(({ data }) => {
        return data;
      });
  }
};
export default video;
