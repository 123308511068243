import Vue from 'vue';
import Vuex from 'vuex';

/*import router from '@/router/router'; */

Vue.use(Vuex);
let vm = new Vue();
/*  */
const state = {
  sharingFolders: [],
  // sharingPointCamera:{}
  sharingPointCamera:[]
};
const actions = {
  /**
   * 获取共享摄像机文件夹
   * @param store
   * @param obj
   * @returns {Promise<*>}
   */
  getSharingFolder(store,params) {
    return Vue.prototype.$api.sharingFolder.getSharingFolders(params).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '获取共享摄像机文件夹失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      const list = data.data.map(item => {
        item.show = false;
        return item;
      })
      if(list[0]&&list[0].list.length>0){
        list[0].show = true;
      }
      store.commit('setSharingFolders', list);
      return data;
    });
  },
  //新增收藏夹
  addSharingFolder(store,params) {
    return Vue.prototype.$api.sharingFolder.addSharingFolder(params).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '新增共享摄像机文件夹失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      store.dispatch('getSharingFolder',{currPage:0,pageSize:0})
      return data;
    });
  },
  //修改文件夹
  modifySharingFolder(store,params) {
    return Vue.prototype.$api.sharingFolder.updateSharingFolder(params).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '修改共享摄像机文件夹失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      // store.dispatch('getSharingFolder',{currPage:0,pageSize:0})
      return data;
    });
  },
  //删除摄像机
  deleteSharingFolder(store,id) {
    return Vue.prototype.$api.sharingFolder.deleteSharingFolder(id).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '删除摄像机文件夹失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      // store.dispatch('getSharingFolder',{currPage:0,pageSize:0})
      return data;
    });
  },
  //绑定摄像机
  bindingCamera(store,params) {
    return Vue.prototype.$api.sharingFolder.bindingCamera(params).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '绑定摄像机失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      // store.dispatch('getSharingFolder',{currPage:0,pageSize:0})
      return data;
    });
  },
  //获取文件夹下所有摄像机
  getCamerasByFolderId(store,folderId) {
    return Vue.prototype.$api.sharingFolder.getCamerasByFolderId(folderId).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '获取共享夹所有摄像机失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      // store.dispatch('getSharingFolder',{currPage:0,pageSize:0})

      return data;
    });
  },
  //解绑摄像机 
  unboundCamera(store,params) {
    return Vue.prototype.$api.sharingFolder.unboundCamera(params).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '解绑摄像机失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      // store.dispatch('getSharingFolder',{currPage:0,pageSize:0})
      return data;
    });
  },
  start(store,folderId){
    return Vue.prototype.$api.sharingFolder.start(folderId).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '共享文件夹失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      // store.dispatch('getSharingFolder',{currPage:0,pageSize:0})
      return data;
    });
  },
  stop(store,folderId){
    return Vue.prototype.$api.sharingFolder.stop(folderId).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '取消共享文件夹失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      // store.dispatch('getSharingFolder',{currPage:0,pageSize:0})
      return data;
    });
  },
  getSharingCameras(){
    return Vue.prototype.$api.sharingFolder.getSharingCameras().then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '取消共享摄像机失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      // store.dispatch('getSharingFolder',{currPage:0,pageSize:0})
      return data;
    });
  }
};
const mutations = {
  setSharingFolders(state, val) {
    state.sharingFolders = val;
  },
  setSharingPointCamera(state, val) {
    state.sharingPointCamera = val;
  }
};

export default {
  state,
  mutations,
  actions
};
