/**
 * api接口的统一出口
 */
// 文章模块接口
import login from '@/request/api/login';
import home from '@/request/api/home';
import collect from '@/request/api/collect';
import message from '@/request/api/message';
import cameraPatrol from '@/request/api/cameraPatrol';
import resource from '@/request/api/resource';
import aiCenterS from '@/request/api/aiCenter-s';
import aiCenterY from '@/request/api/aiCenter-y';
import user from '@/request/api/user';
import dict from '@/request/api/dict';
import snapshot from '@/request/api/snapshot';
import cameraGroup from '@/request/api/cameraGroup';
import storeManage from '@/request/api/storeManage';
import alarm from '@/request/api/alarm';
import authority from '@/request/api/authority';
import timingRecording from './timingRecording';
import video from '@/request/api/video';
import role from '@/request/api/role';
import imageAn from '@/request/api/imageAn';
import sharingFolder from '@/request/api/sharing-folder';
// 其他模块的接口……

// 导出接口
export default {
  login,
  home,
  collect,
  message,
  cameraPatrol,
  resource,
  aiCenterS,
  aiCenterY,
  user,
  dict,
  snapshot,
  cameraGroup,
  storeManage,
  alarm,
  authority,
  timingRecording,
  video,
  role,
  imageAn,
  sharingFolder
  // ……
};
