/**
 * article模块接口列表
 */

import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块
import Vue from 'vue';

const cameraPatrol = {
  /**
   * 新增轮询预案
   * @param {*} data
   */
  addCameraPatrol(data) {
    return axios
      .post(`${base.sq}/monitor/cameraPatrolPlan/cameraGroup`, data.data)
      .then(({ data }) => {
        return data;
      });
  },
  /*
   *修改轮巡预案
   */
  modifyCameraPatrol(data) {
    return axios
      .put(
        `${base.sq}/monitor/cameraPatrolPlan/updateCameraPatrolPlan/${data.id}`,
        data.params
      )
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 获取轮询预案列表
   */
  getCameraPatrolPlanList(data) {
    if (Vue.prototype.$g.treeDefinate) {
      return axios
        .post(
          `${base.sq}/monitor/cameraPatrolPlan/getCameraPatrolPlanList/yunnan`,
          data
        )
        .then(({ data }) => {
          return data;
        });
    } else {
      return axios
        .post(
          `${base.sq}/monitor/cameraPatrolPlan/getCameraPatrolPlanList`,
          data
        )
        .then(({ data }) => {
          return data;
        });
    }
  },
  /**
   * 获取账户名称
   */
  getUserName() {
    return axios
      .get(`${base.sq}/monitor/cameraPatrolPlan/getUserName`)
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 批量删除轮询预案
   */
  deleteCameraPatrolPlan(ids) {
    return axios
      .delete(`${base.sq}/monitor/cameraPatrolPlan`, { data: ids })
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 播放/暂停轮询预案
   */
  playOrPausePlan(data) {
    return axios
      .put(
        `${base.sq}/monitor/cameraPatrolPlan/${data.flag}?cameraPatrolPlanId=${data.cameraPatrolPlanId}`
      )
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 获取摄像机组信息
   */
  getCameraGroupCondition(data) {
    return axios
      .get(
        `${base.sq}/monitor/cameraGroup/getCameraGroup-condition?${qs.stringify(
          data
        )}`
      )
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 获取摄像机结果统计
   */
  getResultStatistic(id) {
    return axios
      .get(`${base.sq}/monitor/cameraPatrolPlan/getResultStatistic/${id}`)
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 获取摄像机结果统计
   */
  getPatrolResultInfo(data) {
    return axios
      .get(
        `${base.sq}/monitor/cameraPatrolPlan/getPatrolResultInfo/${
          data.id
        }?${qs.stringify(data.params)}`
      )
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 获取每条预案绑定的摄像机
   */
  getPatrolCamera(patrolId) {
    return axios
      .get(`${base.sq}/monitor/cameraPatrolPlan/getPatrolPlanInfo/${patrolId}`)
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 导出轮巡结果
   */
  exportResult(data) {
    return axios
    .get(
      `${base.sq}/monitor/cameraPatrolPlan/getPatrolResultInfo/${data.cameraPatrolId}/export?${qs.stringify(data.params)}`,
      {
        responseType: 'arraybuffer'
      }
    )
    .then((data) => {
      return data;
    });
  }
};

export default cameraPatrol;
