/**
 * article模块接口列表
 */

import base from './base' // 导入接口域名列表
import axios from '@/request/http' // 导入http中创建的axios实例
import Axios from 'axios'
import qs from 'qs' // 根据需求是否导入qs模块
import Vue from 'vue'
import Base64 from '@/tools/base64'

const home = {
  // 保留请求取消
  cancels: {},

  // 新闻列表
  getCameralList(dimensionType, data) {
    // return axios.get(`${base.sq}/user/login/${username}/${password}`);
    return axios.post(
      `${base.sq}/device/platforms/home/${dimensionType}`,
      data
    )
  },
  /**
   * 获取摄像机列表
   * @param store
   * @param obj
   * @returns {Promise<*>}
   */
  getCameraListForStatis(data) {
    // POST /monitor/cam-fav/home/homepageList(device/platforms/home/homepageList)
    return axios.post(
      `${base.sq}/monitor/cam-fav/home/homepageList`,
      data
    )
  },
  getCameraListForPot(data) {
    return axios
      .post(
        `${
          base.sq
        }/monitor/cam-fav/homepagePot?${qs.stringify(
          data.params
        )}`,
        data.data
      )
      .then(({ data }) => {
        return data
      })
      .catch(() => {})
  },
  getOrgTree() {
    return axios
      .get(`${base.sq}/user/organization/tree`)
      .then(({ data }) => {
        return data
      })
      .catch(() => {})
  },
  //
  getCameraPlayUrl(data) {
    return axios
      .get(
        `${base.sq}/device/cameras/playUrl?${qs.stringify(
          data.data
        )}` /* {
        headers: {
          operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
        }
      } */
      )
      .then(({ data }) => {
        return data
      })
      .catch(() => {})
  },
  getCameraPlayUrlTemp(data) {
    return axios
      .get(
        `${base.sq}/device/cameras/playUrl?${qs.stringify(
          data
        )}` /* {
        headers: {
          operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
        }
      } */
      )
      .then(({ data }) => {
        return data
      })
      .catch(() => {})
  },

  // 获取该摄像机支持的流媒体（{ cameraNum: String }）
  getStreamMediaByCameraNum(params) {
    this.cancels['getStreamMediaByCameraNum']?.()
    return axios
      .get(
        `${
          base.sq
        }/device/cameras/resolutions?${qs.stringify(
          params
        )}`,
        {
          cancelToken: new Axios.CancelToken((c) => {
            this.cancels['getStreamMediaByCameraNum'] = c
          })
        }
      )
      .then(({ data }) => data.data)
  },

  // 获取多个摄像机支持的流媒体（{ cameraNums: String[] }）
  getStreamMediaByCameraNums(params) {
    this.cancels['getStreamMediaByCameraNum']?.()
    return axios
      .post(
        `${base.sq}/device/cameras/batch/resolutions`,
        params,
        {
          cancelToken: new Axios.CancelToken((c) => {
            this.cancels['getStreamMediaByCameraNum'] = c
          })
        }
      )
      .then(({ data }) => data.data)
  },

  cameraYtControl(params) {
    return axios
      .get(
        `${
          base.sq
        }/cameraOperate/video.PTZControl?${qs.stringify(
          params.data
        )}`,
        {
          headers: {
            operateNote: Base64.encode(
              JSON.stringify(params.instructionsDel)
            )
          }
        }
      )
      .then(({ data }) => {
        return data
      })
  },
  // 监控中心搜索下拉菜单
  getCameraLocal(params) {
    return axios
      .get(
        `${
          base.sq
        }/monitor/cameraOrgTree/getHomeCameraInfo?${qs.stringify(
          params
        )}`
      )
      .then(({ data }) => {
        return data
      })
  },
  getCameraList(params) {
    return axios
      .get(
        `${base.sq}/device/cameras/list?${qs.stringify(
          params
        )}`
      )
      .then(({ data }) => {
        return data
      })
  },
  // 获取拥堵路段
  getAllRoadLineBlock() {
    let url = ''
    if (!Vue.prototype.$g.isDepart) {
      url = `${base.sq}/monitor/roadLineBlock/getAllRoadLineBlock`
    } else {
      url = `${base.sq}/monitor/roadLineBlock/getAllRoadLineBlock/jiaotongbu`
    }
    return axios.get(url).then(({ data }) => {
      return data
    })
  },
  //获取tree数据
  cameraTreeAccessByRoad(data) {
    return axios
      .post(
        `${base.sq}/monitor/treeAccess/cameraTreeAccessByRoad/${data.gradeType}`,
        data.params
      )
      .then(({ data }) => {
        return data
      })
  },
  //获取区域tree数据
  cameraTreeAccessByRegion(data) {
    return axios
      .post(
        `${base.sq}/monitor/treeAccess/cameraTreeAccessByRegion/${data.gradeType}`,
        data.params
      )
      .then(({ data }) => {
        return data
      })
  },
  //获取组织单位数据
  cameraTreeByOrg(data) {
    return axios
      .post(
        `${base.sq}/monitor/cameraOrgTree/CameraTreeByOrg`,
        data
      )
      .then(({ data }) => {
        return data
      })
  },
    //获取朱建峰组织单位数据
    cameraZJFTreeByOrg(data) {
      return axios
        .post(
          `${base.sq}/monitor/cameraOrgTree/CameraTreeByOrgOther`,
          data
        )
        .then(({ data }) => {
          return data
        })
    },
  //获取组织单位下的摄像机
  cameraTreeByOrgInfo(data) {
    return axios
      .post(
        `${base.sq}/monitor/cameraOrgTree/CameraTreeByOrgInfo`,
        data
      )
      .then(({ data }) => {
        return data
      })
  },
  //部级平台区域获取树状调阅结构
  getCameraByRegion(data /* ,cancel */) {
    return axios
      .post(
        `${
          base.sq
        }/monitor/cameraOrgTree/getCameraByRegion?${qs.stringify(
          data
        )}` /* ,
        cancel */
      )
      .then(({ data }) => {
        return data
      })
  },
  //部级平台区域获取树状调阅结构摄像机
  getCameraListByRoad(data) {
    return axios
      .get(
        `${
          base.sq
        }/monitor/cameraOrgTree/getCameraListByRoad?${qs.stringify(
          data
        )}`
      )
      .then(({ data }) => {
        return data
      })
  },
  //获取收藏夹列表
  getFolder() {
    return axios
      .get(`${base.sq}/monitor/cam-fav/getFolder`)
      .then(({ data }) => {
        return data
      })
  },
  //获取故障列表
  getFaultList() {
    return axios
      .get(`${base.sq}/device/fault/faultInfo`)
      .then(({ data }) => {
        return data
      })
  },
   //故障上报
   faultReported(data) {
    return axios
      .post(`${base.sq}/device/fault/batchInsert`, data)
      .then(({ data }) => {
        return data
      })
  },
  //故障详情
  faultDetails(data) {
      return axios
      .get(
        `${
          base.sq
        }/device/fault/queryFaultDetails?${qs.stringify(
          data
        )}`
      )
      .then(({ data }) => {
        return data
      })
  },
  //获取收藏夹下面的摄像机列表
  getFolderCamera(data) {
    return axios
      .get(
        `${
          base.sq
        }/monitor/cam-fav/folderCamera?${qs.stringify(
          data
        )}`
      )
      .then(({ data }) => {
        return data
      })
  },
  //删除文件夹
  deleteFolder(data) {
    return axios
      .put(
        `${base.sq}/monitor/cam-fav/deleteFolder`,
        data.data
      )
      .then(({ data }) => {
        return data
      })
  },
  //新建文件夹
  createFolder(data) {
    return axios
      .post(
        `${base.sq}/monitor/cam-fav/createFolder`,
        /*{
        headers: {
          operateNote: Base64.encode(JSON.stringify(data.instructionsDel))
        }, 
        params: data.data
      }*/ data.data
      )
      .then(({ data }) => {
        return data
      })
  },
  //部平台收藏功能
  createFavorite(data) {
    return axios
      .post(
        `${base.sq}/monitor/cam-fav/createFavorite`,
        data.data
      )
      .then(({ data }) => {
        return data
      })
  },
  stopStream(data) {
    return axios
      .post(`${base.sq}/device/cameras/stream-status`, data)
      .then(({ data }) => {
        return data
      })
  },
  getHomeSearchList(data) {
    return axios
      .post(
        `${base.sq}/monitor/cam-fav/homeSearchList/${
          data.type
        }?${qs.stringify(data.params)}`,
        data.data
      )
      .then(({ data }) => {
        return data
      })
  },
  /**fixsxy明文传输 */
  modifyPass(data) {
    return axios
      .put(
        `${base.sq}/user/modify-password-by-logName`,
        data
      )
      .then(({ data }) => {
        return data
      })
  },
  /**
   * 密文传输修改密码
   * @param {*} id
   * @returns
   */
  modifyEncryptPass(data) {
    return axios
      .post(`${base.sq}/user/encrypted/password`, data)
      .then(({ data }) => {
        return data
      })
  },
  getCameraDetail(id) {
    return axios
      .get(`${base.sq}/device/cameras/${id}/detail`)
      .then(({ data }) => {
        return data
      })
  },
  //查询抽检摄像机
  getCamerasByDetectionId(data) {
    return axios
      .get(
        `${
          base.sq
        }/monitor/screenShot/getCamerasByDetectionId?${qs.stringify(
          data
        )}`
      )
      .then(({ data }) => {
        return data
      })
  },
  getSameLonAndLat(data) {
    return axios
      .post(
        `${base.sq}/device/platforms/sameLonAndLat?cameraId=${data.cameraId}`,
        data.params
      )
      .then(({ data }) => {
        return data
      })
  },
  //修改经纬度
  modifyCoordinates(params) {
    return axios
      .put(
        `${
          base.sq
        }/monitor/cam-fav/modifyCoordinates?${qs.stringify(
          params
        )}`
      )
      .then(({ data }) => {
        return data
      })
  },
  //一键复位
  resetCoordinates(params) {
    return axios
      .put(
        `${
          base.sq
        }/monitor/cam-fav/resetCoordinates?${qs.stringify(
          params
        )}`
      )
      .then(({ data }) => {
        return data
      })
  },
  //获取拥堵路段
  getCongestedSection(data) {
    return axios
      .post(
        `${base.sq}/monitor/roadLineBlock/getCongestedSection`,
        data
      )
      .then(({ data }) => {
        return data
      })
  }
}

export default home
