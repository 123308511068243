/**
 * article模块接口列表
 */

import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const message = {
  getMessageList(params) {
    return axios
      .get(`${base.sq}/base/message/list?${qs.stringify(params)}`, {
        timeout: 420000
      })
      .then(({ data }) => {
        return data;
      });
  },
  //标记已读
  updateMessage(params) {
    return axios
      .put(`${base.sq}/base/message/updateMessage${params?'?':''}${qs.stringify(params)}`)
      .then(({ data }) => {
        return data;
      });
  },
  //删除消息
  batchDelete(params) {
    return axios
      .delete(`${base.sq}/base/message/batchDelete${params?'?':''}${qs.stringify(params)}`,)
      .then(({ data }) => {
        return data;
      });
  },
  //获取数量
  getMessageCount() {
    return axios
    .get(`${base.sq}/base/message/getCount`)
    .then(({ data }) => {
      return data;
    });
  }
};

export default message;
