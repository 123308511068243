<template>
  <div>
    <a-modal
      title="系统设置"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :centered="true"
      width="40%"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="fl-menu flex m-b-md items-center">
        <span
          :class="[{ current: activeMenu === 0 },'pointer ']"
          @click="tabClick(0)"
          >LOGO与名称</span
        >
        <span
          :class="[{ current: activeMenu === 1 }, 'pointer']"
          @click="tabClick(1)"
          >报警设置</span
        >
      </div>
      <div v-show="activeMenu === 0">
        <div class="flex m-b-sm items-center">
          <span class="wd80">平台名称：</span>
          <div class="flex items-center flex-one">
            <a-input
              placeholder="请输入平台名称"
              class="flex-one m-r-sm"
              v-model="platformName"
            />
            <span>0/20</span>
          </div>
        </div>
        <div class="flex m-b-sm items-center">
          <span class="wd80">上传LOGO:</span>
          <div class="flex-one">
            <a-upload
              name="avatar"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              @change="handleChange"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
            <span class="block">请上传正方形PNG图片</span>
          </div>
        </div>
      </div>

      <div v-show="activeMenu === 1">
        <div class="m-b-md">
          <div class="flex items-center m-b-xs">
            <span class="wd180 text-right">存储空间报警</span>
            <a-switch
              :checked="disabled"
              @change="handleDisabledChange"
              class="m-l-md m-r-md"
            />
            <div class="flex items-center">
              <a-input placeholder="请输入阈值" class="m-r-sm" />%
            </div>
          </div>
          <span class="text-white f12 pd110"
            >(到达该阈值后,录像继续保存,删掉最早的截图。)</span
          >
        </div>

        <div class="m-b-md">
          <div class="flex items-center m-b-xs">
            <span class="wd180 text-right">拥堵报警</span>
            <a-switch
              :checked="disabled"
              @change="handleDisabledChange"
              class="m-l-md m-r-md"
            />
            <div class="flex items-center">
              <a-input placeholder="请输入阈值" class="m-r-sm" />km
            </div>
          </div>
          <span class="pd110 f12 text-white"
            >(到达该阈值后，系统发出拥堵报警。)</span
          >
        </div>
        <div class="flex items-center">
          <span class="wd180 text-right">上云网关离线报警</span>
          <a-switch
            :checked="disabled"
            class="m-l-md m-r-md"
            @change="handleDisabledChange"
          />
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
  name: 'logActionDialog',
  props: {
    visible: {
      type: Boolean,
    },
    resource: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      confirmLoading: false,
      loading: false,
      imageUrl: '',
      activeMenu: 0,
      disabled: false,
      platformName: '',
    };
  },
  methods: {
    handleDisabledChange(disabled) {
      this.disabled = disabled;
    },
    tabClick(index) {
      this.activeMenu = index;
    },
    handleChange(info) {
      /* if (info.file.status === 'uploading') {
        this.loading = true;
      } */
      /* if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
      } */
      this.loading = true;
      getBase64(info.file.originFileObj, (imageUrl) => {
        this.imageUrl = imageUrl;
        this.loading = false;
      });
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    handleCancel() {
      this.$emit('close');
    },
    dataURLtoBlob(dataurl) {
      if (dataurl.split(',')[0].match(/:(.*?);/)) {
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      } else {
        if (!dataurl.trim()) {
          this.$message({
            type: 'error',
            message: 'LOGO不能为空！',
          });
        }
      }
    },
    handleOk() {
      const reg = /^[A-Za-z0-9-_.\u4e00-\u9fa5]{1,20}$/;
      if (!reg.test(this.platformName)) {
        this.$message.error(
          '系统名称只能是[1-20位]汉字、数字、大小写字母、“_”、“.” 组合！'
        );
        return;
      }
      if (!this.imageUrl) {
        this.$message.error('请添加图片');
        return;
      }
      let params = new FormData();
      const imgFile = this.dataURLtoBlob(this.imageUrl);
      params.append('file', imgFile);
      params.append('name', this.platformName);
      this.$api.aiCenterY.setLogo(params).then((res) => {
        if (res.code === 200) {
          this.$message.success('保存成功');
          this.$emit('close');
        }
      });
    },
  },
};
</script>
<style scoped>
.wd80 {
  width: 80px;
}
.wd180 {
  width: 120px;
}
.pd110 {
  padding-left: 140px;
}
.ant-upload-select-picture-card .ant-upload {
  background-color: seagreen !important;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
