import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
// import qs from 'qs'; // 根据需求是否导入qs模块

const role = {
  //新增角色
  addRole(data) {
    return axios.post(`${base.sq}/user/role/roles`, data).then(({ data }) => {
      return data;
    });
  },
  //修改角色
  modifyRole(data) {
    return axios.put(`${base.sq}/user/role/role`, data).then(({ data }) => {
      return data;
    });
  }
};
export default role;
