let settings = {
  designW: 1920,
  designH: 1080,
  zoomMode: 'stretch',
  chartRefreshPeriod: 400,
  hideGesture: ''
};
const Cfg = {
  designW: settings.designW || 1920, //设计图宽度
  designH: settings.designH || 1080, //设计图高度
  zoomMode: settings.zoomMode || (innerWidth < 768 ? 'cover' : 'contain'),
  notebookOptim: [undefined, true].includes(settings.notebookOptim),
  chartRefreshPeriod: settings.chartRefreshPeriod || 10, // 图表刷新周期（秒）
  /*  colors: settings.colors || 'default',
  colorData: {//配色方案，部分色彩参考 http://rmco.jp/coloringroom/haisyoku_rei/haisyoku_haru.html
      default: ['lightskyblue', 'orange', 'greenyellow', 'limegreen',
          'mediumturquoise', 'mediumpurple'],
      spring: ['#BEDC6E', '#FA8C8C', '#FAAAC8', '#FAC8C8',
          '#FFFFE6', '#6E6464'],
      summer: ['#FFAE00', '#FF5200', '#007AFF', '#00BF05',
          '#DCFFFF', '#505064'],
      autumn: ['#c1ad2f','#782323', '#783723', '#A05027',
          '#FAE6DC', '#283C14'],
      winter: ['#F5F5FA', '#96822D', '#6E5A19', '#BECDEB',
          '#E1E1F0', '#281E1E'],
  }, */
  hideGesture: settings.hideGesture
};
/* let hasGetWeather = false; */
let scale = 1;
/* let notebookOptim = true;
let colonShow = true; */
let [pageH, pageW] = [window.innerHeight, window.innerWidth];
export const Public = {
  pageResize() {
    [pageH, pageW] = [window.innerHeight, window.innerWidth];
    let isWider = pageW / pageH > Cfg.designW / Cfg.designH;
    let [scaleW, scaleH] = [pageW / Cfg.designW, pageH / Cfg.designH];
    let $container = document.querySelector('#container');

    switch (Cfg.zoomMode) {
      case 'contain':
        if (isWider) {
          /* $container.css({
          width: (pageH * Cfg.designW) / Cfg.designH,
          height: '100%'
        }); */
          $container.style.cssText = `width: ${(pageH * Cfg.designW) /
            Cfg.designH}px;height: 100%`;
        } else {
          /*   $container.css({
          height: (pageW * Cfg.designH) / Cfg.designW,
          width: '100%'
        }); */
          $container.style.cssText = `height: ${(pageW * Cfg.designH) /
            Cfg.designW}px;height: 100%`;
        }
        scale = isWider ? scaleH : scaleW;
        break;
      case 'cover':
        // $('html,body').css('overflow', 'initial');
        document.documentElement.style.overflow = 'initial';
        document.body.style.overflow = 'initial';
        if (isWider) {
          /* $container.css({
          height: (pageW * Cfg.designH) / Cfg.designW,
          width: '100%'
        }); */
          $container.style.cssText = `height: ${(pageW * Cfg.designH) /
            Cfg.designW}px;
        width: 100%`;
        } else {
          /* $container.css({
          width: (pageH * Cfg.designW) / Cfg.designH,
          height: '100%'
        }); */
          $container.style.cssText = `width: ${(pageH * Cfg.designW) /
            Cfg.designH}px;
        height: 100%`;
        }
        scale = isWider ? scaleW : scaleH;
        break;
      case 'stretch':
        scale = isWider ? scaleH : scaleW;
        /*  $container.css({ width: '100%' }, { height: '100%' }); */
        $container.style.cssText = 'width: 100%; height: 100%';
        break;
    }
    /* $('html')
    .css('font-size', scale * 16 + 'px')
    .css('opacity', 1); */
    console.error(scale);
    document.querySelector('html').style.cssText = `font-size:${scale * 16}px`;
    /* notebookOptim = !(Cfg.notebookOptim === false || scale > 0.75); */
    // console.log("~~~~~~~~~窗口高度：" + pageH + ",\n宽度:" + pageW + " \nbody字号：" + scale)
  },
  //图表缩放
  chartsResize(charts, param) {
    window.addEventListener('resize', () => {
      Object.keys(charts).forEach(id => {
        if (param && param.notResize.includes(id)) {
          return;
        }
        charts[id].resize();
      });
    });
  },
  /**
   *
   * @param charts
   * @param t 默认刷新时间（秒）
   * @param noRefresh 无需刷新的图表
   * @param someRefresh 指定要刷新的图表，有重复指定的图表时优先权高于noRefresh
   */
  chartsReDraw(charts, t = Cfg.chartRefreshPeriod, noRefresh, someRefresh) {
    let counter = null;
    clearInterval(counter);
    counter = setInterval(() => {
      Object.keys(charts).forEach(item => {
        if (
          noRefresh &&
          noRefresh.includes(item) &&
          !(someRefresh && someRefresh.includes(item))
        )
          return;
        let chart = charts[item];
        let opt = chart.getOption();
        chart.clear();
        chart.setOption(opt);
      });
    }, (t || Cfg.chartRefreshPeriod) * 1000);
  }
};
