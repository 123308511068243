import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
let vm = new Vue();
const state = {
  eventDist: [],
  alarmList: [],
  totalList:[],
  searchCamera:[]
};
const mutations = {
  // 建立一个函数
  setEventDist(state, val) {
    state.eventDist = val;
  },
  setAlarm(state, val) {
    state.alarmList = val.reverse();
  },
  getTotal(state,val){
     state.totalList=val
  },
  getCamera(state,val){
    state.searchCamera=val
  }
};
/**
 *   getEventDict(store, params) {()请求事件的函数
 */

const actions = {
  getEventDict(store, params) {
    return Vue.prototype.$api.aiCenterS
      .getEventDict(params)
      .then(({ data }) => {
        if (data && data.code !== 200) {
          vm.$message.error('获取字典表失败');
          return Promise.reject();
        }
        store.commit('setEventDist', data.data);
        return data;
      })
      .catch(e => {
        console.info('userLogin', e);
      });
  },

  getAlarms(store, params) {
    return Vue.prototype.$api.aiCenterS
      .getAlarms(params)
      .then(({ data }) => {
        if (data && data.code !== 200) {
          vm.$message.error('获取字典表失败');
          return Promise.reject();
        }

        store.commit('setAlarm', data.data);
        return data;
      })
      .catch(e => {
        console.info('userLogin', e);
      });
  },

  getBehavior(store,params){
    return Vue.prototype.$api.aiCenterS
    .getBehavior(params)
    .then(({data})=>{
      if (data && data.code !== 200) {
        vm.$message.error('获取字典表失败');
        return Promise.reject();
      }
      store.commit('getTotal',data.data)
      return data
    })
    .catch(e => {
      console.info('userLogin', e);
    });
  },

};



export default {
  state,
  mutations,
  actions
};
