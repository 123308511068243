import Vue from 'vue';
import Vuex from 'vuex';
import {
  formatGroupTreeData,
  formatOrganizationTree,
  formatProvinceTree
} from '@/tools';

/*import router from '@/router/router'; */

Vue.use(Vuex);
let vm = new Vue();
/*  */
const state = {
  cameraGroupList: [],
  cameraCollectList: [],
  cameraAllList: [],
  roadList: [],
  classifyList: [],
  organizationList: [],
  cameraCheckList: [],
  provinceList: [],
  cameraTypeList: [],
  total: 0,
  groupId: '',
  selectCameraList: [],
  expandedKeys: [],
  currentCameraGroup: null,
  currentComIndex: -1,
  treeStru: null,
  cameraTab: '',
  selectList: [],
  sessionCurrentCamera: {},
  cameraLoading: false
};
const actions = {
  /**
   * 获取摄像机列表
   * @param store
   * @param obj
   * @returns {Promise<*>}
   */
  // eslint-disable-next-line no-unused-vars
  getCameraGroup(store) {
    return Vue.prototype.$api.collect.getCameraGroup().then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '获取摄像机分组信息失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      let list = formatGroupTreeData(data.data ? data.data : []);
      store.commit('setCameraGroupList', list);
      store.commit('setExpandedKeys', [list.length > 0 ? list[0].key : '']);
      // console.error('haha', list);
      return data;
    });
  },
  /**
   * 新增摄像机组
   * @param {*} store
   */
  newCameraGroup(store, data) {
    return Vue.prototype.$api.collect.newCameraGroup(data).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '新增摄像机分组信息失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      return data;
    });
  },
  /**
   * 修改摄像机组
   * @param {*} store
   */
  modifyCameraGroup(store, data) {
    return Vue.prototype.$api.collect.modifyCameraGroup(data).then(data => {
      if (data.code !== 200) {
        vm.$message.error(
          '新增摄像机分组信息失败! [' + data.code + ']: ' + data.message
        );
        return Promise.reject();
      }
      return data;
    });
  },
  /**
   * 删除摄像机组
   * @param {*} store
   * @param {*} cameraId
   */
  deleteCameraGroup(store, cameraGroupId) {
    return Vue.prototype.$api.collect
      .deleteCameraGroup(cameraGroupId)
      .then(data => {
        if (data.code !== 200) {
          vm.$message.error('取消摄像机组失败！');
          return Promise.reject();
        }
        /* let newCameralList = store.state.cameraCollectList.filter(
          (item) => item.cameraId !== cameraId
        ); */
        vm.$message.success('删除摄像机组成功');
        store.dispatch('getCameraGroup'); //fixsxy
        return data;
      });
  },
  getCollectList(store) {
    return Vue.prototype.$api.collect.getCollectList().then(data => {
      if (data.code !== 200) {
        vm.$message.error('获取摄像机收藏列表失败！');
        return Promise.reject();
      }
      store.commit('setCameraCollectList', data.data);
      return data;
    });
  },
  deleteCollectItem(store, cameraId) {
    return Vue.prototype.$api.collect.deleteCollectItem(cameraId).then(data => {
      if (data.code !== 200) {
        vm.$message.error('取消收藏失败！');
        return Promise.reject();
      }
      let newCameralList = store.state.cameraCollectList.filter(
        item => item.cameraId !== cameraId
      );
      store.commit('setCameraCollectList', newCameralList);
      return data;
    });
  },
  addCollectItem(store, cameraId) {
    return Vue.prototype.$api.collect.addCollectItem(cameraId).then(data => {
      if (data.code !== 200) {
        vm.$message.error('收藏摄像机失败！');
        return Promise.reject();
      }
      // store.commit('setCameraCollectList', newCameralList);
      return data;
    });
  },
  /**
   * 获取所有摄像机列表
   */
  getCameraListByCondition(store, data) {
    store.commit('setCameraLoading', true);
    store.commit('setCameraAllList', []);
    return Vue.prototype.$api.collect
      .getCameraListByCondition(data)
      .then(data => {
        if (data.code !== 200) {
          vm.$message.error('获取所有摄像机列表失败！');
          return Promise.reject();
        }
        store.commit('setCameraAllList', data.data); //fixsxy
        store.commit('setCameraAllTotal', data.total); /*  */
        if (data.data && data.data.length >= 0) {
          store.commit('setCameraLoading', false);
        }

        return data;
      });
  },
  /**
   * 获取所有选择的摄像机列表
   * @param {*} store
   */
  getCameraListCheck(store, data) {
    return Vue.prototype.$api.collect.getCameraListByCheck(data).then(data => {
      if (data.code !== 200) {
        vm.$message.error('获取所有摄像机列表失败！');
        return Promise.reject();
      }
      store.commit('setCameraCheckList', data.data);
      return data;
    });
  },
  /**
   * 获取组织
   */
  getOrganationList(store, data) {
    return Vue.prototype.$api.collect.getOrganizationList({hasCamera: false, ...data}).then(data => {
      if (data.code !== 200) {
        // vm.$message.error('获取组织列表失败！');
        return Promise.reject();
      }
      store.commit('setOrganizationList', formatOrganizationTree(data.data));
      return Promise.resolve(data);
    });
  },
  /**
   * 获取道路
   */
  getRoadList(store,data = {}) {
    return Vue.prototype.$api.collect
      .getRoadsList({ currPage: 0, pageSize: 0,...data })
      .then(data => {
        if (data.code !== 200) {
          vm.$message.error('获取道路列表失败！');
          return Promise.reject();
        }
        store.commit('setRoadList', data.data);
        return data;
      });
  },
  /**
   * 获取摄像机类型列表
   */
  getClassifyList(store, codeType) {
    return Vue.prototype.$api.collect.getClassifyList(codeType).then(data => {
      if (data.code !== 200) {
        vm.$message.error('获取摄像机类型列表失败！');
        return Promise.reject();
      }
      store.commit('setClassifyList', data.data);
      return data;
    });
  },
  /**
   * 获取摄像机类别
   * @param {*} store
   * @param {*} data
   */
  getCameraType(store, codeType) {
    return Vue.prototype.$api.collect.getClassifyList(codeType).then(data => {
      if (data.code !== 200) {
        vm.$message.error('获取摄像机类型列表失败！');
        return Promise.reject();
      }
      store.commit('setCameraTypeList', data.data);
      return data;
    });
  },
  /**
   * 获取省份
   */
  getProvince(store, newdata) {
    let params = {
      organizationId:newdata.organizationId,
      roadId:newdata.roadId,
      regionLevel:1
    }
    return Vue.prototype.$api.collect.getProvince(params).then(data => {
      if (data.code !== 200) {
        vm.$message.error('获取省份列表失败！');
        return Promise.reject();
      }
      
      store.commit('setProvinceList', data.data);
       
      
      return data;
    });
  },
  /**
   * 获取三级城市
   */
  getSelectList(store, data) {
    return Vue.prototype.$api.collect.getSelectList(data).then(data => {
      if (data.code !== 200) {
        vm.$message.error('获取三级城市列表失败！');
        return Promise.reject();
      }
      store.commit('setSelectList', formatProvinceTree(data.data));
      return data;
    });
  }
};
const mutations = {
  setCameraGroupList(state, val) {
    state.cameraGroupList = val;
  },
  setCameraCollectList(state, val) {
    state.cameraCollectList = val;
  },
  setCameraAllList(state, val) {
    state.cameraAllList = val;
  },
  setRoadList(state, val) {
    state.roadList = val;
  },
  setClassifyList(state, val) {
    state.classifyList = val;
  },
  setOrganizationList(state, val) {
    state.organizationList = val;
  },
  setProvinceList(state, val) {
    state.provinceList = val;
  },
  setCameraCheckList(state, val) {
    state.cameraCheckList = val;
  },
  setCameraAllTotal(state, val) {
    // console.log('papa', val);
    state.total = val;
  },
  setGroupId(state, val) {
    state.groupId = val;
  },
  setSelectCameraList(state, val) {
    state.selectCameraList = val;
  },
  setExpandedKeys(state, val) {
    state.expandedKeys = val;
  },
  setCurrentCameraGroup(state, val) {
    state.currentCameraGroup = val;
  },
  setCameraTypeList(state, val) {
    state.cameraTypeList = val;
  },
  setCurrentComIndex(state, val) {
    state.currentComIndex = val;
  },
  setTreeStru(state, val) {
    state.treeStru = val;
  },
  setCameraTab(state, val) {
    state.cameraTab = val;
  },
  setSelectList(state, val) {
    state.selectList = val;
  },
  setSessionCurrentCamera(state, val) {
    state.sessionCurrentCamera = val;
  },
  setCameraLoading(state, val) {
    state.cameraLoading = val;
  }
};

export default {
  state,
  mutations,
  actions
};
