/**
 * article模块接口列表
 */

import base from './base'; // 导入接口域名列表
import axios from '@/request/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块
import Base64 from '@/tools/base64';

/* const collectInfo = {
  deleteCollect: {
    module: '重点关注',
    page: '收藏列表',
    feature: '取消收藏'
  }
}; */
const collect = {
  getCameraGroup() {
    return axios.get(`${base.sq}/monitor/cameraGroup`).then(({ data }) => {
      return data;
    });
  },
  newCameraGroup(data) {
    return axios
      .post(`${base.sq}/monitor/cameraGroup`, data)
      .then(({ data }) => {
        return data;
      });
  },
  /**
   *
   * @param {删除组} cameraGroupId
   */
  deleteCameraGroup(cameraGroupId) {
    return axios
      .delete(`${base.sq}/monitor/cameraGroup`, { params: { cameraGroupId } })
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 删除组内摄像机
   * @param {删除组内摄像机} cameraGroupId
   */
  deleteCameraGroupItem(data) {
    return axios
      .post(
        `${base.sq}/monitor/cameraGroup/deleteGroupCamera?cameraGroupId=${data.id}`,
        data.params
      )
      .then(({ data }) => {
        return data;
      });
  },
  /**
   * 摄像机组导出
   * @param {*} params
   */
  exportCameraGroup(id) {
    return axios.get(`${base.sq}/monitor/cameraGroup/exportCamera/${id}`, {
      responseType: 'arraybuffer'
    });
  },
  modifyCameraGroup(params) {
    return axios
      .put(`${base.sq}/monitor/cameraGroup/${params.id}`, params.data)
      .then(({ data }) => {
        return data;
      });
  },
  getCollectList() {
    return axios.get(`${base.sq}/monitor/cam-fav`).then(({ data }) => {
      return data;
    });
  },
  deleteCollectItem(obj) {
    return axios
      .delete(`${base.sq}/monitor/cam-fav?${qs.stringify(obj.params)}`, {
        headers: {
          operateNote: Base64.encode(JSON.stringify(obj.instructionsDel))
        },
        /*  params: { cameraId: obj.params.cameraId,folderId:obj.params.folderId }*/
      })
      .then(({ data }) => {
        return data;
      });
  },
  addCollectItem(cameraId) {
    return axios
      .post(`${base.sq}/monitor/cam-fav?cameraId=${cameraId}`)
      .then(({ data }) => {
        return data;
      });
  },
  getCameraListByCondition(data) {
    return axios
      .get(`${base.sq}/monitor/cameraGroup/cameraInfoAll?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  getCameraListByCheck(data) {
    return axios
      .get(`${base.sq}/monitor/cameraGroup/cameraInfoAll?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  getRoadsList(data) {
    return axios
      .get(`${base.sq}/base/roads/list?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  getClassifyList(codeType) {
    return axios
      .get(`${base.sq}/base/code-masters/list?codeType=${codeType}`)
      .then(({ data }) => {
        return data;
      });
  },
  getOrganizationList(data) {
    return axios.get(`${base.sq}/user/organization/tree?${qs.stringify(data)}`).then(({ data }) => {
      return data;
    });
  },
  getProvince(data) {
    /* return axios
      .get(`${base.sq}/base/regions/list?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      }); */
      //新的，部平台
      return axios
      .get(`${base.sq}/base/regions/list-bpt?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  getSelectList(data) {
    return axios
      .get(`${base.sq}/base/regions/selectList?${qs.stringify(data)}`)
      .then(({ data }) => {
        return data;
      });
  },
  //获取视频抽检信息列表
  getDetectionList(data) {
    return axios
      .get(
        `${base.sq}/monitor/screenShot/getDetectionList?${qs.stringify(data)}`
      )
      .then(({ data }) => {
        return data;
      });
  },
  //获取视频抽检摄像机列表
  getDetectionCamera(data) {
    return axios
      .get(
        `${base.sq}/monitor/screenShot/getDetectionCamera/${
          data.id
        }?${qs.stringify(data.params)}`
      )
      .then(({ data }) => {
        return data;
      });
  },
  //修改新增视频抽检信息
  addCameraDetection(data) {
    return axios
      .post(`${base.sq}/monitor/screenShot/cameraDetection`, data)
      .then(({ data }) => {
        return data;
      });
  },
  //删除批量删除抽检信息
  removeCameraDetection(data) {
    return axios
      .delete(`${base.sq}/monitor/screenShot/deleteDetection`, { data: data })
      .then(({ data }) => {
        return data;
      });
  }
};

export default collect;
