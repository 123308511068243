<template>
  <div class="loading-wrap">
    <div v-if="loadingStatus === 'loading'">
      <a-icon type="loading" style="color: #0adef1;z-index:999" />
      <span style="color: #0adef1" v-if="!showLoad">{{ message }}</span>
    </div>
    <div v-else-if="loadingStatus === 'error'">
      <a-icon type="dropbox" />
      <span >{{ message }}</span>
    </div>
    <div v-else-if="loadingStatus === 'refresh'">
      <a-icon type="dropbox" />
      <span >{{ message }}</span>
    </div>
    <div
      class="loading-empty flex-column flex items-center justify-center"
      v-else-if="loadingStatus === 'empty'"
    >
      <!-- <i class="img-no-data"></i> -->
      <span class="ellipsis full-width" >{{ message }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    message: {
      type: String,
      default() {
        return '数据加载中...';
      },
    },
    loadingStatus: {
      type: String,
      default() {
        return 'loading';
      },
    },
    showLoad: {
      type: Boolean,
      default: true,
    },
   
    
  },
  methods: {},
  mounted() {
    console.log(this.message,this.loadingStatus,this.showLoad);
  },
};
</script>

<style lang="less" scoped>
.loading-wrap {
  text-align: center;
  font-size: 18px;
  padding: 30px;
  height: 100%;
  position: relative;
  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    span {
      display: block;
      line-height: 30px;
      // color: #484d57;
      color: #506f94;
    }
    i {
      font-size: 18px;
      // color: #484d57;
      margin-bottom: 10px;
      &.img-no-data {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-image: url('../../assets/home/no-data@2x.png');
      }
    }

    &.loading-empty {
      i {
        font-size: 18px;
      }
      span {
        // font-size: 1.2rem;fix前
        font-size: 12px;
        // line-height: 30px;
      }
    }
  }
}
</style>
